import React, { createContext, useContext, useEffect, useState } from "react";
import { useAPI } from "./useAPI";

const RoundContext = createContext("");

export const useRound = () => useContext(RoundContext);

export function RoundProvider({ children }) {
  const [round, setRound] = useState(null);
  const [checking, setChecking] = useState(false);
  const { currentUser, rounds } = useAPI();

  useEffect(() => {
    async function getRound() {
      if (round === null && currentUser?.jwtToken?.length > 0 && !checking) {
        try {
          setChecking(true);
          const existingRound = await rounds.getLatest();

          if (existingRound?.status === 200) {
            setRound(existingRound.data);
          }
        } catch (error) {
          console.log(`Retrieve Round failed!`);
          console.log(error);
        }

        setChecking(false);
      }
    }

    getRound();
  }, [
    checking,
    round,
    rounds,
    currentUser?.jwtToken?.length,
    currentUser?.governmentAreaId,
  ]);

  return (
    <RoundContext.Provider value={round}>{children}</RoundContext.Provider>
  );
}
