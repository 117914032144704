import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function Dropdown({
  label,
  data,
  choice,
  onSelect,
  disabled = false,
}) {
  const labelId = `dropdown-label-${label}`;

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        disabled={disabled}
        value={choice}
        onChange={(e) => onSelect(e.target.value)}
        label={label}
      >
        <MenuItem value={""}>Please Select</MenuItem>
        {data.map((d) => {
          return (
            <MenuItem key={d} value={d}>
              {d}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
