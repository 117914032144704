import React, { useState } from "react";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";

import { ChoiceModal } from "../../../components";
import { useAPI } from "../../../hooks";
import { loadedSegment } from "../forms/common/journeyType";
import { basicDetails } from "../forms/common/journeyNames";

export default function SegmentsTable({
  headers,
  rows,
  onDataChange,
  canDelete = true,
  isClickable = false,
  includeRatings = false,
  selectedJourneyType = loadedSegment,
}) {
  const theme = useTheme();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deletingSegmentId, setDeletingSegmentId] = useState(null);
  const { segments } = useAPI();
  const navigate = useNavigate();
  const { id } = useParams();

  function handleDelete(segmentId) {
    setDeletingSegmentId(segmentId);
    setDeleteDialog(true);
  }

  async function onDeleteDecision(confirmDelete) {
    if (!canDelete) return;
    if (confirmDelete && deletingSegmentId) {
      try {
        await segments.softDelete(deletingSegmentId);
        onDataChange();
      } catch (error) {
        console.error("Failed to delete segment:", error);
      } finally {
        setDeletingSegmentId(null);
        setDeleteDialog(false);
      }
    } else {
      setDeletingSegmentId(null);
      setDeleteDialog(false);
    }
  }

  function handleSelect(segment) {
    if (!isClickable) return;
    const sessionId = id ?? segment.sessionId;
    navigate(`/session/${sessionId}/segment/${segment.id}`, {
      state: {
        journeyType: selectedJourneyType,
        startingPoint: basicDetails,
        ...(selectedJourneyType === loadedSegment && {
          loadedSegmentIds: rows.map((row) => row.id),
        }),
      },
    });
  }

  return (
    <>
      <Stack spacing={4}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
                {canDelete && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleSelect(row)}
                >
                  <TableCell align="left">{row.roadName}</TableCell>
                  <TableCell align="left">{row.uniqueId}</TableCell>
                  <TableCell align="left">{row.customId}</TableCell>
                  <TableCell align="left">{row.fromSLK}</TableCell>
                  <TableCell align="left">{row.toSLK}</TableCell>
                  {includeRatings && (
                    <>
                      <TableCell align="left">
                        {row.segmentAssessment?.finalStarRating}
                      </TableCell>
                      <TableCell align="left">
                        {row.segmentAssessment?.lessthanOneStarCount}
                      </TableCell>
                      <TableCell align="left">
                        {row.segmentAssessment?.oneStarCount}
                      </TableCell>
                      <TableCell align="left">
                        {row.segmentAssessment?.threeStarCount}
                      </TableCell>
                      <TableCell align="left">
                        {row.segmentAssessment?.fiveStarCount}
                      </TableCell>
                    </>
                  )}
                  {canDelete && (
                    <TableCell align="right">
                      <IconButton
                        aria-label="Delete"
                        color="error"
                        onClick={() => handleDelete(row.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {deleteDialog && (
        <ChoiceModal
          open={deleteDialog}
          text="Are you sure you want to delete this segment?"
          yesText="Yes"
          noText="No"
          onYes={() => onDeleteDecision(true)}
          onNo={() => onDeleteDecision(false)}
          onClose={() => onDeleteDecision(false)}
        />
      )}
    </>
  );
}
