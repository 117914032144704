import { Dialog, Stack, Typography } from "@mui/material";

export default function UploadModal({ open, topText, bottomText, icon }) {
  return (
    <Dialog open={open} maxWidth="xs">
      <Stack spacing={8} sx={{ padding: "2.5em 4em 2.5em 4em" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {topText}
        </Typography>
        <Stack sx={{ alignItems: "center" }}>{icon}</Stack>
        {bottomText && (
          <Typography
            sx={{
              alignItems: "center",
              fontSize: "20px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {bottomText}
          </Typography>
        )}
      </Stack>
    </Dialog>
  );
}
