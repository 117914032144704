import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextFieldInput } from "../../../components";
import { userName } from "../common/formNames";
import { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ForgotPassword({
  setShowForgotPassword,
  setLoggingIn,
  forgotPassword,
}) {
  const [emailSent, setEmailSent] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  async function onSubmit(form) {
    setLoggingIn(true);
    await forgotPassword(form[userName]);
    setEmailSent(true);
    setLoggingIn(false);
  }

  return (
    <Stack spacing={2}>
      {emailSent ? (
        <Stack spacing={4} sx={{ alignItems: "center" }}>
          <Typography variant="h3">Email Sent</Typography>
          <CheckCircleIcon fontSize="large" color="success" />
          <Typography>
            Check your email and open the link we sent to continue.
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography variant="h1">Reset Password</Typography>
          <TextFieldInput
            name={userName}
            label="Email"
            control={control}
            errors={errors}
            required={true}
          />
        </>
      )}
      <Stack spacing={1} sx={{ paddingTop: "0.5em" }}>
        {emailSent ? (
          <></>
        ) : (
          <>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleSubmit(onSubmit)()}
              sx={{ padding: "1em 0" }}
            >
              Send Reset Link
            </Button>
            <Button
              onClick={() => setShowForgotPassword(false)}
              sx={{ textTransform: "none", justifyContent: "start" }}
            >
              Back to Log In
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
}
