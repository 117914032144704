import { useForm } from "react-hook-form";
import {
  acceptableName,
  driveCapacity,
  fundingLevel,
  goodName,
  highName,
  levelSafetyRate,
  lowName,
  moderateName,
  requiredAccreditation,
  timeCommitment,
  trainingCompleted,
  vehicleAvailable,
  veryGoodName,
} from "../common/formNames";
import { Button, Divider, Stack, useMediaQuery } from "@mui/material";
import { RadioController, RadioOption } from "./forms";

export default function Form({ onSubmit }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <RadioController
        control={control}
        name={levelSafetyRate}
        errors={errors}
        text="How would you rate the level of road safety expertise in your organisation?"
      >
        <RadioOption value={veryGoodName} label={veryGoodName} />
        <RadioOption value={goodName} label={goodName} />
        <RadioOption value={acceptableName} label={acceptableName} />
      </RadioController>

      <RadioController
        control={control}
        name={trainingCompleted}
        errors={errors}
        text="Have any personnel completed training in the use of either IRAP or ANRAM?"
      >
        <RadioOption value={true} label="Yes" />
        <RadioOption value={false} label="No" />
      </RadioController>

      <RadioController
        control={control}
        name={requiredAccreditation}
        errors={errors}
        text="Does anyone in your organisation have the required accreditation to use IRAP?"
      >
        <RadioOption value={true} label="Yes" />
        <RadioOption value={false} label="No" />
      </RadioController>

      <RadioController
        control={control}
        name={timeCommitment}
        errors={errors}
        text="What level of time commitment can be given to this task?"
      >
        <RadioOption value={highName} label={highName} />
        <RadioOption value={moderateName} label={moderateName} />
        <RadioOption value={lowName} label={lowName} />
      </RadioController>

      <RadioController
        control={control}
        name={fundingLevel}
        errors={errors}
        text="What level of funding can be allocated to use the tool?"
      >
        <RadioOption value={highName} label={highName} />
        <RadioOption value={moderateName} label={moderateName} />
        <RadioOption value={lowName} label={lowName} />
      </RadioController>

      <RadioController
        control={control}
        name={vehicleAvailable}
        errors={errors}
        text="Is an instrumented survey vehicle available, to collect data?"
      >
        <RadioOption value={true} label="Yes" />
        <RadioOption value={false} label="No" />
      </RadioController>

      <RadioController
        control={control}
        name={driveCapacity}
        errors={errors}
        text="Is there capacity to drive each route to be analysed?"
      >
        <RadioOption value={true} label="Yes" />
        <RadioOption value={false} label="No" />
      </RadioController>
      <Divider />
      <Stack
        direction="row"
        sx={{ flex: 1, justifyContent: isMobile ? "center" : "end" }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "auto", padding: "0.5em 2em" }}
          onClick={() => handleSubmit(onSubmit)()}
        >
          Submit Results
        </Button>
      </Stack>
    </>
  );
}
