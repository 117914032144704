import { Button, Stack, Typography } from "@mui/material";

export default function EmailNotConfirmed({ showSignUp, logout }) {
  return (
    <Stack spacing={2}>
      <Typography>
        {showSignUp
          ? "Thanks for signing up."
          : "You haven't confirmed your email."}{" "}
        An email has been sent. Please click the link in the email to continue.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={logout}
        sx={{ padding: "1em 0" }}
      >
        To Log In
      </Button>
    </Stack>
  );
}
