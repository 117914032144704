import React from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

export default function TextFieldInput({
  name,
  label,
  control,
  required,
  errors,
  note,
  onChange,
  sx,
}) {
  const theme = useTheme();

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            label={label ?? name}
            variant="filled"
            InputLabelProps={{ shrink: true }}
            sx={sx}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
          />
        )}
      />
      {errors[name] && (
        <Typography color={theme.palette.error.main}>
          This is required.
        </Typography>
      )}

      {note && (
        <Typography
          variant="caption"
          sx={{ paddingLeft: "1em", color: theme.palette.text.secondary }}
        >
          {note}
        </Typography>
      )}
    </Stack>
  );
}
