import dayjs from "dayjs";

//returns dd/nn/yyyy format from UTC
export function convertToPerthDate(utcDate) {
  return dayjs(utcDate).format("DD/MM/YYYY");
}
//returns dd/nn/yyyy, hh:mm:ss am/pm format from UTC
export function convertToPerthDateTime(utcDate) {
  return dayjs(utcDate).format("DD/MM/YYYY hh:mm:ss");
}
