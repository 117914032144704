import { Typography, ListItem, ListItemIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import CircleIcon from "@mui/icons-material/FiberManualRecord";

export default function ListItemWithBulletIcon({ text }) {
  const theme = useTheme();

  return (
    <ListItem sx={{ padding: 0, margin: 0 }}>
      <ListItemIcon sx={{ minWidth: 15 }}>
        <CircleIcon sx={theme.typography.bulletlist} />
      </ListItemIcon>
      <Typography variant="body1">{text}</Typography>
    </ListItem>
  );
}
