import { CircularProgress, Dialog, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function LoadingModal({ open }) {
  const theme = useTheme();

  return (
    <Dialog open={open} maxWidth="xs">
      <Stack spacing={8} sx={{ padding: "6em 8em 6em 8em" }}>
        <Stack sx={theme.components.UploadModal}>
          <CircularProgress />
        </Stack>
      </Stack>
    </Dialog>
  );
}
