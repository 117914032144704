import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../images/logo.png";
import gov from "../../images/gov.png";
import iRap from "../../images/irap.png";
import uwa from "../../images/uwa.jpg";

export default function LoginLayout({ children }) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        minWidth: "100vw",
        minHeight: "100vh",
        background: "linear-gradient(180deg, #175586 0%, #042C47 100%)",
      }}
    >
      <Stack
        spacing={2}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Stack
          spacing={4}
          direction="row"
          sx={{
            paddingTop: "1.5em",
            paddingBottom: "1em",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Made Safer by iRap"
            src={iRap}
            height="46px"
            width="271px"
          />

          <img alt="Walga Logo" src={logo} height="92px" width="210px" />
        </Stack>
        <Stack>
          <img alt="UWA Logo" src={uwa} height="60px" width="350px" />
        </Stack>
        <Typography sx={{ color: theme.palette.primary.contrastText }}>
          With funding from:
        </Typography>
        <img alt="Australian Government Logo" src={gov} width="210px" />
      </Stack>
      <Stack spacing={1} sx={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
        {children}
      </Stack>
    </Stack>
  );
}
