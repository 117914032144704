import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export const NavbarListItem = ({ text, icon, handleSelect }) => (
  <ListItem
    sx={{
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        cursor: "pointer",
      },
      m: 0,
      p: 1.5,
      paddingRight: 6,
    }}
    onClick={handleSelect}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);
