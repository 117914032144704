import { createTheme } from "@mui/material/styles";

export const themeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#042C47",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#D8AF19",
    },
    error: {
      main: "#ff0000",
    },
    background: {
      default: "#fff",
      paper: "#F5F5F5",
    },
    common: {
      white: "rgb(250, 249, 246)",
      red: "#D32F2F",
    },
    text: {
      primary: "#042C47",
      secondary: "#00000099",
      light: "#d1d1d1",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Roboto, Inter",
    },
    b1: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "143%",
      letterSpacing: "0.01em",
      opacity: "0.9",
    },
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 450,
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    bulletlist: {
      fontSize: ".25rem",
      padding: "0px",
      margin: "0px",
    },
    listItem: {
      padding: "0px",
      margin: "0px",
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#C0C0C0",
          marginBottom: "1rem",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: "400",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "resourcePageSubHeader" },
          style: {
            fontSize: "1rem",
            fontWeight: 450,
            lineHeight: 1.167,
            letterSpacing: "-0.01562em",
          },
        },
      ],
    },
    OverviewCard: {
      fontSize: {
        xsmall: "10px",
        small: "18px",
        medium: "30px",
        large: "40px",
        xlarge: "55px",
      },
      fontWeight: 800,
      alignSelf: "center",
      margin: 1,
    },
    TreatmentCard: {
      fontSize: {
        small: "10px",
        large: "20px",
      },
      fontWeight: 200,
      margin: 1,
    },
    Effectiveness: {
      high: "#2E7D32",
      medium: "#0288D1",
      low: "#D32F2F",
    },
    TableSortLabel: {
      color: "#ffffff",
      "&.MuiTableSortLabel-root": {
        color: "#ffffff",
      },
      "&.MuiTableSortLabel-root.Mui-active": {
        color: "#ffffff",
      },
      "&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiSvgIcon-root": {
        color: "#ffffff",
      },
      "&.MuiTableSortLabel-root:hover": {
        color: "#ffffff",
      },
      "&.MuiTableSortLabel-root:hover .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
  },
});
