import {
  Button,
  Dialog,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function ChoiceModal({
  open,
  text,
  yesText,
  noText,
  onYes,
  onNo,
  onClose,
  titleText = null,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Stack spacing={2} sx={{ padding: "2em 2em 1em 2em" }}>
        {titleText && <Typography variant="h3">{titleText}</Typography>}
        <Typography>{text}</Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          justifyContent="space-between"
        >
          <Stack sx={{ flex: 1 }}>
            <Button
              variant="text"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onNo}
            >
              {noText}
            </Button>
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onYes}
            >
              {yesText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
