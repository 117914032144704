export const ruralLocalAccessRoadG = {
  "Road Type":
    "Rural local access road, Single Carriageway, two-lane, two-way, 50/60/70/80/90/100/110 km/h, AADT < 100",
  Name: "Rural Local Access Road G",
  Context: "Rural",
  Seal: "Sealed",
  Carriageway: "Single",
  Laneage: "Two-lane, two-way",
  "Approximate Speed Limit": "110/100/90/80/70/60/50 km/h",
  AADT: "<100",
  attributes: {
    speedLimit: {
      name: "speedLimit",
      label: "Speed Limit",
      rulesMessage: "Please select a speed limit",
      readOnly: false,
      data: [
        "<1 Star | 110km/h",
        "1 Star | 100km/h",
        "3 Stars | 80-90km/h",
        "5 Stars | 70km/h or less",
      ],
    },
    curvature: {
      name: "curvature",
      label: "Curvature",
      rulesMessage: "Please select a curvature",
      readOnly: true,
      data: ["Moderate"],
    },
    skidResistance: {
      name: "skidResistance",
      label: "Skid Resistance",
      rulesMessage: "Please select a skid resistance",
      readOnly: false,
      data: [
        "<1 Star | Sealed - poor",
        "1 Star | Sealed - medium",
        "3 Stars | Sealed - adequate",
      ],
    },
    laneWidth: {
      name: "laneWidth",
      label: "Lane Width",
      rulesMessage: "Please select a lane width",
      readOnly: false,
      data: [
        "<1 Star | Very Narrow",
        "1 Star | Narrow",
        "3 Stars | Medium",
        "5 Stars | Wide",
      ],
    },
    roadsideHazards: {
      name: "roadsideHazards",
      label: "Roadside Hazards (linked to Roadside Objects)",
      rulesMessage: "Please select roadside hazards",
      readOnly: false,
      data: [
        "<1 Star | 0-1m from roadside",
        "1 Star | 1-5m from roadside",
        "3 Stars | 6m from roadside",
        "5 Stars | 1-5m from roadside",
      ],
    },
    roadCondition: {
      name: "roadCondition",
      label: "Road Condition",
      rulesMessage: "Please select a road condition",
      readOnly: false,
      data: ["<1 Star | Poor", "1 Star | Medium", "3 Stars | Good"],
    },
    delineation: {
      name: "delineation",
      label: "Delineation",
      rulesMessage: "Please select a delineation",
      readOnly: false,
      data: ["<1 Star | None", "1 Star | Poor", "3 Stars | Adequate"],
    },
    lanes: {
      name: "lanes",
      label: "Number of Lanes",
      rulesMessage: "Please select lanes",
      readOnly: true,
      data: ["2"],
    },
    carriageway: {
      name: "carriageway",
      label: "Carriageway",
      rulesMessage: "Please select a carriageway",
      readOnly: true,
      data: ["Undivided road"],
    },
    pavedShoulder: {
      name: "pavedShoulder",
      label: "Paved Shoulder",
      rulesMessage: "Please select a paved shoulder",
      readOnly: false,
      data: ["1 Star | None", "3 Stars | Narrow", "5 Stars | Wide"],
    },
    roadsideObjects: {
      name: "roadsideObjects",
      label: "Roadside Objects (linked to Roadside Hazards)",
      rulesMessage: "Please select roadside objects",
      readOnly: false,
      data: [
        "<1 Star | Tree",
        "1 Star | Tree",
        "3 Stars | Tree",
        "5 Stars | Safety barrier - wire",
      ],
    },
    shoulderRumbleStrips: {
      name: "shoulderRumbleStrips",
      label: "Shoulder Rumble Strips",
      rulesMessage: "Please select shoulder rumble strips",
      readOnly: false,
      data: ["1 Star | Not present", "5 Stars | Present"],
    },
    centrelineRumbleStrips: {
      name: "centrelineRumbleStrips",
      label: "Centreline Rumble Strips (linked to Median Type)",
      rulesMessage: "Please select centreline rumble strips",
      readOnly: false,
      data: [
        "<1 Star | Not present",
        "1 Star | Not present",
        "5 Stars | Present",
      ],
    },
    medianType: {
      name: "medianType",
      label: "Median Type (linked to Centrelink Rumble Strips)",
      rulesMessage: "Please select a median type",
      readOnly: false,
      data: [
        "<1 Star | No Centre line",
        "1 Star | Centre line",
        "5 Stars | Centre line",
      ],
    },
    propertyAccessPoints: {
      name: "propertyAccessPoints",
      label: "Property Access Points",
      rulesMessage: "Please select property access points",
      readOnly: true,
      data: ["Residential 1 or 2"],
    },
  },
};
