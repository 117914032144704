export const Prefix = {
  Session: "Session",
  Segment: "Segment",
  SegmentAssessment: "SegmentAssessment",
};

export const keyToHeaderMap = {
  Segment_roadName: "Road Name",
  Segment_roadType: "Road Type",
  Segment_uniqueId: "Main Roads Unique ID (ROAD)",
  Segment_tbd: "Main Roads ID (ROUTE_NE_ID)", //not yet determined
  Segment_customId: "Local Government Unique ID",
  Segment_fromSLK: "From SLK",
  Segment_toSLK: "To SLK",
  Session_tbd: "Local Government Name", //not yet determined
  SegmentAssessment_finalStarRating: "Star Safety Rating",
  SegmentAssessment_lessthanOneStarCount: "<1 Star Number of Checks",
  SegmentAssessment_oneStarCount: "1 Star Number of Checks",
  SegmentAssessment_threeStarCount: "3 Star Number of Checks",
  SegmentAssessment_fiveStarCount: "5 Star Number of Checks",
  SegmentAssessment_speedLimit: "Speed Limit",
  SegmentAssessment_skidResistance: "Skid Resistance",
  SegmentAssessment_laneWidth: "Lane Width",
  SegmentAssessment_roadsideHazards: "Roadside Hazards",
  SegmentAssessment_roadCondition: "Road Condition",
  SegmentAssessment_delineation: "Delineation",
  SegmentAssessment_pavedShoulder: "Paved Shoulder",
  SegmentAssessment_roadsideObjects: "Roadside Object",
  SegmentAssessment_shoulderRumbleStrips: "Shoulder Rumble Strips",
  SegmentAssessment_centrelineRumbleStrips: "Centreline Rumble Strips",
  SegmentAssessment_medianType: "Median Type",
  SegmentAssessment_sideWalk: "Footpath",
  SegmentAssessment_bicycleFacilities: "Bicycle Facilities",
  SegmentAssessment_pedestrianFacilities: "Pedestrian Facilities",
  SegmentAssessment_streetLighting: "Street Lighting"
};
