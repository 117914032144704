/* eslint-disable no-restricted-globals */
import React, { createContext, useContext, useEffect, useState } from "react";

const IsOnlineContext = createContext("");

export const useIsOnline = () => useContext(IsOnlineContext);

export function IsOnlineProvider({ children }) {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setIsOnline(navigator.onLine);
    }
    self.addEventListener("online", changeStatus);
    self.addEventListener("offline", changeStatus);
    changeStatus();

    return () => {
      self.removeEventListener("online", changeStatus);
      self.removeEventListener("offline", changeStatus);
    };
  }, []);

  return (
    <IsOnlineContext.Provider value={isOnline}>
      {children}
    </IsOnlineContext.Provider>
  );
}
