/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAPI } from "../../../hooks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import NewUserModal from "./components/NewUserModal";
dayjs.extend(utc);

export default function AdminUsers() {
  const [searchTerm, setSearchTerm] = useState("");
  const [regions, setRegions] = useState([]);
  const [searchRegion, setSearchRegion] = useState("");
  const [govs, setGovs] = useState([]);
  const [searchGov, setSearchGov] = useState("");
  const [data, setData] = useState([]);
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const { admin } = useAPI();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    async function getRegions() {
      const results = await admin.getRegionNames();
      if (results.status === 200) {
        setRegions(results.data);
      }
    }

    async function getGovs() {
      const results = await admin.getGovs();
      if (results.status === 200) {
        setGovs(results.data);
      }
    }

    getData();
    getRegions();
    getGovs();
  }, []);

  async function getData() {
    const results = await admin.getUsers();
    if (results.status === 200) {
      setData(results.data);
    }
  }

  async function updateLock(email, lock) {
    const results = await admin.setLockUser({ email, lock });
    if (results.status === 200) {
      await getData();
    }
  }

  function onNewModalClose(success) {
    if (success) {
      getData();
    }

    setOpenNewUserModal(false);
  }

  const filteredGovs = govs
    .filter((g) => !searchRegion.length || g.region?.includes(searchRegion))
    .map((g) => g.name);

  const rows = data.filter(
    (d) =>
      (!searchTerm.length ||
        d.email.includes(searchTerm) ||
        d.region.includes(searchTerm) ||
        d.governmentName.includes(searchTerm)) &&
      (!searchRegion.length || d.region.includes(searchRegion)) &&
      (!searchGov.length || d.governmentName.includes(searchGov))
  );

  return (
    <>
      <Typography variant="h4">Manage Users</Typography>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{ justifyContent: "space-between" }}
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{flex: 1}}>
          <TextField
            label="Search"
            placeholder="Email, Region, Local Government"
            value={searchTerm}
            variant="filled"
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FormControl variant="filled" fullWidth>
            <InputLabel id="region-search">Region</InputLabel>
            <Select
              labelId="region-search"
              value={searchRegion}
              autoWidth
              onChange={(e) => setSearchRegion(e.target.value)}
              label="Region"
            >
              <MenuItem value={""}>Please Select</MenuItem>
              {regions.map((r) => {
                return (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="local-gov-search">Local Government</InputLabel>
            <Select
              labelId="local-gov-search"
              value={searchGov}
              onChange={(e) => setSearchGov(e.target.value)}
              label="Local Government"
            >
              <MenuItem value={""}>Please Select</MenuItem>
              {filteredGovs.map((g) => {
                return (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Button variant="outlined" onClick={() => setOpenNewUserModal(true)}>
          Add User
        </Button>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Local Government</TableCell>
              <TableCell>Last Active</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.region}</TableCell>
                <TableCell align="left">{row.governmentName}</TableCell>
                <TableCell align="left">
                  {row.lastLoggedIn.includes("0001-01-01")
                    ? "--"
                    : dayjs.utc(row.lastLoggedIn).local().format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="left">
                  <Button
                    onClick={() => updateLock(row.email, !row.isLockedOut)}
                  >
                    <Chip
                      label={row.isLockedOut ? "Inactive" : "Active"}
                      color={row.isLockedOut ? "error" : "success"}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NewUserModal
        open={openNewUserModal}
        onClose={onNewModalClose}
        regions={regions}
        governments={govs}
      />
    </>
  );
}
