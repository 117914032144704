import { useEffect, useState } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OverviewCard } from "../../../components";
import { calculateKms, calculateRating, renderStars } from "../../../helpers";
import BigNumber from "bignumber.js";
import TreatmentCard from "../../treatments/TreatmentCard";

export default function OverviewSection({
  roundSegments,
  roadsInNetwork,
  kmsInNetwork,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [starRating, setStarRating] = useState(0);
  const [kmsAnalysed, setKmsAnalysed] = useState(0);

  useEffect(() => {
    setKmsAnalysed(calculateKms(roundSegments));
    setStarRating(calculateRating(roundSegments));
  }, [roundSegments]);

  const stars = renderStars(starRating, theme, isMobile);

  const uniqueRoadNames = [
    ...new Set(roundSegments.map((segment) => segment.roadName)),
  ];

  return (
    <Stack>
      <Stack sx={{ justifyContent: "space-around" }}>
        <Stack direction="row" sx={{ justifyContent: "space-around" }}>
          <OverviewCard title={"% OF NETWORK COMPLETED"}>
            <Typography
              sx={{
                ...theme.components.OverviewCard,
                fontSize: isMobile
                  ? theme.components.OverviewCard.fontSize.medium
                  : theme.components.OverviewCard.fontSize.xlarge,
                paddingTop: isMobile ? "0.9em" : null,
              }}
              color="green"
            >
              {roadsInNetwork
                ? `${new BigNumber(uniqueRoadNames.length)
                    .dividedBy(new BigNumber(roadsInNetwork))
                    .multipliedBy(100)
                    .toFixed(2)}%`
                : uniqueRoadNames.length}
            </Typography>
          </OverviewCard>
          <OverviewCard title={"AVERAGE RATING"}>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100px",
              }}
            >
              {stars}
            </Stack>
          </OverviewCard>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-around" }}>
          <OverviewCard title={"ROADS ANALYSED"}>
            <Typography
              sx={{
                ...theme.components.OverviewCard,
                fontSize: isMobile
                  ? theme.components.OverviewCard.fontSize.medium
                  : theme.components.OverviewCard.fontSize.xlarge,
              }}
              variant="h1"
              color="green"
            >
              {roadsInNetwork
                ? `${uniqueRoadNames.length}/${roadsInNetwork}`
                : uniqueRoadNames.length}
            </Typography>
          </OverviewCard>
          <OverviewCard title={"SEGMENTS ANALYSED"}>
            <Typography
              sx={{
                ...theme.components.OverviewCard,
                fontSize: isMobile
                  ? theme.components.OverviewCard.fontSize.medium
                  : theme.components.OverviewCard.fontSize.xlarge,
              }}
              variant="h1"
              color={"green"}
            >
              {roundSegments.length}
            </Typography>
          </OverviewCard>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-around" }}>
          <OverviewCard title={"KMS ANALYSED IN NETWORK"}>
            <Typography
              sx={{
                ...theme.components.OverviewCard,
                fontSize: isMobile
                  ? theme.components.OverviewCard.fontSize.small
                  : theme.components.OverviewCard.fontSize.large,
              }}
              variant="h1"
              color="green"
            >
              {kmsInNetwork
                ? `${kmsAnalysed.toFixed(2)}/${kmsInNetwork.toFixed(2)}`
                : kmsAnalysed.toFixed(2)}
            </Typography>
          </OverviewCard>
          <TreatmentCard />
        </Stack>
      </Stack>
    </Stack>
  );
}
