import dayjs from "dayjs";
import { localDatabase } from "./localDatabase";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./requests";

export default function sessionData(defaultHeader, isOnline) {
  async function get(roundId, amountToTake) {
    if (isOnline) {
      const suffix = amountToTake ? `?take=${amountToTake}` : "";
      return await getRequest(`session/${roundId}${suffix}`, defaultHeader);
    } else {
      return await getLocal();
    }
  }

  async function getSingle(id) {
    if (isOnline) {
      return await getRequest(`session/single/${id}`, defaultHeader);
    } else {
      const data = await localDatabase.session.get(id);
      return { status: 200, data };
    }
  }

  async function post(data) {
    if (isOnline) {
      return await postRequest(`session`, data, defaultHeader);
    } else {
      const id = await localDatabase.session.put({
        ...data,
        id: crypto.randomUUID(),
        updatedDate: dayjs().toDate(),
        segmentsCount: 0,
      });
      const localData = await localDatabase.session.get(id);
      return { status: 200, data: localData };
    }
  }

  async function count(userId) {
    if (isOnline) {
      return await getRequest(`user/${userId}/sessions/count`, defaultHeader);
    } else {
      const data = await localDatabase.session
        .where({ userId: userId })
        .count();
      return { status: 200, data };
    }
  }

  async function getLocal(amountToTake) {
    const data = await localDatabase.session
      .limit(amountToTake ?? 200)
      .toArray();
    return { status: 200, data };
  }

  async function update(session) {
    if (isOnline) {
      return await putRequest(`session`, session, defaultHeader);
    } else {
      await localDatabase.session.put({
        ...session,
      });
    }
  }

  async function syncLocal(id, round, user) {
    if (isOnline) {
      const session = await localDatabase.session.get(id);
      if (session) {
        session.roundId = round.id;
        session.userId = user.id;
        const results = await putRequest(`session`, session, defaultHeader);

        if (results.status === 200) {
          const localSegments = await localDatabase.segment
            .where("sessionId")
            .equals(id)
            .toArray();

          if (localSegments.length) {
            const segmentResults = await putRequest(
              `segment/bulk`,
              localSegments,
              defaultHeader
            );

            if (segmentResults.status !== 200) {
              //return the error if something went wrong.
              return segmentResults;
            }

            for (const segment of localSegments) {
              await localDatabase.segment.delete(segment.id);
            }
          }

          await localDatabase.session.delete(session.id);
        }

        return results;
      }
    }
  }

  async function deleteLocal(id) {
    if (isOnline) {
      const session = await localDatabase.session.get(id);
      if (session) {
        await localDatabase.session.delete(id);

        const localSegments = await localDatabase.segment
          .where("sessionId")
          .equals(id)
          .toArray();

        for (var segment of localSegments) {
          await localDatabase.segment.delete(segment.id);
        }

        return { status: 200 };
      }
    }
  }

  async function softDelete(id) {
    if (isOnline) {
      return await deleteRequest(`session/${id}`, defaultHeader);
    } else {
      await localDatabase.session.delete(id);
      return { status: 200 };
    }
  }

  return {
    get,
    getSingle,
    getLocal,
    post,
    count,
    syncLocal,
    deleteLocal,
    softDelete,
    update,
  };
}
