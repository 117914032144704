import { ruralHighwayB } from "./ruralHighwayB";
import { ruralHighwayA } from "./ruralHighwayA";
import { ruralRoadC } from "./ruralRoadC";
import { ruralRoadD } from "./ruralRoadD";
import { ruralRoadE } from "./ruralRoadE";
import { ruralLocalCollectorRoadF } from "./ruralLocalCollectorRoadF";
import { ruralLocalAccessRoadG } from "./ruralLocalAccessRoadG";
import { urbanArterialH } from "./urbanArterialH";
import { urbanArterialI } from "./urbanArterialI";
import { urbanLocalAccessRoadK } from "./urbanLocalAccessRoadK";
import { urbanLocalCollectorRoadJ } from "./urbanLocalCollectorRoadJ";
import { ruralHighwayUnsealedL } from "./ruralHighwayUnsealedL";
import { ruralHighwayUnsealedM } from "./ruralHighwayUnsealedM";
import { ruralHighwayUnsealedN } from "./ruralHighwayUnsealedN";
import { ruralLocalCollectorRoadO } from "./ruralLocalCollectorRoadO";
import { ruralLocalAccessRoadP } from "./ruralLocalAccessRoadP";
import { urbanLocalAccessRoadQ } from "./urbanLocalAccessRoadQ";

const roadChecklistData = [
  ruralHighwayB,
  ruralHighwayA,
  ruralRoadC,
  ruralRoadD,
  ruralRoadE,
  ruralLocalCollectorRoadF,
  ruralLocalAccessRoadG,
  urbanArterialH,
  urbanArterialI,
  urbanLocalAccessRoadK,
  urbanLocalCollectorRoadJ,
  ruralHighwayUnsealedL,
  ruralHighwayUnsealedM,
  ruralHighwayUnsealedN,
  ruralLocalCollectorRoadO,
  ruralLocalAccessRoadP,
  urbanLocalAccessRoadQ,
];

export default roadChecklistData;
