import React, { createContext, useContext, useState } from "react";

const TitleContext = createContext("");

export const useTitle = () => useContext(TitleContext);

export function TitleProvider({ children }) {
  const [title, setTitle] = useState("");

  const value = {
    title,
    setTitle,
  };

  return (
    <TitleContext.Provider value={value}>{children}</TitleContext.Provider>
  );
}
