import { useState } from "react";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import WhiteArrowIcon from "../icons/WhiteArrowIcon";
import { convertToPerthDate } from "../../helpers";
import { LightTextTableCell } from "./LightTextTableCell";

const direction = {
  Ascending: "asc",
  Descending: "desc",
};

export default function SortableSessionTable({
  headers,
  rows,
  canSelect = false,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: direction.Ascending,
  });

  function requestSort(key) {
    setSortConfig((prevState) => ({
      key,
      direction:
        prevState.direction === direction.Ascending
          ? direction.Descending
          : direction.Ascending,
    }));
  }

  const sessions = sortConfig.key
    ? [...rows].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === direction.Ascending ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === direction.Ascending ? 1 : -1;
        }
        return 0;
      })
    : rows;

  return (
    <>
      <Stack spacing={4}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <TableRow>
                {headers.map((header) => (
                  <LightTextTableCell
                    key={header.sortKey}
                    sortDirection={
                      sortConfig.key === header.sortKey
                        ? sortConfig.direction
                        : false
                    }
                  >
                    <TableSortLabel
                      direction={
                        sortConfig.key === header.sortKey
                          ? sortConfig.direction
                          : direction.Ascending
                      }
                      onClick={() => requestSort(header.sortKey)}
                      IconComponent={(props) => (
                        <WhiteArrowIcon
                          {...props}
                          direction={sortConfig.direction}
                          active={sortConfig.key === header.sortKey}
                        />
                      )}
                      sx={theme.components.TableSortLabel}
                    >
                      {header.name}
                    </TableSortLabel>
                  </LightTextTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={
                    canSelect
                      ? () => navigate(`/session/${row.id}/editsession`)
                      : null
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {convertToPerthDate(row.lastUpdated)}
                  </TableCell>
                  <TableCell align="left">{row.segmentsCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
