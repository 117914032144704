export const contextList = ["Rural", "Urban"];
export const sealList = ["Sealed", "Unsealed"];
export const carriagewayList = ["Single", "Divided"];
export const aadtList = [
  "15 000 or greater",
  "15 000 or less",
  "14 000–30 000",
  "4 000– 14 000",
  "2 000 or greater",
  "2 000– 8 000",
  "500–8 000",
  "500– 2 000",
  "250– 2 000",
  "0–1 000",
  "0–500",
  "< 500",
  "> 250",
  "< 250",
  "> 100",
  "< 100",
];
