import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAPI } from "../../hooks";

export default function ConfirmEmail() {
  const { email, code } = useParams();
  const { confirmEmail } = useAPI();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    async function confirm() {
      if (code && email) {
        const results = await confirmEmail(email, code);
        if (results.status === 200) {
          setMessage("Email confirmed. You can now log in.");
        } else {
          setMessage(
            "Your email could not be confirmed. Please try logging in again to resend the email. If this persists, please contact Walga."
          );
        }

        setIsLoading(false);
      }
    }

    confirm();
  }, [code, confirmEmail, email]);

  return (
    <Paper elevation={8} sx={{ padding: "3em", width: isMobile ? "90%" : "50%" }}>
      <Stack spacing={2}>
        {isLoading ? (
          <>
            <Typography>Confirming email. Please wait...</Typography>
            <CircularProgress />
          </>
        ) : (
          <>
            <Typography>{message}</Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => navigate("/")}
              sx={{ padding: "1em 0" }}
            >
              To Log In
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  );
}
