import React, { useState } from "react";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { fromSLKName, notesName, toSLKName } from "../common/formNames";
import { TextFieldInput } from "../../../../components";
import MapComponent from "../../../../map/MapComponent";

export default function SelectSLK({ data, onSubmit }) {
  const defaultValues = {
    [fromSLKName]: data.fromSLK ?? "",
    [notesName]: data.notes ?? "",
    [toSLKName]: data.toSLK ?? "",
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [mapIsEditingFrom, setMapIsEditingFrom] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues });

  const handleSLKChange = (newSLK) => {
    if (newSLK.fromSLK) {
      setValue(fromSLKName, newSLK.fromSLK);
      setMapIsEditingFrom(false);
    }
    if (newSLK.toSLK) {
      setValue(toSLKName, newSLK.toSLK);
      setMapIsEditingFrom(true);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h5">Select SLK</Typography>
        {!isMobile && (
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={() => handleSubmit(onSubmit)()}
            >
              Confirm SLK
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextFieldInput
            name={fromSLKName}
            label={`From (SLK)${mapIsEditingFrom ? " - map is editing" : ""}`}
            control={control}
            errors={errors}
            required={true}
          />
          <TextFieldInput
            name={toSLKName}
            label={`To (SLK)${mapIsEditingFrom ? "" : " - map is editing"}`}
            control={control}
            errors={errors}
            required={true}
          />
        </Stack>
        <TextFieldInput
          name={notesName}
          label="Notes"
          control={control}
          errors={errors}
          note="Note any landmarks or nearby features if LG Stars is running in offline mode."
        />
        <Box
          sx={{
            position: "relative",
            height: isMobile ? "50vh" : "68vh",
            width: "100%",
          }}
        >
          <MapComponent
            onSLKChange={handleSLKChange}
            roadName={data.roadName}
          />
        </Box>
        {isMobile && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto", padding: "0.5em 2em" }}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Confirm SLK
          </Button>
        )}
      </Stack>
    </>
  );
}
