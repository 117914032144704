import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect, useState } from "react";
import { useAPI, useRound, useSnackbar } from "../../hooks";
import { useNavigate } from "react-router-dom";

export default function CreateSessionModal({ open, onClose }) {
  const round = useRound();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { currentUser, sessions } = useAPI();
  const showSnackbar = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    async function getAndSetInitialName() {
      if (currentUser) {
        const results = await sessions.count(currentUser.id);
        if (results?.status === 200) {
          const sessionCount = results.data;
          setName(
            `${currentUser.governmentName.replace(/\s/g, "")}${
              sessionCount + 1
            }`
          );
        }
      }
    }

    getAndSetInitialName();
  }, [sessions, currentUser]);

  async function onNewSession() {
    const session = await sessions.post({
      roundId: round.id,
      userId: currentUser.id,
      name: name,
    });
    if (session.status === 200) {
      navigate(`/session/${session.data.id}/segment`);
    } else {
      console.log(session.data);
      showSnackbar("Create session failed!", "error");
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Stack spacing={3} sx={{ padding: "1em 2em 1em 2em" }}>
        <Stack direction="row">
          <Stack sx={{ flex: 1 }}>
            <Typography variant={isMobile ? "h6" : "h5"}>
              Create New Session
            </Typography>
          </Stack>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography>Session Name:</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "auto", padding: "0.5em 2em" }}
          onClick={onNewSession}
        >
          Create Session
        </Button>
      </Stack>
    </Dialog>
  );
}
