/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import SegmentsTable from "./components/SegmentsTable";
import { segmentTableHeaders } from "./segmentTableData";
import { useAPI } from "../../hooks";

export default function LoadedSegments() {
  const location = useLocation();
  const [rowData, setRowData] = useState([]);
  const { segments } = useAPI();

  const fetchSegments = async () => {
    try {
      let ids = location.state?.loadedSegmentIds
        ? location.state?.loadedSegmentIds
        : [...location.state];
      const response = await segments.getWithIds(ids);
      setRowData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSegments();
  }, []);

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h2">
          Road information loaded successfully
        </Typography>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Select starting segment</Typography>
        </Stack>
        <SegmentsTable
          headers={segmentTableHeaders}
          rows={rowData}
          onDataChange={fetchSegments}
          isClickable={true}
        />
      </Stack>
    </>
  );
}
