/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAPI, useLoadingModal, useRound, useSnackbar } from "../../hooks";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { ChoiceModal } from "../../components";

const headers = ["Name", "Updated Date", "Segment Count", ""];

export default function Sync() {
  const { currentUser, sessions, checkForLocalData } = useAPI();
  const [rows, setRows] = useState([]);
  const [deletingId, setDeletingId] = useState("");
  const theme = useTheme();
  const setLoading = useLoadingModal();
  const showSnackbar = useSnackbar();
  const round = useRound();

  useEffect(() => {
    getRows();
  }, []);

  async function getRows() {
    const results = await sessions.getLocal();
    if (results.status === 200) {
      setRows(results.data);
    }
  }

  async function handleUpload(id) {
    setLoading(true);
    const results = await sessions.syncLocal(id, round, currentUser);

    if (results.status === 200) {
      await getRows();

      showSnackbar("Session uploaded successfully.", "success");
    } else {
      console.log(results.data);
      showSnackbar("Failed to upload.", "error");
    }

    checkForLocalData();
    setLoading(false);
  }

  function handleDelete(id) {
    setDeletingId(id);
  }

  async function onDeleteDecision(confirmDelete) {
    if (confirmDelete && deletingId) {
      try {
        setLoading(true);
        await sessions.deleteLocal(deletingId);
        await getRows();
      } catch (error) {
        console.error("Failed to delete session:", error);
      } finally {
        setDeletingId("");
        setLoading(false);
        checkForLocalData();
      }
    } else {
      setDeletingId("");
    }
  }

  return (
    <>
      <Typography variant="h4">Sync</Typography>
      <Divider />
      <Stack spacing={2}>
        <Typography>
          These Sessions are stored locally, but not online.
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.lastUpdated}</TableCell>
                  <TableCell align="left">{row.segmentsCount}</TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ justifyContent: "end" }}
                    >
                      <IconButton
                        aria-label="Upload"
                        color="success"
                        onClick={() => handleUpload(row.id)}
                      >
                        <UploadIcon />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        color="error"
                        onClick={() => handleDelete(row.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <ChoiceModal
        open={deletingId.length > 0}
        text="Are you sure you want to delete this session?"
        yesText="Yes"
        noText="No"
        onYes={() => onDeleteDecision(true)}
        onNo={() => onDeleteDecision(false)}
        onClose={() => onDeleteDecision(false)}
      />
    </>
  );
}
