import React, { useState } from "react";
import { Form, Instructions, Results } from "./components";
import { calculateResourceToUse } from "./common/formResults";
import { CreateSessionModal } from "../../components";

export default function Questionnaire() {
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  const [createSessionOpen, setCreateSessionOpen] = useState(false);
  const [result, setResult] = useState(null);

  function onSubmit(form) {
    const resource = calculateResourceToUse(form);
    setResult(resource);
  }

  return (
    <>
      {result ? (
        <Results result={result} setCreateSessionOpen={setCreateSessionOpen} />
      ) : startQuestionnaire ? (
        <Form onSubmit={onSubmit} />
      ) : (
        <Instructions
          setStartQuestionnaire={setStartQuestionnaire}
          setCreateSessionOpen={setCreateSessionOpen}
        />
      )}
      <CreateSessionModal
        open={createSessionOpen}
        onClose={() => setCreateSessionOpen(false)}
      />
    </>
  );
}
