import { Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../header/Header";
import { useTitle } from "../../hooks";
import DataUsageCheckModal from "../modal/DataUsageCheckModal";
import { LoginProvider } from "../../hooks";

export default function Layout({ title, useTitleContext, isAdmin = false, children }) {
  const { title: titleContext } = useTitle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <LoginProvider>
      <Header
        title={useTitleContext ? titleContext ?? title : title}
        isAdmin={isAdmin}
      />
      <Stack spacing={3} sx={{ padding: isMobile ? "1em 1em" : "1em 3em" }}>
        <DataUsageCheckModal>{children}</DataUsageCheckModal>
      </Stack>
    </LoginProvider>
  );
}
