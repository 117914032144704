import React from "react";
import { Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function DateFieldInput({
  name,
  label,
  control,
  required,
  errors,
}) {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        defaultValue={dayjs()}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={label ?? name}
            slotProps={{
              textField: { variant: "filled" },
            }}
          />
        )}
      />
      {errors[name] && (
        <Typography color={theme.palette.error.main}>
          This is required.
        </Typography>
      )}
    </Stack>
  );
}
