import Polyline from "@arcgis/core/geometry/Polyline";
import BigNumber from "bignumber.js";

export const emptySLK = { toSLK: null, fromSLK: null };

export function calculateKms(segments) {
  return segments.reduce(
    (acc, curr) =>
      new BigNumber(acc)
        .plus(new BigNumber(curr?.toSLK ?? 0).minus(curr?.fromSLK ?? 0).abs())
        .toNumber(),
    0
  );
}

export function computeSLK(length, prevSlk) {
  return prevSlk.toSLK && prevSlk.fromSLK
    ? { ...emptySLK, fromSLK: length.toFixed(2) }
    : prevSlk.fromSLK
    ? { ...prevSlk, toSLK: length.toFixed(2) }
    : { ...prevSlk, fromSLK: length.toFixed(2) };
}

export function createPolyline(clickedGraphic, nearestCoordinate) {
  return new Polyline({
    paths: [
      [
        clickedGraphic.geometry.paths[0][0],
        [nearestCoordinate.coordinate.x, nearestCoordinate.coordinate.y],
      ],
    ],
    spatialReference: { wkid: 102100 },
  });
}
