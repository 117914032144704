import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  EmailNotConfirmed,
  ForgotPassword,
  LoginCard,
  LoginOffline,
  SignUpCard,
} from "./components";
import { passwordName, userName } from "./common/formNames";
import { useIsOnline, useLoadingModal, useSnackbar } from "../../hooks";
import { UnknownEmailModal } from "../../components";
import { Paper, Typography, useMediaQuery } from "@mui/material";

export default function Login({ login, logout, signUp, forgotPassword, user }) {
  const showSnackbar = useSnackbar();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [unknownEmail, setUnknownEmail] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const isOnline = useIsOnline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const setLoading = useLoadingModal();

  useEffect(() => {
    setTimeout(() => setShowLogin(true), 2000);
  }, []);

  async function onLogin(form) {
    setLoading(true);
    const loggedIn = await login(form[userName], form[passwordName]);
    if (!loggedIn) {
      showSnackbar("Login failed!", "error");
    }
    setLoading(false);
  }

  async function onSignUp(form) {
    setLoading(true);
    const results = await signUp(form[userName], form[passwordName]);
    if (results.status !== 200) {
      showSnackbar(`Sign Up failed! ${results.data} `, "error");
      if (results.data === "Email provided is not a local government email") {
        setUnknownEmail(true);
      }
    }

    setLoading(false);
  }

  function onForgotPassword() {
    setShowForgotPassword(true);
  }

  return (
    <>
      <Typography
        variant="h1"
        color={theme.palette.primary.contrastText}
        fontWeight={700}
      >
        LG Stars
      </Typography>
      <Typography variant="h2" color={theme.palette.primary.contrastText}>
        Safety Ratings Tool for Local Government Roads
      </Typography>
      {showLogin ? (
        <Paper
          elevation={8}
          sx={{ padding: "3em", width: isMobile ? "90%" : "50%" }}
        >
          {user?.emailConfirmed === false ? (
            <EmailNotConfirmed showSignUp={showSignUp} logout={logout} />
          ) : showSignUp ? (
            <SignUpCard onSignUp={onSignUp} setShowSignUp={setShowSignUp} />
          ) : showForgotPassword ? (
            <ForgotPassword
              setShowForgotPassword={setShowForgotPassword}
              setLoggingIn={setLoading}
              forgotPassword={forgotPassword}
            />
          ) : isOnline ? (
            <LoginCard
              onLogin={onLogin}
              onForgotPassword={onForgotPassword}
              setShowSignUp={setShowSignUp}
            />
          ) : (
            <LoginOffline onLogin={onLogin} />
          )}
        </Paper>
      ) : (
        false
      )}
      <UnknownEmailModal
        open={unknownEmail}
        onClose={() => setUnknownEmail(false)}
      />
    </>
  );
}
