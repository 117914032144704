export const levelSafetyRate = "LevelSafetyRate";
export const trainingCompleted = "TrainingCompleted";
export const requiredAccreditation = "RequiredAccreditation";
export const timeCommitment = "TimeCommitment";
export const fundingLevel = "FundingLevel";
export const vehicleAvailable = "VehicleAvailable";
export const driveCapacity = "DriveCapacity";

export const veryGoodName = "Very Good";
export const goodName = "Good";
export const acceptableName = "Acceptable";
export const highName = "High";
export const moderateName = "Moderate";
export const lowName = "Low";

export const formResultsToolName = "Road Safety Ratings Tool";
