import React from "react";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";

export default function WhiteArrowIcon({ direction, active }) {
  const theme = useTheme();

  return direction === "asc" ? (
    <ArrowDropUpIcon
      style={{
        color: active
          ? theme.palette.secondary.main
          : theme.palette.primary.contrastText,
      }}
    />
  ) : (
    <ArrowDropDownIcon
      style={{
        color: active
          ? theme.palette.secondary.main
          : theme.palette.primary.contrastText,
      }}
    />
  );
}
