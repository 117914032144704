import React from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCSVDownloader } from "react-papaparse";
import { useNavigate, useParams } from "react-router-dom";
import { UploadCSV } from "../../pages";
import { useAPI } from "../../hooks";

export default function Segment() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { CSVDownloader } = useCSVDownloader();
  const { id } = useParams();
  const { segments } = useAPI();

  function getCSVTemplate() {
    return [
      {
        "Road Name": "",
        "Main Roads Unique ID (ROAD)": "",
        "Main Roads ID (ROUTE_NE_ID)": "",
        "Local Government Unique ID": "",
        "From SLK": "",
        "To SLK": "",
        "Local Government Name": "",
      },
    ];
  }

  async function onNewSegment() {
    const results = await segments.post({ sessionId: id });
    const segment = results.data;
    navigate(`/session/${id}/segment/${segment.id}`);
  }

  return (
    <>
      <Typography variant="h5">
        How would you like to define your segments?
      </Typography>
      <Stack spacing={1}>
        <Typography variant="h6">Individual segments</Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Manually input segments details like Road Name, Unique ID and SLK
          values.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "fit-content", padding: "0.5em 2em" }}
          onClick={onNewSegment}
        >
          Input Individual Segments
        </Button>
      </Stack>

      <Divider />

      <Stack spacing={1}>
        <Typography variant="h6">File Upload</Typography>
        <Typography sx={{ color: theme.palette.text.secondary }}>
          Upload a file with predefined segments, including Road Name, Unique ID
          and SLK values.
        </Typography>
        <CSVDownloader
          bom
          filename="walga-road-segment-template"
          data={getCSVTemplate}
        >
          <Button
            variant="outlined"
            sx={{ width: "fit-content", padding: "0.5em 2em" }}
          >
            Download Template File
          </Button>
        </CSVDownloader>
      </Stack>

      <UploadCSV sessionId={id} />
    </>
  );
}
