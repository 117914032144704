import React, { useState } from "react";

import { Stack, Typography, Switch, useMediaQuery } from "@mui/material";

import SegmentLookup from "../segments/SegmentLookup";
import SessionLookup from "../sessions/SessionLookup";
import { useRound } from "../../hooks";

export default function PreviousLookup() {
  const [isSegmentPage, setIsSegmentPage] = useState(false);
  const round = useRound();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleToggleChange = () => {
    setIsSegmentPage((prev) => !prev);
  };

  return (
    <>
      <Stack spacing={4}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">
            {isSegmentPage
              ? `Segments in the ${round?.governmentArea.name}`
              : `Sessions in the ${round?.governmentArea.name}`}
          </Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
            }}
          >
            <Switch
              checked={isSegmentPage}
              onChange={handleToggleChange}
              inputProps={{ "aria-label": "Toggle switch" }}
            />
            <Typography align="right">
              {isSegmentPage ? "Show Segments" : "Show Sessions"}
            </Typography>
          </Stack>
        </Stack>
        {isSegmentPage ? <SegmentLookup /> : <SessionLookup />}
      </Stack>
    </>
  );
}
