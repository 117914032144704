import React, { useState } from "react";

import { Drawer, List, IconButton, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import SyncIcon from "@mui/icons-material/Sync";
import PeopleIcon from "@mui/icons-material/People";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { NavbarListItem } from "./NavbarListItem";
import {
  navbarListItems,
  navbarListItemsIconEnum,
  navbarListItemsTextEnum,
} from "./navbarData";
import { NewSessionModal } from "../index";
import { useAPI, useIsOnline, useLogin } from "../../hooks";

export default function NavBarDrawer({ isAdmin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [goToNewSession, setGoToNewSession] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useLogin();
  const isOnline = useIsOnline();
  const { hasLocalData, currentUser } = useAPI();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (e, path, text) => {
    if (text === navbarListItemsTextEnum.NEW_SESSION) {
      setGoToNewSession(true);
    } else if (text === navbarListItemsTextEnum.LOG_OUT) {
      logout(e);
    } else {
      navigate(path);
    }
    setIsOpen(false);
  };

  function getIcon(icon) {
    switch (icon) {
      case navbarListItemsIconEnum.DASHBOARD:
        return <HomeIcon />;
      case navbarListItemsIconEnum.NEW_SESSION:
        return <AddIcon />;
      case navbarListItemsIconEnum.SESSIONS_AND_SEGMENTS:
        return <SearchIcon />;
      case navbarListItemsIconEnum.HOW_TO_USE_THE_TOOL:
        return <InfoIcon />;
      case navbarListItemsIconEnum.LINKS_AND_RESOURCES:
        return <OpenInNewIcon />;
      case navbarListItemsIconEnum.NOTES:
        return <ContentPasteIcon />;
      case navbarListItemsIconEnum.PREFERENCES:
        return <SettingsIcon />;
      case navbarListItemsIconEnum.LOG_OUT:
        return <LogoutIcon />;
      case navbarListItemsIconEnum.ADMIN_USERS:
        return <PeopleIcon />;
      case navbarListItemsIconEnum.ADMIN_REGIONS:
        return <LocationOnIcon />;
      default:
        return <OpenInNewIcon />;
    }
  }

  return (
    <>
      <IconButton onClick={handleToggle}>
        <MenuIcon sx={{ color: theme.palette.primary.contrastText }} />
      </IconButton>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={handleToggle}
        sx={{
          "& .MuiDrawer-paper": {
            marginTop: (theme) => theme.spacing(8),
            height: `calc(100% - ${(theme) => theme.spacing(8)})`,
          },
        }}
      >
        <List>
          {currentUser.isAdmin ? (
            isAdmin ? (
              <>
                <NavbarListItem
                  text="Back to Dashboard"
                  icon={<HomeIcon />}
                  handleSelect={() => navigate("/")}
                />
                <Divider sx={{ m: 0, p: 0 }} />
              </>
            ) : (
              <>
                <NavbarListItem
                  text="Admin"
                  icon={<AdminPanelSettingsIcon />}
                  handleSelect={() => navigate("/admin/users")}
                />
                <Divider sx={{ m: 0, p: 0 }} />
              </>
            )
          ) : (
            <></>
          )}
          {hasLocalData && isOnline && !isAdmin ? (
            <>
              <NavbarListItem
                text="Sync Local Data"
                icon={<SyncIcon />}
                handleSelect={() => navigate("/sync")}
              />
              <Divider sx={{ m: 0, p: 0 }} />
            </>
          ) : (
            <></>
          )}
          {navbarListItems.map((item, index) => {
            if (item.isAdmin === isAdmin) {
              return (
                <React.Fragment key={index}>
                  <NavbarListItem
                    text={item.text}
                    icon={getIcon(item.icon)}
                    handleSelect={(e) => handleSelect(e, item.path, item.text)}
                  />
                  <Divider sx={{ m: 0, p: 0 }} />
                </React.Fragment>
              );
            }

            return <React.Fragment key={index} />;
          })}
        </List>
      </Drawer>
      <NewSessionModal
        showChoiceModal={goToNewSession}
        onClose={() => setGoToNewSession(false)}
      />
    </>
  );
}
