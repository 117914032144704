import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ChoiceModal,
  OverviewCard,
  TopSection,
  UploadModal,
} from "../../components";
import { useAPI } from "../../hooks";
import SegmentsTable from "../segments/components/SegmentsTable";
import { detailedSegmentTableHeaders } from "../segments/segmentTableData";
import { calculateKms, calculateRating, renderStars } from "../../helpers";

export default function SessionSummary({ onFinaliseSession }) {
  const { id } = useParams();
  const { segments, sessions } = useAPI();
  const navigate = useNavigate();
  const [sessionSegments, setSessionSegments] = useState([]);
  const [kmsAnalysed, setKmsAnalysed] = useState(0);
  const [starRating, setStarRating] = useState(0);
  const [decisionDialog, setDecisionDialog] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const today = dayjs();
  const formattedDate = today.format("DD/MM/YYYY");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchSegments = async () => {
      const result = await segments.getSegmentsForSession(id);
      setSessionSegments(result.data);
    };
    fetchSegments();
  }, [id, segments]);

  useEffect(() => {
    setKmsAnalysed(calculateKms(sessionSegments));
    setStarRating(calculateRating(sessionSegments));
  }, [sessionSegments]);

  async function handleFinaliseSession() {
    setUploading(true);
    try {
      await onFinaliseSession();
      setUploading(false);
      setShowSuccessModal(true);
    } catch (error) {
      setUploading(false);
      console.error("Failed to finalise session: ", error);
    }
  }

  function handleExitSession() {
    setDecisionDialog(true);
  }

  async function handleDecision(discard) {
    if (discard) {
      try {
        await sessions.softDelete(id);
      } catch (error) {
        console.error("Failed to delete session:", error);
      } finally {
        setDecisionDialog(false);
        navigate("/");
      }
    } else {
      setDecisionDialog(false);
      navigate("/");
    }
  }

  const stars = renderStars(starRating, theme, isMobile);

  return (
    <>
      <TopSection>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Box>
            <Typography variant="h5">Session Details</Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Date: {formattedDate}
            </Typography>
          </Box>
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            sx={isMobile ? {} : { alignItems: "center" }}
          >
            <Button variant="outlined" onClick={handleExitSession}>
              Exit Session
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFinaliseSession}
            >
              Finalise Session
            </Button>
          </Stack>
        </Stack>
      </TopSection>
      <Stack>
        <Typography variant="h5">Overview</Typography>
        <Stack direction="row" sx={{ justifyContent: "space-around" }}>
          <OverviewCard title={"KMS ANALYSED"}>
            <Typography
              sx={{
                ...theme.components.OverviewCard,
                paddingTop: isMobile ? "1em" : "0.3em",
                fontSize: isMobile
                  ? theme.components.OverviewCard.fontSize.medium
                  : theme.components.OverviewCard.fontSize.xlarge,
              }}
              variant="h1"
            >
              {kmsAnalysed.toFixed(2)}
            </Typography>
          </OverviewCard>
          <OverviewCard title={"AVERAGE RATING"}>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100px",
              }}
            >
              {stars}
            </Stack>
          </OverviewCard>
        </Stack>
      </Stack>
      <Stack spacing={2} sx={{ flex: "1 0 auto" }}>
        <Typography variant="h5">Segments</Typography>
        <SegmentsTable
          headers={detailedSegmentTableHeaders}
          rows={sessionSegments}
          includeRatings={true}
          canDelete={false}
        />
      </Stack>
      <UploadModal
        open={uploading}
        topText={`Adding to Round Summary`}
        bottomText={"Please wait..."}
        icon={<CircularProgress />}
      />
      <UploadModal
        open={showSuccessModal}
        topText={`Session Added!`}
        bottomText={"Thank you for keeping WA's roads safe."}
        icon={<CheckCircleIcon style={{ color: "green", fontSize: "50px" }} />}
      />
      <ChoiceModal
        open={decisionDialog}
        text="You are about to exit this session. Would you like to save or discard your work?"
        yesText="Save and Exit"
        noText="Discard"
        titleText={"Exit Session"}
        onYes={() => handleDecision(false)}
        onNo={() => handleDecision(true)}
        onClose={() => handleDecision(false)}
      />
    </>
  );
}
