import React, { createContext, useContext, useState } from "react";
import { LoadingModal } from "../components";

const LoadingModalContext = createContext("");

export const useLoadingModal = () => useContext(LoadingModalContext);

export function LoadingModalProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingModalContext.Provider value={setLoading}>
      {children}
      <LoadingModal open={loading} />
    </LoadingModalContext.Provider>
  );
}
