import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function CrossSectionTable({
  roadTitle,
  roadInformation,
  rows = [],
}) {
  const rowDataArray = Object.values(rows).map((row) => {
    return {
      ...row,
      data: row.data.map((dataItem) => {
        const parts = dataItem.split("|");
        return parts.length > 1 ? parts[1].trim() : parts[0];
      }),
    };
  });
  const commaIndex = roadInformation.indexOf(",") + 1;
  const attributeInformation = roadInformation.substring(commaIndex).trim();
  return (
    <Stack>
      <Typography variant="h3" sx={{ paddingTop: "0.8em" }}>
        {roadTitle}
      </Typography>
      <Typography>{attributeInformation}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attribute</TableCell>
              <TableCell>{"<1 Star"}</TableCell>
              <TableCell>1 Star</TableCell>
              <TableCell>3 Star</TableCell>
              <TableCell>5 Star</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDataArray.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.label}</TableCell>
                <TableCell>{row.data[0]}</TableCell>
                <TableCell>{row.data[row.readOnly ? 0 : 1]}</TableCell>
                <TableCell>{row.data[row.readOnly ? 0 : 2]}</TableCell>
                <TableCell>{row.data[row.readOnly ? 0 : 3]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
