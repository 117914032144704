export const ruralLocalCollectorRoadO = {
  "Road Type":
    "Rural local collector road, Single Carriageway, two-lane two-way, 50/60/70/80/90/100/110 km/h, AADT > 250",
  Name: "Rural Local Collector Road O",
  Context: "Rural",
  Seal: "Unsealed",
  Carriageway: "Single",
  Laneage: "Two-lane",
  "Approximate Speed Limit": "110/100/90/80/70/60/50 km/h",
  AADT: ">250",
  attributes: {
    speedLimit: {
      name: "speedLimit",
      label: "Speed Limit",
      rulesMessage: "Please select a speed limit",
      readOnly: false,
      data: [
        "<1 Star | 110km/h",
        "1 Star | 80-100km/h",
        "3 Stars | 60-70km/h",
        "5 Stars | 40-50km/h",
      ],
    },
    curvature: {
      name: "curvature",
      label: "Curvature",
      rulesMessage: "Please select a curvature",
      readOnly: true,
      data: ["Moderate"],
    },
    skidResistance: {
      name: "skidResistance",
      label: "Skid Resistance",
      rulesMessage: "Please select a skid resistance",
      readOnly: false,
      data: ["<1 Star | Unsealed - poor", "3 Stars | Unsealed - adequate"],
    },
    laneWidth: {
      name: "laneWidth",
      label: "Lane Width",
      rulesMessage: "Please select a lane width",
      readOnly: false,
      data: ["1 Star | Narrow", "3 Stars | Medium", "5 Stars | Wide"],
    },
    roadsideHazards: {
      name: "roadsideHazards",
      label: "Roadside Hazards (linked to Roadside Objects)",
      rulesMessage: "Please select roadside hazards",
      readOnly: false,
      data: [
        "<1 Star | 0-1m from roadside",
        "1 Star | 1-5m from roadside",
        "3 Stars | 5-10m from roadside",
        "5 Stars | 10+m from roadside",
      ],
    },
    roadCondition: {
      name: "roadCondition",
      label: "Road Condition",
      rulesMessage: "Please select a road condition",
      readOnly: false,
      data: ["<1 Star | Poor", "3 Stars | Good"],
    },
    delineation: {
      name: "delineation",
      label: "Delineation",
      rulesMessage: "Please select a delineation",
      readOnly: true,
      data: ["Poor"],
    },
    lanes: {
      name: "lanes",
      label: "Number of Lanes",
      rulesMessage: "Please select lanes",
      readOnly: true,
      data: ["2"],
    },
    carriageway: {
      name: "carriageway",
      label: "Carriageway",
      rulesMessage: "Please select a carriageway",
      readOnly: true,
      data: ["Undivided road"],
    },
    pavedShoulder: {
      name: "pavedShoulder",

      label: "Paved Shoulder",
      rulesMessage: "Paved shoulder not possible for unsealed road",
      readOnly: true,
      data: ["None"],
    },
    roadsideObjects: {
      name: "roadsideObjects",
      label: "Roadside Objects (linked to Roadside Hazards)",
      rulesMessage: "Please select roadside objects",
      readOnly: false,
      data: [
        "<1 Star | Tree",
        "1 Star | Tree",
        "3 Stars | Tree",
        "5 Stars | None",
      ],
    },
    shoulderRumbleStrips: {
      name: "shoulderRumbleStrips",
      label: "Shoulder Rumble Strips",
      rulesMessage: "Shoulder rumble strips not possible for unsealed road",
      readOnly: true,
      data: ["Not possible"],
    },
    centrelineRumbleStrips: {
      name: "centrelineRumbleStrips",
      label: "Centreline Rumble Strips",
      rulesMessage: "Centreline rumble strips not possible for unsealed road",
      readOnly: true,
      data: ["Not possible"],
    },
    medianType: {
      name: "medianType",
      label: "Median Type",
      rulesMessage: "Median type not possible for unsealed road",
      readOnly: true,
      data: ["Not possible"],
    },
    propertyAccessPoints: {
      name: "propertyAccessPoints",
      label: "Property Access Points",
      rulesMessage: "Please select property access points",
      readOnly: true,
      data: ["Residential 1 or 2"],
    },
  },
};
