import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

export default function DropdownInput({
  name,
  label,
  control,
  required,
  errors,
  data,
}) {
  const theme = useTheme();
  const labelId = `${label}-id`;

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <FormControl variant="filled" sx={{ minWidth: "20%" }}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          rules={{ required: required }}
          control={control}
          name={name}
          defaultValue={""}
          render={({ field }) => (
            <Select labelId={labelId} label={label} {...field}>
              <MenuItem value={""}>Please Select</MenuItem>
              {data.map((d) => {
                return (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      </FormControl>

      {errors[name] && (
        <Typography color={theme.palette.error.main}>
          This is required.
        </Typography>
      )}
    </Stack>
  );
}
