export const urbanLocalAccessRoadK = {
  "Road Type":
    "Urban local access road, Single Carriageway, two-lane two-way 30/40/50/60/70 km/h, AADT 0–500",
  Name: "Urban Local Access Road K",
  Context: "Urban",
  Seal: "Sealed",
  Carriageway: "Single",
  Laneage: "Two-lane",
  "Approximate Speed Limit": "70/60/50/40/30 km/h",
  AADT: "0-500",
  attributes: {
    speedLimit: {
      name: "speedLimit",
      label: "Speed Limit",
      rulesMessage: "Please select a speed limit",
      readOnly: false,
      data: [
        "<1 Star | 70km/h",
        "1 Star | 60km/h",
        "3 Stars | 50km/h",
        "5 Stars | 30-40km/h",
      ],
    },
    curvature: {
      name: "curvature",
      label: "Curvature",
      rulesMessage: "Please select a curvature",
      readOnly: true,
      data: ["Straight or gently curving"],
    },
    skidResistance: {
      name: "skidResistance",
      label: "Skid Resistance",
      rulesMessage: "Please select a skid resistance",
      readOnly: false,
      data: [
        "1 Star | Sealed - poor",
        "3 Stars | Sealed - medium",
        "5 Stars | Sealed - adequate",
      ],
    },
    laneWidth: {
      name: "laneWidth",
      label: "Lane Width",
      rulesMessage: "Please select a lane width",
      readOnly: false,
      data: ["1 Star | Narrow", "5 Stars | Medium"],
    },
    roadsideHazards: {
      name: "roadsideHazards",
      label: "Roadside Hazards",
      rulesMessage: "Please select roadside hazards",
      readOnly: false,
      data: ["1 Star | 0-1m from roadside", "3 Stars | 1-5m from roadside"],
    },
    roadCondition: {
      name: "roadCondition",
      label: "Road Condition",
      rulesMessage: "Please select a road condition",
      readOnly: false,
      data: ["1 Star | Poor", "3 Stars | Medium", "5 Stars | Good"],
    },
    delineation: {
      name: "delineation",
      label: "Delineation",
      rulesMessage: "Please select a delineation",
      readOnly: false,
      data: ["1 Star | Poor", "5 Stars | Adequate"],
    },
    lanes: {
      name: "lanes",
      label: "Number of Lanes",
      rulesMessage: "Please select lanes",
      readOnly: true,
      data: ["2"],
    },
    carriageway: {
      name: "carriageway",
      label: "Carriageway",
      rulesMessage: "Please select a carriageway",
      readOnly: true,
      data: ["Undivided road"],
    },
    pavedShoulder: {
      name: "pavedShoulder",
      label: "Paved Shoulder",
      rulesMessage: "Please select a paved shoulder",
      readOnly: false,
      data: ["<1 Star | None", "1 Star | Narrow", "5 Stars | Medium"],
    },
    roadsideObjects: {
      name: "roadsideObjects",
      label: "Roadside Objects",
      rulesMessage: "Please select roadside objects",
      readOnly: true,
      data: ["Tree/Pole"],
    },
    shoulderRumbleStrips: {
      name: "shoulderRumbleStrips",
      label: "Shoulder Rumble Strips",
      rulesMessage: "Please select shoulder rumble strips",
      readOnly: true,
      data: ["Not present"],
    },
    centrelineRumbleStrips: {
      name: "centrelineRumbleStrips",
      label: "Centreline Rumble Strips",
      rulesMessage: "Please select centreline rumble strips",
      readOnly: true,
      data: ["Not present"],
    },
    medianType: {
      name: "medianType",
      label: "Median Type",
      rulesMessage: "Please select a median type",
      readOnly: false,
      data: ["<1 Star | None", "3 Stars | Centre line"],
    },
    propertyAccessPoints: {
      name: "propertyAccessPoints",
      label: "Property Access Points",
      rulesMessage: "Please select property access points",
      readOnly: true,
      data: ["Residential 3+"],
    },
    intersectionType: {
      name: "intersectionType",
      label: "Intersection Type",
      rulesMessage: "Please select an intersection type",
      readOnly: true,
      data: ["None"],
    },
    intersectingVolume: {
      name: "intersectingVolume",
      label: "Intersecting Volume",
      rulesMessage: "Please select intersecting volume",
      readOnly: true,
      data: ["Two sides"],
    },
    bicycleFacilities: {
      name: "bicycleFacilities",
      label: "Bicycle Facilities",
      rulesMessage: "Please select bicycle facilities",
      readOnly: true,
      data: ["Not Present"],
    },
    pedestrianFacilities: {
      name: "pedestrianFacilities",
      label: "Pedestrian Facilities",
      rulesMessage: "Please select pedestrian facilities",
      readOnly: false,
      data: [
        "1 Star | Informal path 0m to <1m",
        "1 Star | Informal path (>= 1.0m)",
        "5 Stars | Path with Physical barrier",
      ],
    },
    streetLighting: {
      name: "streetLighting",
      label: "Street Lighting",
      rulesMessage: "Please select street lighting",
      readOnly: false,
      data: ["1 Star | Not Present", "5 Stars | Present"],
    },
  },
};
