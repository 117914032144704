import React, { useEffect, useState } from "react";
import { Button, Stack, TextField, useMediaQuery } from "@mui/material";
import { NewSessionModal, SortableSessionTable } from "../../components";
import { sortableTableHeaders } from "./headerData";
import { useAPI, useRound } from "../../hooks";

export default function SessionLookup() {
  const [rowData, setRowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [goToNewSession, setGoToNewSession] = useState(false);
  const { sessions } = useAPI();
  const round = useRound();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sessions.get(round.id);
        setRowData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (round) {
      fetchData();
    }
  }, [sessions, round]);

  const filteredData = rowData.filter((row) =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Stack spacing={4}>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          label="Search by Session Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            sx={{ width: "auto" }}
            onClick={() => setGoToNewSession(true)}
          >
            New Session
          </Button>
        </Stack>
      </Stack>
      <SortableSessionTable
        canSelect={true}
        headers={sortableTableHeaders}
        rows={filteredData}
      ></SortableSessionTable>
      <NewSessionModal
        showChoiceModal={goToNewSession}
        onClose={() => setGoToNewSession(false)}
      />
    </Stack>
  );
}
