import React from "react";
import {
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavBarDrawer } from "../../components";
import logo from "../../images/logo.png";
import { useAPI, useIsOnline } from "../../hooks";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";

export default function Header({ title, isAdmin }) {
  const theme = useTheme();
  const isOnline = useIsOnline();
  const { hasLocalData } = useAPI();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Paper
      elevation={4}
      sx={{
        backgroundColor: theme.palette.primary.main,
        padding: "0 0.5em",
        marginBottom: "1em",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ height: "64px", alignItems: "center" }}
      >
        <NavBarDrawer isAdmin={isAdmin} />
        <Typography
          variant={isMobile ? "body1" : "h6"}
          fontWeight={500}
          sx={{ color: theme.palette.primary.contrastText }}
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ flex: 1, alignItems: "center", justifyContent: "end" }}
        >
          {!isOnline ? (
            <Tooltip title="You are offline. Any data created will sync the next time you are online.">
              <WifiOffIcon color="secondary" />
            </Tooltip>
          ) : hasLocalData ? (
            <Tooltip title="You have offline data to sync. Click here to view and sync.">
              <IconButton onClick={() => navigate("/sync")}>
                <SyncIcon sx={{ color: theme.palette.common.white }} />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          <img alt="Walga Logo" src={logo} height="47px" width="96px" />
        </Stack>
      </Stack>
    </Paper>
  );
}
