//create enum for navbarListItems
export const navbarListItemsTextEnum = {
  DASHBOARD: "Dashboard",
  NEW_SESSION: "New Session",
  SESSIONS_AND_SEGMENTS: "Sessions & Segments",
  HOW_TO_USE_THE_TOOL: "How To Use The Tool",
  LINKS_AND_RESOURCES: "Links & Resources",
  NOTES: "Notes",
  PREFERENCES: "Preferences",
  LOG_OUT: "Log Out",
  ADMIN_USERS: "Users",
  ADMIN_REGIONS: "Regions",
};

export const navbarListItemsIconEnum = {
  DASHBOARD: "HomeIcon",
  NEW_SESSION: "AddIcon",
  SESSIONS_AND_SEGMENTS: "SearchIcon",
  HOW_TO_USE_THE_TOOL: "InfoIcon",
  LINKS_AND_RESOURCES: "OpenInNewIcon",
  NOTES: "ContentPasteIcon",
  PREFERENCES: "SettingsIcon",
  LOG_OUT: "LogoutIcon",
  ADMIN_USERS: "PeopleIcon",
  ADMIN_REGIONS: "LocationOnIcon",
};

export const navbarListItems = [
  {
    text: navbarListItemsTextEnum.DASHBOARD,
    icon: navbarListItemsIconEnum.DASHBOARD,
    isAdmin: false,
    path: "/",
  },
  {
    text: navbarListItemsTextEnum.NEW_SESSION,
    icon: navbarListItemsIconEnum.NEW_SESSION,
    isAdmin: false,
  },

  {
    text: navbarListItemsTextEnum.SESSIONS_AND_SEGMENTS,
    icon: navbarListItemsIconEnum.SESSIONS_AND_SEGMENTS,
    isAdmin: false,
    path: "/previous",
  },
  {
    text: navbarListItemsTextEnum.HOW_TO_USE_THE_TOOL,
    icon: navbarListItemsIconEnum.HOW_TO_USE_THE_TOOL,
    isAdmin: false,
    path: "/userguide",
  },
  {
    text: navbarListItemsTextEnum.LINKS_AND_RESOURCES,
    icon: navbarListItemsIconEnum.LINKS_AND_RESOURCES,
    isAdmin: false,
    path: "/resources",
  },
  {
    text: navbarListItemsTextEnum.NOTES,
    icon: navbarListItemsIconEnum.NOTES,
    isAdmin: false,
    path: "/notes",
  },
  {
    text: navbarListItemsTextEnum.PREFERENCES,
    icon: navbarListItemsIconEnum.PREFERENCES,
    isAdmin: false,
    path: "/preferences",
  },
  {
    text: navbarListItemsTextEnum.LOG_OUT,
    icon: navbarListItemsIconEnum.LOG_OUT,
    isAdmin: false,
    path: "/",
  },
  {
    text: navbarListItemsTextEnum.ADMIN_USERS,
    icon: navbarListItemsIconEnum.ADMIN_USERS,
    isAdmin: true,
    path: "/admin/users",
  },
  {
    text: navbarListItemsTextEnum.ADMIN_REGIONS,
    icon: navbarListItemsIconEnum.ADMIN_REGIONS,
    isAdmin: true,
    path: "/admin/regions",
  },
  {
    text: navbarListItemsTextEnum.LOG_OUT,
    icon: navbarListItemsIconEnum.LOG_OUT,
    isAdmin: true,
    path: "/",
  },
];
