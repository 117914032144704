/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack, TextField, useMediaQuery } from "@mui/material";
import SegmentsTable from "./components/SegmentsTable";
import { segmentTableHeaders } from "./segmentTableData";
import { useAPI } from "../../hooks";

export default function SegmentLookup() {
  const [rowData, setRowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { segments } = useAPI();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const fetchData = async () => {
    try {
      const response = await segments.get();
      setRowData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = rowData.filter((row) => {
    const fieldsToSearch = [
      row.roadName,
      row.uniqueId,
      row.customId,
      row.fromSLK,
      row.toSLK,
    ];
    return JSON.stringify(fieldsToSearch)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  return (
    <Stack spacing={4}>
      <Stack
        direction="row"
        sx={{
          justifyContent: isMobile ? "center" : "space-between",
        }}
      >
        <TextField
          label="Search by Segment details"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Stack>
      <SegmentsTable
        headers={segmentTableHeaders}
        rows={filteredData}
        onDataChange={fetchData}
        isClickable={true}
      />
    </Stack>
  );
}
