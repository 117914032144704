import { Button, Stack, Typography } from "@mui/material";

export default function LoginOffline({ onLogin }) {
  return (
    <Stack spacing={2}>
      <Typography variant="h1">You are offline</Typography>
      <Typography variant="h3">
        You may still use the app, but your data will not be synced until the
        next time you come online.
      </Typography>
      <Stack spacing={1} sx={{ paddingTop: "0.5em" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onLogin({})}
          sx={{ padding: "1em 0" }}
        >
          Enter Site
        </Button>
      </Stack>
    </Stack>
  );
}
