export const userguideData = [
  {
    title: "Pick a starting road segment",
    content:
      "Segments to be defined based on major differences in the characteristics of the road (see note).",
  },
  {
    title: "Note identifying information in Summary Table",
    content:
      "Road name, starting Straight Line Kilometres (SLK), ending Straight Line Kilometres (SLK), and Date Assessed.",
  },
  {
    title:
      "Identify the cross-section that most closely corresponds to your road",
    content:
      "Keep number of lanes, average annual daily traffic (AADT), and road hierarchy in mind (see note).",
  },
  {
    title:
      "Review each of the criteria listed in the table associated with the cross-section type",
    content:
      "Using the Checklist provided, tally the total number of ticks corresponding to the Star Rating in each respective column.",
  },
  {
    title: "Possible Improvements",
    content:
      "Note any possible improvements for that road segment (see table on the Checklist) in the summary table (see note).",
  },
  {
    title: "Repeat with the next road segment",
    content:
      "Continue until all road segments on the Local Government network have been evaluated.",
  },
  {
    title: "Stocktake",
    content:
      "Once all of the road segments on the Local Government network have been assessed, identify the worst performing segments and consider using this information as input for program planning.",
  },
  {
    title: "Evaluate Treatments",
    content:
      "Consider which treatments make the most sense for the identified segments.",
  },
];
