import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCSVReader } from "react-papaparse";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { csvTemplateNames } from "../uploadcsv/csvTemplateNames";
import { UploadModal } from "../../../components";
import { useAPI } from "../../../hooks";

export default function UploadCSV({ sessionId }) {
  const [uploading, setUploading] = useState(false);
  const [zoneHover, setZoneHover] = useState(false);
  const [fileName, setFilename] = useState("");
  const { CSVReader } = useCSVReader();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const { segments } = useAPI();
  const csvConfig = {
    header: true,
    skipEmptyLines: true,
  };

  async function onUploadCSV(csv, filename) {
    setFilename(filename.name);
    setUploading(true);

    let newSegments = csv.data.map((segment) => {
      return Object.keys(segment).reduce((newSegment, header) => {
        let newKey = csvTemplateNames[header];
        newSegment[newKey] = segment[header];
        return newSegment;
      }, {});
    });
    try {
      let updatedSegments = newSegments.map((segment) => {
        return { ...segment, sessionId: sessionId };
      });

      const response = await segments.postBulk(updatedSegments);

      if (response.data?.length > 0) {
        navigate(`/session/${id}/loadedsegments`, {
          state: response.data.map((segment) => segment.id),
        });
      }
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <CSVReader
        config={csvConfig}
        onUploadAccepted={onUploadCSV}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps }) => (
          <Stack
            spacing={1}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              border: `dashed 2px ${
                zoneHover
                  ? theme.palette.text.primary
                  : theme.palette.text.light
              }`,
              borderRadius: "4px",
              padding: "8em 0",
            }}
            {...getRootProps()}
          >
            <Typography>Drop file to upload</Typography>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.secondary }}
            >
              Or
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "fit-content", padding: "0.5em 2em" }}
            >
              Select File
            </Button>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.secondary }}
            >
              Permitted file types: .xls, .csv
            </Typography>
          </Stack>
        )}
      </CSVReader>
      <UploadModal
        open={uploading}
        topText={`Uploading ${fileName}`}
        bottomText={"Please wait..."}
        progressIcon={<CircularProgress />}
      />
    </>
  );
}
