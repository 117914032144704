export const urbanArterialI = {
  "Road Type":
    "Urban arterial, Single Carriageway, two-lane two-way, 40/50/60/70/80 km/h, AADT 4,000– 14,000",
  Name: "Urban Arterial I",
  Context: "Urban",
  Seal: "Sealed",
  Carriageway: "Single",
  Laneage: "Two-lane",
  "Approximate Speed Limit": "80/70/60/50/40 km/h",
  AADT: "4,000-14,000",
  attributes: {
    speedLimit: {
      name: "speedLimit",
      label: "Speed Limit",
      rulesMessage: "Please select a speed limit",
      readOnly: false,
      data: [
        "<1 Star | 80km/h",
        "1 Star | 70km/h",
        "3 Stars | 50-60km/h",
        "5 Stars | 40km/h",
      ],
    },
    curvature: {
      name: "curvature",
      label: "Curvature",
      rulesMessage: "Please select a curvature",
      readOnly: true,
      data: ["Straight or gently curving"],
    },
    skidResistance: {
      name: "skidResistance",
      label: "Skid Resistance",
      rulesMessage: "Please select a skid resistance",
      readOnly: true,
      data: ["Sealed - adequate"],
    },
    laneWidth: {
      name: "laneWidth",
      label: "Lane Width",
      rulesMessage: "Please select a lane width",
      readOnly: false,
      data: ["1 Star | Narrow", "3 Stars | Medium", "5 Stars | Wide"],
    },
    roadsideHazards: {
      name: "roadsideHazards",
      label: "Roadside Hazards",
      rulesMessage: "Please select roadside hazards",
      readOnly: true,
      data: ["1-5m from roadside"],
    },
    roadCondition: {
      name: "roadCondition",
      label: "Road Condition",
      rulesMessage: "Please select a road condition",
      readOnly: false,
      data: ["1 Star | Poor", "3 Star | Good"],
    },
    delineation: {
      name: "delineation",
      label: "Delineation",
      rulesMessage: "Please select a delineation",
      readOnly: true,
      data: ["Adequate"],
    },
    lanes: {
      name: "lanes",
      label: "Number of Lanes",
      rulesMessage: "Please select lanes",
      readOnly: true,
      data: ["2 (per carriageway)"],
    },
    carriageway: {
      name: "carriageway",
      label: "Carriageway",
      rulesMessage: "Please select a carriageway",
      readOnly: true,
      data: ["Divided carriageway road"],
    },
    pavedShoulder: {
      name: "pavedShoulder",
      label: "Paved Shoulder",
      rulesMessage: "Please select a paved shoulder",
      readOnly: false,
      data: ["<1 Star | None", "1 Star | Medium", "3 Stars | Wide"],
    },
    roadsideObjects: {
      name: "roadsideObjects",
      label: "Roadside Objects",
      rulesMessage: "Please select roadside objects",
      readOnly: false,
      data: ["1 Star | Tree/Pole", "5 Stars | Safety barrier - wire"],
    },
    shoulderRumbleStrips: {
      name: "shoulderRumbleStrips",
      label: "Shoulder Rumble Strips",
      rulesMessage: "Please select shoulder rumble strips",
      readOnly: false,
      data: ["1 Star | Not present", "5 Stars | Present"],
    },
    centrelineRumbleStrips: {
      name: "centrelineRumbleStrips",
      label: "Centreline Rumble Strips",
      rulesMessage: "Please select centreline rumble strips",
      readOnly: false,
      data: ["1 Star | Not present", "5 Stars | Present"],
    },
    medianType: {
      name: "medianType",
      label: "Median Type",
      rulesMessage: "Please select a median type",
      readOnly: false,
      data: ["1 Star | Centre Line", "5 Stars | >=1m to <5m"],
    },
    propertyAccessPoints: {
      name: "propertyAccessPoints",
      label: "Property Access Points",
      rulesMessage: "Please select property access points",
      readOnly: true,
      data: ["None"],
    },
    intersectionType: {
      name: "intersectionType",
      label: "Intersection Type",
      rulesMessage: "Please select an intersection type",
      readOnly: false,
      data: [
        "1 Star | 3-leg unsignalised, no protected turn lane",
        "3 Star | 3-leg unsignalised, protected turn lane",
        "5 Star | Roundabout",
      ],
    },
    intersectingVolume: {
      name: "intersectingVolume",
      label: "Intersecting Volume",
      rulesMessage: "Please select intersecting volume",
      readOnly: true,
      data: ["<1,000 vehicles"],
    },
    bicycleFacilities: {
      name: "bicycleFacilities",
      label: "Bicycle Facilities",
      rulesMessage: "Please select bicycle facilities",
      readOnly: false,
      data: [
        "<1 Star | None",
        "1 Star | On-road lane",
        "3 Star | Off-road path",
        "5 Star | Off-road path w/barrier",
      ],
    },
    pedestrianFacilities: {
      name: "pedestrianFacilities",
      label: "Pedestrian Facilities",
      rulesMessage: "Please select pedestrian facilities",
      readOnly: false,
      data: [
        "<1 Star | Informal path 0m to <1m",
        "1 Star | Informal Path (>= 1.0m)",
        "3 Stars | Footpath (1.0m to <3.0m)",
        "5 Stars | Physical Barrier",
      ],
    },
    streetLighting: {
      name: "streetLighting",
      label: "Street Lighting",
      rulesMessage: "Please select street lighting",
      readOnly: false,
      data: ["1 Star | Not Present", "3 Star | Present"],
    },
  },
};
