import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function RoadCard({ data, isRight, onClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const margin = isMobile
    ? "0.5em 0"
    : isRight
    ? "1em 0 1em 1em"
    : "1em 1em 1em 0";
  return (
    <Paper elevation={1} sx={{ margin: margin }}>
      <Stack spacing={1} sx={{ padding: "1em" }}>
        <Typography variant="h6">{data.Name}</Typography>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.secondary }}
        >
          {data.Seal}
        </Typography>
      </Stack>
      <Divider />
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography>Carriageway</Typography>
            <Typography>Laneage</Typography>
            <Typography>Approx. Speed Limit</Typography>
            <Typography>AADT</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography>{data.Carriageway}</Typography>
            <Typography>{data.Laneage}</Typography>
            <Typography>{data["Approximate Speed Limit"]}</Typography>
            <Typography>{data.AADT}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Stack sx={{ padding: "0 1em 1em 1em" }}>
        <Button
          variant="contained"
          sx={{ padding: "1em 0" }}
          onClick={() => onClick(data["Road Type"])}
        >
          Select
        </Button>
      </Stack>
    </Paper>
  );
}
