import { Button, Dialog, Link, Stack, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "../../hooks";

export default function UnknownEmailModal({ open, onClose }) {
  const showSnackbar = useSnackbar();
  const email = "roadwise@walga.asn.au";

  function onCopyEmail() {
    navigator.clipboard.writeText(email);
    showSnackbar("Email copied", "info");
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Stack spacing={2} sx={{ padding: "2em 2em 1em 2em" }}>
        <Typography variant="h6">Unknown Email Address</Typography>
        <Typography>
          Please contact <Link href={`mailto:${email}`}>{email}</Link> to create
          your account.
        </Typography>
        <Stack direction="row">
          <Stack sx={{ flex: 1, alignItems: "start" }}>
            <Button
              variant="text"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onCopyEmail}
              startIcon={<ContentCopyIcon />}
            >
              Copy Email
            </Button>
          </Stack>
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onClose}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
