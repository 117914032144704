import React, { useEffect, useState } from "react";
import { useAPI } from "../../../hooks";
import { Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router-dom";
import { SortableTable } from "../../../components";

const headers = [
  {
    name: "Local Government",
    fieldName: "name",
    sortable: true,
  },
  {
    name: "Road Network Completion (%)",
    fieldName: "roadNetworkCompletedPercent",
    sortable: true,
  },
  {
    name: "Average Rating",
    fieldName: "averageRating",
    sortable: true,
  },
  {
    name: "Roads Analysed",
    fieldName: "roadsAnalysed",
    sortable: true,
  },
  {
    name: "Segments Analysed",
    fieldName: "segmentsAnalysed",
    sortable: true,
  },
  {
    name: "Kms Analysed",
    fieldName: "kmsAnalysed",
    sortable: true,
  },
  {
    name: "",
    fieldName: "button",
    sortable: false,
  },
];

export default function AdminViewRegion() {
  const { region } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { admin } = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const results = await admin.getRegion(region);
      if (results.status === 200) {
        setData(results.data);
      }
      setLoading(false);
    }

    getData();
  }, [region, admin]);

  function getRating(number) {
    if (number < 1) {
      return "<1 Star";
    }

    if (number < 2) {
      return "1 Star";
    }

    if (number < 4) {
      return "3 Star";
    }

    return "5 Star";
  }

  return (
    <>
      <Typography variant="h4">{region}</Typography>
      <SortableTable
        headers={headers}
        loading={loading}
        rows={data.map((d) => {
          return {
            ...d,
            averageRating: getRating(d.averageRating),
            button: (
              <Button
                onClick={() => navigate(`${d.id}`)}
                endIcon={<ArrowForwardIosIcon />}
              >
                See Analysis
              </Button>
            ),
          };
        })}
      />
    </>
  );
}
