/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAPI } from "./useAPI";
import { useRound } from "./useRound";
import { attributeTreatments } from "../pages/treatments/treatmentData";

export const useTreatments = (amount) => {
  const [treatments, setTreatments] = useState([]);
  const { rounds } = useAPI();
  const round = useRound();

  useEffect(() => {
    async function fetchData() {
      const result = await rounds.getTreatmentsForRound(round.id, amount);
      let recommendedTreatments = [];

      if (result.status === 200) {
        const returnedTreatments = Object.keys(result.data);
        recommendedTreatments = attributeTreatments
          .map((treatment) => {
            if (returnedTreatments.includes(treatment.key)) {
              return treatment;
            } else return null;
          })
          .filter((treatment) => treatment);
      }

      setTreatments(recommendedTreatments);
    }

    if (round) {
      fetchData();
    }
  }, [round]);

  return treatments;
};
