import { Box, Dialog, IconButton, Stack, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import CrossSectionImages from "./CrossSectionImages";
import CrossSectionTable from "./CrossSectionTable";
import roadChecklistData from "../forms/common/dropdowndata/roadChecklistData";

export default function CrossSectionModal({ open, onClose, selectedRoadType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const roadData = roadChecklistData.find(
    (roadData) => roadData["Road Type"] === selectedRoadType
  );
  const roadType = roadData.Name.replace(/\s/g, "").replace(
    /^./,
    roadData.Name[0].toLowerCase()
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.common.white,
        },
      }}
    >
      <Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ float: "right", margin: "0.2em 0.2em" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ padding: "0 0.5em" }}
      >
        {!isMobile && <CrossSectionImages roadType={roadType} />}
        <CrossSectionTable
          roadTitle={roadData.Name}
          roadInformation={roadData["Road Type"]}
          rows={roadData.attributes}
        />
        {isMobile && <CrossSectionImages roadType={roadType} />}
      </Stack>
    </Dialog>
  );
}
