import axios from "axios";

export async function getRequest(endpoint, defaultHeader) {
  try {
    return await axios.get(`${process.env.PUBLIC_URL}/api/${endpoint}`, {
      headers: defaultHeader,
    });
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function postRequest(endpoint, data, defaultHeader) {
  try {
    return await axios.post(`${process.env.PUBLIC_URL}/api/${endpoint}`, data, {
      headers: {
        "Content-Type": "application/json",
        ...defaultHeader,
      },
    });
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function putRequest(endpoint, data, defaultHeader) {
  try {
    return await axios.put(`${process.env.PUBLIC_URL}/api/${endpoint}`, data, {
      headers: {
        "Content-Type": "application/json",
        ...defaultHeader,
      },
    });
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function deleteRequest(endpoint, defaultHeader) {
  try {
    return await axios.delete(`${process.env.PUBLIC_URL}/api/${endpoint}`, {
      headers: defaultHeader,
    });
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function patchRequest(endpoint, data, defaultHeader) {
  try {
    return await axios.patch(
      `${process.env.PUBLIC_URL}/api/${endpoint}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          ...defaultHeader,
        },
      }
    );
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}
