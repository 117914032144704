import Dexie from "dexie";

export const localDatabase = new Dexie("walga-lg-stars");

//note: only declare fields you will search on. These are indexes only, and don't represent the model itself.
//to upgrade the database, you need to increase the version number and add it here.
//https://dexie.org/docs/Tutorial/Design#database-versioning
localDatabase.version(2).stores({
  round: "&id, governmentAreaId",
  session: "&id, roundId, userId",
  segment: "&id, sessionId",
  currentUser: "&id",
});
