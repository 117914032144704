import { localDatabase } from "./localDatabase";
import { getRequest, postRequest } from "./requests";

export default function roundData(defaultHeader, user, isOnline) {
  async function get(amountToTake) {
    if (isOnline) {
      const suffix = amountToTake ? `&take=${amountToTake}` : "";
      const results = await getRequest(
        `round?governmentId=${user.governmentAreaId}${suffix}`,
        defaultHeader
      );

      if (results.status === 200) {
        localDatabase.round.bulkPut(results.data);
      }

      return results;
    } else {
      const data = await localDatabase.round
        .where("governmentAreaId")
        .equalsIgnoreCase(user.governmentAreaId)
        .limit(amountToTake ?? 20)
        .toArray();
      return { status: 200, data };
    }
  }

  async function getLatest() {
    if (isOnline) {
      const results = await getRequest(
        `round/latest/${user.governmentAreaId}`,
        defaultHeader
      );

      if (results.status === 200) {
        localDatabase.round.put(results.data);
      }

      return results;
    } else {
      const data = await localDatabase.round.get({
        governmentAreaId: user.governmentAreaId,
      });
      return { status: 200, data };
    }
  }

  async function getSessionsForRound(roundId) {
    if (isOnline) {
      return await getRequest(`round/${roundId}/sessions`, defaultHeader);
    } else {
      const sessions = await localDatabase.session
        .where("roundId")
        .equals(roundId)
        .toArray();

      for (let session of sessions) {
        session.segments = await localDatabase.segment
          .where("sessionId")
          .equals(session.id)
          .toArray();
      }

      return { status: 200, data: sessions };
    }
  }

  async function getTreatmentsForRound(roundId, amount) {
    if (isOnline) {
      return await getRequest(
        `round/${roundId}/treatments/${amount}`,
        defaultHeader
      );
    }
  }

  async function post(data) {
    if (isOnline) {
      return await postRequest(`round`, data, defaultHeader);
    } else {
      //Any round made here is a placeholder only.
      const id = await localDatabase.round.put({
        id: crypto.randomUUID(),
        isLocal: true,
      });
      const localData = await localDatabase.round.get(id);
      return { status: 200, data: localData };
    }
  }

  return { get, getLatest, post, getSessionsForRound, getTreatmentsForRound };
}
