/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import * as projection from "@arcgis/core/geometry/projection";
import Search from "@arcgis/core/widgets/Search";
import Popup from "@arcgis/core/widgets/Popup";
import { useIsOnline } from "../hooks";
import { Box, Stack, Typography } from "@mui/material";
import { computeSLK, createPolyline, emptySLK } from "../helpers";

import "@arcgis/core/assets/esri/themes/light/main.css";

export default function MapComponent({ onSLKChange, roadName }) {
  const mapRef = useRef(null);
  const [slk, setSlk] = useState(emptySLK);
  let isOnline = useIsOnline();

  useEffect(() => {
    const webmap = new WebMap({
      portalItem: {
        id: `${process.env.REACT_APP_MAP_KEY}`,
      },
    });

    const popup = new Popup();

    const view = new MapView({
      container: mapRef.current,
      map: webmap,
      popup: popup,
    });

    const searchWidget = new Search({
      view: view,
    });

    view.ui.add(searchWidget, {
      position: "top-right",
    });

    view.when(() => {
      searchWidget.search(`${roadName} western australia`);
    });

    function displayBadClickPopup(event) {
      view.popup.autoOpenEnabled = false;
      view.popup.location = event.mapPoint;
      view.popup.content =
        "You must click directly on the road network or POI.";
      view.popup.open();
    }

    view.on("click", async (event) => {
      const response = await view.hitTest(event);
      if (response.results.length > 0) {
        const clickedGraphic = response.results[0].graphic;
        if (!clickedGraphic.geometry) {
          displayBadClickPopup(event);
          return;
        }

        const nearestCoordinate = geometryEngine.nearestCoordinate(
          clickedGraphic.geometry,
          event.mapPoint
        );

        if (!nearestCoordinate?.coordinate) {
          displayBadClickPopup(event);
          return;
        }

        if (clickedGraphic?.geometry?.paths) {
          const polylineProjected = createPolyline(
            clickedGraphic,
            nearestCoordinate
          );
          const polylineGeographic = projection.project(polylineProjected, {
            wkid: 4326,
          });
          const length = geometryEngine.geodesicLength(
            polylineGeographic,
            "kilometers"
          );

          setSlk((prevSlk) => {
            const newSLK = computeSLK(length, prevSlk);
            return newSLK;
          });
        }
      }
    });

    return () => {
      if (view) {
        view.container = null;
      }
    };
  }, []);

  //this is to prevent rendering errors between the upper components and the map
  useEffect(() => {
    onSLKChange(slk);
  }, [onSLKChange, slk]);

  return isOnline ? (
    <Box ref={mapRef} style={{ height: "100%", width: "100%" }}></Box>
  ) : (
    <Stack
      sx={{
        height: "80%",
        width: "100%",
        alignItems: "center",
        border: "1px solid black",
        justifyContent: "center",
      }}
    >
      <Typography>
        {`Map cannot be displayed ${
          !isOnline ? "in offline mode" : "on a mobile device."
        }`}
      </Typography>
    </Stack>
  );
}
