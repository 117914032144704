import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useAPI } from "../../hooks";

export default function DataUsageCheckModal({ children }) {
  const { currentUser, users } = useAPI();
  const [open, setOpen] = useState(currentUser.allowDataAggregation === null);

  async function onClick(allow) {
    await users.updatePreferences({
      allowDataAggregation: allow,
    });
    setOpen(false);
  }
  return (
    <>
      {children}
      <Dialog open={open} maxWidth="xs">
        <Stack spacing={2} sx={{ padding: "2em 2em 1em 2em" }}>
          <Typography variant="h6">Data Usage</Typography>
          <Typography>
            Do you consent to allowing WALGA to use your inputted data at an
            aggregate level to inform policy directions and support WALGA
            advocacy efforts?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack sx={{ flex: 1, alignItems: "start" }}>
              <Button
                variant="text"
                sx={{ width: "100%", padding: "0.5em 2em" }}
                onClick={() => onClick(false)}
              >
                Not Now
              </Button>
            </Stack>
            <Stack sx={{ flex: 1, alignItems: "end" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "100%", padding: "0.5em 2em" }}
                onClick={() => onClick(true)}
              >
                Allow
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
