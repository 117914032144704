import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formResultsToolName } from "../common/formNames";

export default function Results({ result, setCreateSessionOpen }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h5">Recommendation</Typography>
      <Stack spacing={4}>
        <Typography>
          Based on your responses, {result.name} might be a suitable tool to
          rate the safety or your road network.{" "}
          {result.name !== formResultsToolName ? (
            <>
              More information can be found on {result.name} at this link:{" "}
              <Link href={result.url} target="blank">
                {result.name}
              </Link>
            </>
          ) : null}
        </Typography>
      </Stack>

      <Divider />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{ flex: 1, justifyContent: "end" }}
      >
        {result.name === formResultsToolName ? (
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto", padding: "0.5em 2em" }}
            onClick={() => setCreateSessionOpen(true)}
          >
            Continue with the road safety tool
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={() => setCreateSessionOpen(true)}
            >
              Continue with the road safety tool
            </Button>
            <Button
              href={result.url}
              target="blank"
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
            >
              Open {result.name}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
}
