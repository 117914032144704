export const segmentTableHeaders = [
  "Road/Street Name",
  "Unique ID",
  "Custom ID",
  "From (SLK)",
  "To (SLK)",
];

export const detailedSegmentTableHeaders = [
  "Road/Street Name",
  "Unique ID",
  "Custom ID",
  "From (SLK)",
  "To (SLK)",
  "Rating",
  "<1 Star",
  "1 Star",
  "3 Star",
  "5 Star",
];
