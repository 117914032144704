import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TopSection } from "../../components";
import { useNavigate } from "react-router-dom";
import { userguideData } from "./userguideData";

export default function UserGuide() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <TopSection>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Box>
            <Typography variant="h5">
              How to use the Road Safety Ratings tool
            </Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              The following instructions present a step-by-step process for
              completing an assessment of the Local Government road network
              using this tool.
            </Typography>
          </Box>
          <Stack sx={{ alignItems: "center" }}>
            <Button
              variant="outlined"
              sx={{ width: "auto" }}
              onClick={() => {
                navigate("/notes");
              }}
            >
              View Notes
            </Button>
          </Stack>
        </Stack>
      </TopSection>
      <List sx={{ paddingTop: 0 }}>
        {userguideData.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={
                <>
                  <Typography variant="h6">
                    {index + 1}. {item.title}
                  </Typography>
                  <Typography>{item.content}</Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
