import { Button, Dialog, Stack, useMediaQuery } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import BigNumber from "bignumber.js";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  SubTitle,
  DoughnutController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "react-chartjs-2";
import { keyToHeaderMap } from "../exportData";
import { useRef } from "react";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  Title,
  SubTitle,
  DoughnutController
);

export default function InfographicModal({
  open,
  onSubmit,
  onClose,
  title,
  data,
}) {
  const theme = useTheme();
  const chartRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let lessThanOne = new BigNumber(0);
  let one = new BigNumber(0);
  let three = new BigNumber(0);
  let five = new BigNumber(0);

  for (const session of data) {
    const starRating =
      session[keyToHeaderMap.SegmentAssessment_finalStarRating];
    switch (starRating) {
      case 0:
        lessThanOne = lessThanOne.plus(
          new BigNumber(session["To SLK"]).minus(session["From SLK"]).abs()
        );
        break;
      case 1:
        one = one.plus(
          new BigNumber(session["To SLK"]).minus(session["From SLK"]).abs()
        );
        break;
      case 3:
        three = three.plus(
          new BigNumber(session["To SLK"]).minus(session["From SLK"]).abs()
        );
        break;
      case 5:
        five = five.plus(
          new BigNumber(session["To SLK"]).minus(session["From SLK"]).abs()
        );
        break;
      default:
        break;
    }
  }

  const chartData = [
    lessThanOne.toNumber(),
    one.toNumber(),
    three.toNumber(),
    five.toNumber(),
  ];

  const fillBackground = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = theme.palette.background.paper;
      ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
      ctx.restore();
    },
  };

  const middleText = {
    id: "middleText",
    beforeDraw(chart) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      ctx.restore();
      if (isMobile) {
        var fontSize = 0.7;
        ctx.font = fontSize + "em sans-serif";
      }
      var text = "Rating with Most Kilometres",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / (isMobile ? 1.15 : 1.4);
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const needle = {
    id: "needle",
    afterDatasetDraw(chart) {
      const {
        ctx,
        chartArea: { width },
      } = chart;
      ctx.save();

      const dataTotal = 4;
      const dataMaxValue = Math.max(...chartData);
      const needleValue = lessThanOne.isEqualTo(dataMaxValue)
        ? 0.5
        : one.isEqualTo(dataMaxValue)
        ? 1.5
        : three.isEqualTo(dataMaxValue)
        ? 2.5
        : 3.5;

      const angle = new BigNumber(Math.PI)
        .plus(
          new BigNumber(1)
            .dividedBy(dataTotal)
            .times(needleValue)
            .times(Math.PI)
        )
        .toNumber();

      const cx = width / 2;
      const cy = chart._metasets[0].data[0].y;

      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -2);
      ctx.lineTo(80, 0);
      ctx.lineTo(0, 2);
      ctx.fillStyle = "#444";
      ctx.fill();

      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, 10);
      ctx.fill();
      ctx.restore();
    },
  };

  const alphaLevel = 0.6;
  const backgroundColor = [
    alpha("#C00000", alphaLevel),
    alpha("#FF6600", alphaLevel),
    alpha("#FFCC00", alphaLevel),
    alpha("#33CC33", alphaLevel),
  ];

  const doughnutData = {
    labels: ["< 1 Star", "1 Star", "3 Stars", "5 Stars"],
    type: "doughnut",
    datasets: [
      {
        label: "# of Kms",
        data: [
          lessThanOne.toNumber(),
          one.toNumber(),
          three.toNumber(),
          five.toNumber(),
        ],
        backgroundColor: backgroundColor,
        borderColor: "#000",
        borderWidth: 1,
        cutout: 60,
      },
      {
        data: [1, 1, 1, 1],
        backgroundColor: backgroundColor,
        borderColor: "#000",
        borderWidth: 1,
        circumference: 180,
        rotation: 270,
        cutout: 40,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: theme.palette.text.primary,
        font: {
          size: theme.typography.fontSize,
          weight: "bold",
        },
        anchor: "middle",
        offset: -20,
        align: "start",
      },
      legend: {
        position: isMobile ? "bottom" : "right",
        labels: {
          font: {
            size: theme.typography.fontSize,
          },
        },
      },
      title: {
        display: true,
        text: title,
        color: theme.palette.text.primary,
        font: {
          size: "32px",
        },
      },
      subtitle: {
        display: true,
        text: "All Units in Kilometres",
        color: theme.palette.text.primary,
        font: {
          size: "14px",
        },
      },
    },
    responsive: true,
  };

  function onDownload() {
    const base64Image = chartRef.current.toBase64Image("image/jpeg", 1);
    const a = document.createElement("a");
    a.href = base64Image;
    a.download = `${title}-infographic.jpg`;
    a.click();
    onSubmit();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack
        spacing={2}
        sx={{ padding: "2em 2em 1em 2em", alignItems: "center", width: "100%" }}
      >
        <Chart
          type="doughnut"
          ref={chartRef}
          data={doughnutData}
          options={options}
          plugins={[needle, fillBackground, middleText]}
        />
        <Stack direction="row" sx={{ flex: 1, width: "100%" }}>
          <Stack sx={{ flex: 1, alignItems: "start" }}>
            <Button
              variant="text"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onClose}
            >
              Close
            </Button>
          </Stack>
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onDownload}
            >
              Download
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
