import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

const SnackbarContext = createContext("");

export const useSnackbar = () => useContext(SnackbarContext);

export function SnackbarProvider({ children }) {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  function showSnackbar(message, severity) {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        message={message}
        onClose={onClose}
      >
        <Alert severity={severity} onClose={onClose} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
