import { localDatabase } from "./localDatabase";
import { postRequest } from "./requests";

export default function userData(defaultHeader, user, setUser, isOnline) {
  async function updatePreferences(data) {
    if (isOnline) {
      const results = await postRequest(
        `user/${user.id}/preferences`,
        data,
        defaultHeader
      );

      if (results.status === 200) {
        const clone = structuredClone(user);
        clone.allowDataAggregation = data.allowDataAggregation;
        setUser(clone);
        localDatabase.currentUser.put(clone);
      }

      return results;
    } else {
      const localUser = {
        ...user,
        allowDataAggregation: data.allowDataAggregation,
      };

      console.log(localUser);
      localDatabase.currentUser.put(localUser);

      return { status: 200, data: localUser };
    }
  }

  async function setViewFirstTimeModal() {
    if (isOnline) {
      const results = await postRequest(
        `user/${user.id}/viewfirsttimemodal`,
        {},
        defaultHeader
      );

      if (results.status === 200) {
        const clone = structuredClone(user);
        clone.viewedFirstTimeModal = true;
        setUser(clone);
        localDatabase.currentUser.put(clone);
      }

      return results;
    } else {
      const clone = structuredClone(user);
      clone.viewedFirstTimeModal = true;
      setUser(clone);
      localDatabase.currentUser.put(clone);
      return { status: 200 };
    }
  }

  return { updatePreferences, setViewFirstTimeModal };
}
