/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { DownloadChoiceModal, TopSection, UploadModal } from "../../components";
import { useAPI, useRound } from "../../hooks";
import OverviewSection from "./components/OverviewSection";
import SummaryToggle from "./components/SummaryToggle";
import { Prefix, keyToHeaderMap } from "./exportData";
import Treatment from "../treatments/Treatment";
import InfographicModal from "./components/InfographicModal";

export default function RoundSummary({ onFinaliseRound }) {
  const { rounds } = useAPI();
  const [sessions, setSessions] = useState([]);
  const [segments, setSegments] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [decisionDialog, setDecisionDialog] = useState(false);
  const [infographicDialog, setInfographicDialog] = useState(false);
  const round = useRound();
  const theme = useTheme();
  const formattedDate = dayjs(round.createdOn).format("DD/MM/YYYY");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSegments = async () => {
      const result = await rounds.getSessionsForRound(round.id);
      if (result.status === 200) {
        const segments = result?.data?.flatMap((session) => session.segments);
        setSegments(segments);
        setSessions(result.data);
      }
    };
    fetchSegments();
  }, []);

  useEffect(() => {
    if (showSuccessModal) {
      const timeout = setTimeout(() => {
        setShowSuccessModal(false);
        onFinaliseRound();
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [showSuccessModal]);

  function handleDecision(selection) {
    if (selection) {
      setShowSuccessModal(true);
    }
    setDecisionDialog(false);
  }

  function onInfographicSubmit() {
    setInfographicDialog(false);
  }

  function prepareData() {
    const flattenProperties = (prefix, source) =>
      Object.entries(keyToHeaderMap).reduce((acc, [prefixedKey, header]) => {
        const [currentPrefix, key] = prefixedKey.split("_");
        if (currentPrefix === prefix && key in source) {
          acc[header] = source[key];
        }
        return acc;
      }, {});

    return sessions?.flatMap((session) => {
      const sessionData = flattenProperties(Prefix.Session, session);

      return session.segments.map((segment) => {
        const segmentData = flattenProperties(Prefix.Segment, segment);

        const segmentAssessmentData = segment.segmentAssessment
          ? flattenProperties(
              Prefix.SegmentAssessment,
              segment.segmentAssessment
            )
          : {};

        return { ...sessionData, ...segmentData, ...segmentAssessmentData };
      });
    });
  }

  return (
    <>
      <TopSection>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Box>
            <Typography variant="h5">Progress to Date</Typography>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              This round of analysis includes all completed sessions from{" "}
              {formattedDate} until today.
            </Typography>
          </Box>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            {!isMobile && (
              <Button
                variant="contained"
                sx={{
                  flex: 1,
                  whiteSpace: "nowrap",
                }}
                onClick={() => setInfographicDialog(true)}
              >
                Infographic
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              sx={{
                flex: 1,
                whiteSpace: "nowrap",
              }}
              onClick={() => setDecisionDialog(true)}
            >
              Finalise Analysis
            </Button>
          </Stack>
        </Stack>
      </TopSection>
      <Typography variant="h5">Overview</Typography>
      <OverviewSection
        roundSegments={segments}
        roadsInNetwork={round.roadInNetworkCount}
        kmsInNetwork={round.kmsInNetworkTotal}
      />
      <Stack spacing={2} sx={{ flex: "1 0 auto" }}>
        <SummaryToggle sessions={sessions} segments={segments} />
      </Stack>
      <Treatment />
      <UploadModal
        open={showSuccessModal}
        topText="Outputs Downloaded!"
        bottomText="Thank you for keeping WA's roads safe."
        icon={<CheckCircleIcon style={{ color: "green", fontSize: "50px" }} />}
      />
      <DownloadChoiceModal
        open={decisionDialog}
        text="Would you like to download the outputs (CSV) and finalise this round of analysis?"
        yesText="Download"
        noText="Cancel"
        onYes={() => handleDecision(true)}
        onNo={() => handleDecision(false)}
        onClose={() => handleDecision(false)}
        downloadData={prepareData}
        fileName={`Export_${new Date().toISOString()}`}
      />
      <InfographicModal
        open={infographicDialog}
        onSubmit={onInfographicSubmit}
        onClose={() => setInfographicDialog(false)}
        title={round.governmentArea.name}
        data={prepareData()}
      />
    </>
  );
}
