import { useTheme } from "@mui/material/styles";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { OverviewCard } from "../../components";
import { useTreatments } from "../../hooks";

export default function TreatmentCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const treatments = useTreatments(3);

  return (
    <OverviewCard title={"KEY TREATMENTS/EFFECTIVENSS"}>
      {treatments.length === 0 ? (
        <Typography
          sx={{
            ...theme.components.TreatmentCard,
            fontSize: isMobile
              ? theme.components.TreatmentCard.fontSize.small
              : theme.components.TreatmentCard.fontSize.large,
            color: theme.palette.text.primary,
          }}
          variant="h1"
        >
          No Recommended Treatments
        </Typography>
      ) : (
        treatments.map((treatment) => (
          <Stack
            key={treatment.label}
            direction="row"
            sx={{
              justifyContent: "flex-start",
              marginLeft: isMobile ? "0" : "5em",
            }}
          >
            <Typography
              sx={{
                ...theme.components.TreatmentCard,
                fontSize: isMobile
                  ? theme.components.TreatmentCard.fontSize.small
                  : theme.components.TreatmentCard.fontSize.large,
                paddingRight: "0",
                marginRight: "0",
              }}
              variant="h1"
            >
              {treatment.label} /
            </Typography>
            <Typography
              sx={{
                ...theme.components.TreatmentCard,
                fontSize: isMobile
                  ? theme.components.TreatmentCard.fontSize.small
                  : theme.components.TreatmentCard.fontSize.large,
                color:
                  treatment.effectiveness === "High"
                    ? theme.components.Effectiveness.high
                    : treatment.effectiveness === "Medium"
                    ? theme.components.Effectiveness.medium
                    : theme.components.Effectiveness.low,
                paddingLeft: "2px",
                marginLeft: "2px",
              }}
              variant="h1"
            >
              {treatment.effectiveness}
            </Typography>
          </Stack>
        ))
      )}
    </OverviewCard>
  );
}
