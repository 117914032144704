/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import { Divider, Link, List, Stack, Typography } from "@mui/material";

import { ListItemWithBulletIcon } from "../../components";
import { useLocation } from "react-router-dom";

export default function Notes() {
  const { hash } = useLocation();
  const crossSectionRef = useRef(null);

  useEffect(() => {
    if (hash && hash === "#crosssection" && crossSectionRef) {
      crossSectionRef.current.scrollIntoView();
    }
  }, []);

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Typography variant="h1">How To Use The Tool</Typography>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Note on Video Data</Typography>
          <Typography>
            If available, video data on the road network can be helpful in
            defining segments. However, this tool has been designed to be used
            without access to video data. Standard tools such as Google/Bing
            Maps and the various MRWA GIS platforms are often sufficient to
            perform the assessment.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Note on Segmentation</Typography>
          <Typography>
            This tool operates based on road segments. There is no limit to the
            length of road segments if conditions on that segment remain
            constant. Some criteria to start a new segment are listed below:
          </Typography>
          <List>
            <ListItemWithBulletIcon text="Change in number of lanes" />
            <ListItemWithBulletIcon text="Change in shoulder treatments" />
            <ListItemWithBulletIcon text="Change in posted speed limit" />
            <ListItemWithBulletIcon text="At major Intersections" />
            <ListItemWithBulletIcon text="Change in adjoining land use" />
            <ListItemWithBulletIcon text="Steep differences in traffic volumes" />
          </List>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">
            Note on Straight Line Kilometre (SLK)
          </Typography>
          <Typography>
            SLK data can be determined using information on the{" "}
            <Link
              target="blank"
              href="https://mrwebapps.mainroads.wa.gov.au/gpsslk"
            >
              GPS-SLK Map
            </Link>
            , provided by Main Roads WA.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Note on Road Hierarchy</Typography>
          <Typography>
            The cross-sections use road characterisations taken from the
            Austroads Guides,{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r618-20"
            >
              AP-R618-20
            </Link>
            ,{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r619-20"
            >
              AP-R619-20
            </Link>
            , and the{" "}
            <Link target="blank" href="https://www.mainroads.wa.gov.au">
              Road Information Mapping System.
            </Link>{" "}
            For the purposes of this tool, the MRWA road hierarchies can be
            roughly equated as follows:
          </Typography>
          <List>
            <ListItemWithBulletIcon text="Primary Distributor - Rural Highway/Urban Arterial" />
            <ListItemWithBulletIcon text="Regional Distributor/Distributor A and B - Rural Highway/Urban Arterial" />
            <ListItemWithBulletIcon text="Local Distributor - Collector Street" />
            <ListItemWithBulletIcon text="Access Road - Rural/Urban Local Access Road" />
          </List>
          <Typography>
            These differ from the road hierarchies as used by Main Roads WA. To
            reference a road`s hierarchy,
            <Link
              target="blank"
              href="https://mainroads.maps.arcgis.com/apps/instant/sidebar/index.html?appid=c56031f1e36a47e8ae2e52f6ec4c1833"
            >
              please see this map.
            </Link>
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">
            Note on Road Attribute Definitions
          </Typography>
          <Typography>
            For definitions of specific road attributes, please see the Road
            Attribute Definitions section of this Assessment Guide.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">
            Note on Star Safety Rating Trends
          </Typography>
          <Typography>
            If the bulk of the criteria identified in the assessment are
            trending toward a higher or lower Star Safety Rating but cannot be
            classified wholly as a higher or lower Star Safety Rating, please
            place a tick in the “+” or “-“ box to indicate that this segment is
            likely to be trending upwards or downwards.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Note on Treatment Selection</Typography>
          <Typography>
            The final step in this process is to consider which treatments are
            likely to improve the safety of the road network. Various
            considerations come into play in determining which treatment is most
            appropriate and some highly effective treatments may also carry a
            large price tag. Any treatment implemented on a road segment,
            however, is likely to improve the Star Safety Rating. More
            information on the effectiveness of treatments can be found by using
            the IRAP Star Safety Rating Demonstrator, part of the online ViDA
            Software Package{" "}
            <Link
              target="blank"
              href="https://www.irap.org/project/star-rating-demonstrator"
            >
              Star Rating Demonstrator - iRAP
            </Link>
            . Registration to access and use the Star Safety Rating Demonstrator
            is free.
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1} ref={crossSectionRef}>
          <Typography variant="h1">Cross Sections</Typography>
          <Typography>
            The cross-sections used in this app represent all road types found
            on Local Government managed road networks in Western Australia.
          </Typography>
          <Typography variant="h5">Intent</Typography>
          <Typography>
            The cross-sections presented in this tool were developed as
            representative visualizations of conditions on the WA Local
            Government road network. In preparing an assessment of the Local
            Government road network, these cross-sections are unlikely to
            correspond exactly with the conditions on each road. However, the
            intent is that the road cross-section will provide a visual cue to
            identifying which Star Safety Rating can likely be assigned to each
            road.
          </Typography>
          <Typography>
            Users of the tool should use the checklist to mark off the specific
            characteristics of each road, keeping in mind that the pertinent
            Star Safety Rating is represented by the column with the most check
            marks. When elements remain the same across the Star Ratings, select
            the lowest possible rating to ensure that the determination of a
            star rating is not overinflated. This may result in ratings that are
            skewed lower than the actual Star Rating. To undertake a more
            detailed assessment of the Star Rating for a particular segment,
            please see the link to the{" "}
            <Link
              target="blank"
              href="https://www.irap.org/project/star-rating-demonstrator"
            >
              Star Rating Demonstrator - iRAP
            </Link>
            .
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">
            How to Choose the Appropriate Cross-Section
          </Typography>
          <Typography>
            Each cross-section has various characteristics (listed below), which
            were used to build and validate the different Star Ratings.
          </Typography>
          <List>
            <ListItemWithBulletIcon text="Context - Rural or Urban" />
            <ListItemWithBulletIcon text="Seal - Sealed or Unsealed" />
            <ListItemWithBulletIcon text="Carriageway - Divided or Single" />
            <ListItemWithBulletIcon text="Hierarchy - Highway/Arterial/Collector Road, etc" />
            <ListItemWithBulletIcon text="ADT/Traffic Volumes" />
          </List>
          <Typography>
            In the above order, select the context, seal status, carriageway,
            and traffic volumes to determine which cross-section to reference.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Validation</Typography>
          <Typography>
            However, specific treatments will have larger or smaller effects on
            the overall Star Safety Rating of a specific road. The section on
            Potential Treatments (included in the Checklist) provides a rough
            guide to which actions may result in greater safety benefits. If
            more information is required, the IRAP Vida Star Rating Demonstrator
            Tool in the online ViDA Software is a great resource and allows
            users to input the exact road conditions and explore the effects of
            different road safety treatments. This tool is free can also be used
            to validate each cross-section.{" "}
            <Link target="blank" href="https://www.vida.irap.org/en-gb/home">
              Login - ViDA
            </Link>
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Cross-Section Development</Typography>
          <Typography>
            The starting point for the development of the cross-sections were
            the categories defined in two AustRoads research reports (AP-R619-20
            | Austroads and AP-R618-20 | Austroads). The Western Australian
            Local Government road network, however, does not include some of the
            cross-sections identified in the report (e.g. rural and urban
            freeways), so these were not represented. Conversely, some road
            cross-sections prevalent in WA, particularly cross-sections of
            unsealed roads, were not included in the AustRoads guides; these
            cross-sections were identified, the attributes validated, and then
            developed as cross-sections for inclusion in this tool. Each
            cross-section was validated using the Star Rating Demonstrator tool
            to ensure that the combination of treatments resulted in the
            indicated Star Safety Rating.
          </Typography>
          <Typography>
            Please see the{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r618-20"
            >
              AP-R618-20
            </Link>
            ,{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r619-20"
            >
              AP-R619-20
            </Link>
            , and the{" "}
            <Link target="blank" href="https://www.mainroads.wa.gov.au">
              Road Information Mapping System
            </Link>{" "}
            for more information.
          </Typography>
          <Typography variant="h5">All Modes</Typography>
          <Typography>
            The Star Rating Demonstrator provides Star Ratings for vehicles,
            motorcycles, pedestrians, and cyclists. To ensure that this tool
            remained easy and relatively expedient to use, the tool provides
            cross-sections that reflect a safe facility for all users. However,
            some users may not be accommodated on specific road types (e.g.,
            pedestrians on rural roads).
          </Typography>
          <Typography variant="h5">2-Star and 4-Star Safety Ratings</Typography>
          <Typography>
            In order to ensure that minimal overlap is present between
            represented Star Safety Ratings, 2-star and 4-star roads are not
            represented in the cross-section form as part of this tool.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Sealed vs. Unsealed Roads</Typography>
          <Typography>
            The AustRoads guides AP-R618-20 and AP-R619-20 assume that unsealed
            roads have a lower Star Safety Rating, due to unsealed roads having
            inadequate delineation, i.e. no pavement markings. In Western
            Australia, 68.8% of roads are unsealed and many of these roads are
            never likely to be sealed. For this reason, we have represented
            1-Star. 3-Star, and 5-Star cross-sections for unsealed roads that do
            not recommend adding a seal as a safety treatment.
          </Typography>
          <Typography>
            Please see the{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r618-20"
            >
              AP-R618-20
            </Link>
            ,{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r619-20"
            >
              AP-R619-20
            </Link>
            , and the{" "}
            <Link target="blank" href="https://www.mainroads.wa.gov.au">
              Road Information Mapping System
            </Link>{" "}
            for more information.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Represented Cross-Sections</Typography>
          <Typography>
            The following cross-sections represent all road types found on Local
            Government managed road networks in Western Australia.
          </Typography>
          <Typography variant="h5">Sealed Roads</Typography>
          <List>
            <ListItemWithBulletIcon text="A) Rural highway, divided carriageway, multilane, 90/100/110 km/h, AADT 15 000 or greater" />
            <ListItemWithBulletIcon text="B) Rural highway, divided carriageway, multilane, 90/100/110 km/h, AADT 0-15 000" />
            <ListItemWithBulletIcon text="C) Rural road, single carriageway, two-lane two-way, 80/90/100/110 km/h, AADT 2 000 or greater" />
            <ListItemWithBulletIcon text="D) Rural road, single carriageway, two-lane two-way, 70/80/90/100/110 km/h, AADT 250-2 000" />
            <ListItemWithBulletIcon text="E) Rural road, single carriageway, two-lane two-way, 70/80/90/100/110 km/h, AADT < 250" />
            <ListItemWithBulletIcon text="F) Rural local collector road, single carriageway, two-lane two-way, 70/80/90/100/110 km/h, AADT > 100" />
            <ListItemWithBulletIcon text="G) Rural local access road, single carriageway, two-lane, two-way, 50/60/70/80/90/100/110 km/h, AADT < 100" />
            <ListItemWithBulletIcon text="H) Urban arterial, divided carriageway, multilane, 60/70/80/90/100 km/h, AADT 14 000 or greater" />
            <ListItemWithBulletIcon text="I) Urban arterial, single carriageway, two-lane two-way, 40/50/60/70/80 km/h, AADT 4 000-14 000" />
            <ListItemWithBulletIcon text="J) Urban local collector road, single carriageway, two-lane two-way, 30/40/50/60/70/80 km/h, AADT 500-8 000" />
            <ListItemWithBulletIcon text="K) Urban local access road, single carriageway, two-lane two-way, 30/40/50/60/70 km/h, AADT 0-500" />
          </List>

          <Typography variant="h5">Unsealed Roads</Typography>
          <List>
            <ListItemWithBulletIcon text="L) Rural highway, single carriageway, two-lane two-way, 50/60/70/80/90/100/110 km/h, AADT 2 000 or greater" />
            <ListItemWithBulletIcon text="M) Rural road, single carriageway, two-lane two-way, 50/60/70/80/90/100/110 km/h, AADT 500-2 000" />
            <ListItemWithBulletIcon text="N) Rural road, single carriageway, two-lane two-way, 50/60/70/80/90/100/110 km/h, AADT < 500" />
            <ListItemWithBulletIcon text="O) Rural local collector road, single carriageway, two-lane two-way, 80/90/100/110 km/h, AADT > 250" />
            <ListItemWithBulletIcon text="P) Rural local access road, single carriageway, two-lane, two-way, 40/50/60/70/80/90/100/110 km/h, AADT < 250" />
            <ListItemWithBulletIcon text="Q) Urban local access road, single carriageway, two-lane two-way, 30/40/50/60/70/80/90/100/110 km/h, AADT 0-1 000" />
          </List>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Note on Intersections</Typography>
          <Typography>
            Intersections are not represented in these cross-sections and this
            tool does not provide guidance on recommended treatments at
            intersections.
          </Typography>
          <Typography>
            The intent of this tool is to support the assessment of the road
            network as a whole and to prioritise investment in those areas most
            in need of improvement. Though not always the case, intersection
            treatments are often not implemented or assessed on a network level.
            For this reason, as well as the difficulty of representing all
            intersection types in cross-section format, intersections have been
            omitted from this tool.
          </Typography>
          <Typography>
            Some tools to assess intersection safety include the MRWA Crash Map
            tool and AustRoads Publication:{" "}
            <Link
              target="blank"
              href="https://www.austroads.com.au/publications/road-design/ap-r556-17"
            >
              AP-R556-17
            </Link>
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h1">Preservation of Data</Typography>
          <Typography>
            After applying this tool to assess the safety of the road network,
            Local Governments can use the data for various purposes.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Baseline</Typography>
          <Typography>
            One of the key benefits of this tool is to generate a baseline
            understanding of the safety of the network. This baseline
            information should be preserved either in hard copy or electronic
            form. To this end, an excel spreadsheet has been prepared and can be
            found here:{" "}
            <Link target="blank" href="https://www.roadwise.asn.au/lgstars">
              LGStars Spreadsheet.
            </Link>{" "}
            It is recommended that Local Governments reassess their road
            networks on a 5-year basis.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Benchmarking</Typography>
          <Typography>
            Another key benefit of this tool is to provide the opportunity to
            benchmark progress toward the realisation of a safe road network. To
            understand what improvements have been made, it is important to
            retain all documentation of the initial assessment of the Local
            Government road network, whether in paper or electronic format. Once
            the second-round assessment has been completed, the Local Government
            can review the baseline data and second round assessment to
            determine what changes to the Star Safety Rating have occurred and
            showcase any improvements that have been completed in the
            intervening time.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="h5">Works Programming</Typography>
          <Typography>
            Preserving the data in a meaningful way is also important for
            integrating the results into works programs or other investment
            processes. By ensuring the data is collected and stored an easily
            accessible way will support the delivery of projects or mass actions
            that will treat areas of the network most in need of safety
            improvement.
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack spacing={3}>
        <Typography variant="h1">Data Uniformity</Typography>
        <Typography>
          Particularly in regional and remote Local Governments, many of the
          roads may be very similar and may have similar attributes (and thus
          similar Star Safety Ratings). This may make programming improvements
          difficult. One good starting point for developing a program to improve
          the Star Safety Rating of a road segment, and thereby improve safety
          outcomes, is to focus on areas where significant crashes are likely to
          occur. Improving safety at curves is a good starting point, while
          programming simple, achievable treatments is also recommended. If
          possible, programming and implementing a treatment across the entire
          network is preferable to investing heavily in single road segments at
          the expense of more network-wide solutions.
        </Typography>
      </Stack>
    </Stack>
  );
}
