import dayjs from "dayjs";
import { localDatabase } from "./localDatabase";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "./requests";

export default function segmentData(defaultHeader, isOnline) {
  async function get(amountToTake) {
    if (isOnline) {
      const suffix = amountToTake ? `?take=${amountToTake}` : "";
      return await getRequest(`segment${suffix}`, defaultHeader);
    } else {
      const data = await localDatabase.segment
        .limit(amountToTake ?? 200)
        .toArray();
      return { status: 200, data };
    }
  }

  async function getSingle(id) {
    if (isOnline) {
      return await getRequest(`segment/${id}`, defaultHeader);
    } else {
      const data = await localDatabase.segment.get(id);
      return { status: 200, data };
    }
  }

  async function getWithIds(ids) {
    if (isOnline) {
      return await postRequest(`segment/ByIds`, ids, defaultHeader);
    } else {
      const data = await localDatabase.segment
        .where("id")
        .anyOfIgnoreCase(ids)
        .toArray();
      return { status: 200, data };
    }
  }

  async function getSegmentsForSession(sessionId) {
    if (isOnline) {
      return await getRequest(
        `segment/GetSegmentsForSession/${sessionId}`,
        defaultHeader
      );
    } else {
      const data = await localDatabase.segment
        .where("sessionId")
        .equals(sessionId)
        .toArray();
      return { status: 200, data };
    }
  }

  async function post(data) {
    if (isOnline) {
      return await postRequest(`segment`, data, defaultHeader);
    } else {
      const id = await localDatabase.segment.put({
        ...data,
        id: crypto.randomUUID(),
        updatedDate: dayjs().toDate(),
      });
      const session = await localDatabase.session.get(data.sessionId);
      const segmentsCount = session.segmentsCount ?? 0;
      session.segmentsCount = segmentsCount + 1;

      await localDatabase.session.put(session);

      const localData = await localDatabase.segment.get(id);
      return { status: 200, data: localData };
    }
  }

  async function postBulk(data) {
    if (isOnline) {
      return await postRequest(`segment/bulk`, data, defaultHeader);
    } else {
      const ids = await localDatabase.segment.bulkPut(
        data.map((d) => {
          return {
            ...d,
            id: crypto.randomUUID(),
            updatedDate: dayjs().toDate(),
          };
        })
      );

      const session = await localDatabase.session.get(data[0].sessionId);
      const segmentsCount = session.segmentsCount ?? 0;
      session.segmentsCount = segmentsCount + data.length;

      await localDatabase.session.put(session);

      const localData = await localDatabase.segment
        .where("id")
        .anyOfIgnoreCase(ids)
        .toArray();
      return { status: 200, data: localData };
    }
  }

  async function update(data) {
    if (isOnline) {
      return await patchRequest(`segment`, data, defaultHeader);
    } else {
      const id = await localDatabase.segment.put({
        ...data,
        dateAssessed: dayjs(data.dateAssessed).toDate(),
      });
      const localData = await localDatabase.segment.get(id);
      return { status: 200, data: localData };
    }
  }

  async function softDelete(id) {
    if (isOnline) {
      return await deleteRequest(`segment/${id}`, defaultHeader);
    } else {
      await localDatabase.segment.delete(id);
      return { status: 200 };
    }
  }

  return {
    get,
    getSingle,
    getWithIds,
    post,
    postBulk,
    update,
    softDelete,
    getSegmentsForSession,
  };
}
