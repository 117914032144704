const speedLimit = ["Kilometres per hour"];

const curvature = [
  "Straight or gently curving",
  "Moderate",
  "Sharp",
  " Very Sharp",
];

const skidResistance = [
  "Sealed - adequate",
  "Sealed - medium",
  "Sealed - poor",
  "Unsealed - adequate",
  "Unsealed – poor",
];

const laneWidth = [
  "Narrow: >= 0m to < 2.75m ",
  "Medium: >= 2.75m to < 3.25m",
  "Wide: >= 3.25m",
];

const roadsideHazards = ["0 to <1m ", "1 to <5m", "5 to <10m ", ">=10m"];

const roadCondition = ["Good", "Medium", "Poor"];

const delineation = ["Poor", "Adequate"];

const lanes = [
  "One",
  "Two and One",
  "Two",
  "Three and Two",
  "Three",
  "Four or More",
];

const carriageway = [
  "Undivided Road",
  "Carriageway A of a Divided Carriageway Road",
  "Carriageway B of a Divided Carriageway Road",
  "Carriageway A of a Motorcycle Facility",
  "Carriageway B of a Motorcycle Facility",
];

const pavedShoulder = [
  "Narrow: >= 0m to < 1.0m",
  "Medium: >= 1.0m to < 2.4m",
  "Wide: >= 2.4m",
];

const roadsideObjects = [
  "None",
  "Cliff",
  "Tree >= 10cm dia.",
  "Sign, Post or Pole >= 10cm dia.",
  "Rigid Structure/ Bridge or Building",
  "Unprotected Safety Barrier End",
  "Large Boulders >=20cm High",
  "Aggressive Vertical Face",
  "Deep Drainage Ditch",
  "Upwards Slope - Rollover Gradient",
  "Downwards Slope",
  "Upwards Slope - No Rollover Gradient",
  "Semi-rigid Structure or Building",
  "Safety Barrier - Metal",
  "Safety Barrier - Motorcycle Friendly",
  "Safety Barrier - Concrete",
  "Safety Barrier - Wire Rope",
];

const shoulderRumbleStrips = ["Present", "Not Present"];

const centrelineRumbleStrips = ["Present", "Not Present"];

const medianType = [
  "Centreline",
  "Wide Centreline (0.3m to 1m)",
  "Central Hatching (> 1m)",
  "Continuous Central Turning Lane",
  "Flexipost",
  "Physical Median Width (>= 0m to < 1.0m)",
  "Physical Median Width (>= 1.0m to < 5.0m)",
  "Physical Median Width (>= 5.0m to < 10.0m)",
  "Safety Barrier - Concrete",
  "Safety Barrier - Metal",
  "Safety Barrier - Motorcycle Friendly",
  "Safety Barrier - Wire Rope",
  "Physical Median Width (>= 10.0m to < 20.0m)",
  "Physical Median Width (>= 20.0m)",
  "One Way",
];

const propertyAccessPoints = [
  "None",
  "Residential Access 1 or 2",
  "Residential Access 3+",
  "Commercial Access 1+",
];

const intersectionType = [
  "None",
  "4-leg (Unsignalised) with no Protected Turn Lane",
  "4-leg (Unsignalised) with Protected Turn Lane",
  "4-leg (Signalised) with no Protected Turn Lane",
  "3-leg (Unsignalised) with no Protected Turn Lane",
  "3-leg (Unsignalised) with Protected Turn Lane",
  "Mini Roundabout",
  "3-leg (Signalised) with no Protected Turn Lane",
  "4-leg (Signalised) with Protected Turn Lane",
  "3-leg (Signalised) with Protected Turn Lane",
  "Roundabout",
  "Railway Crossing - Passive (Signs Only)",
  "Merge Lane",
  "Railway Crossing - Active (Flashing Lights/ Boom Gates)",
  "Median Crossing Point - Informal",
  "Median Crossing Point - Formal",
];

const intersectingVolume = [
  "None",
  "1 to 100 vehicles",
  "100 to 1,000 vehicles",
  "1,000 to 5,000 vehicles",
  "5,000 to 10,000 vehicles",
  "10,000 to 15,000 vehicles",
  ">= 15,000 vehicles",
];

const vehicleParking = ["None", "One Side", "Two Sides"];

const sideWalk = [
  "None",
  "Informal Path (0m to < 1.0m)",
  "Informal Path (>= 1.0m)",
  "Non-physical Separation (0m to < 1.0m)",
  "Non-physical Separation (1,0m to < 3.0m)",
  "Non-physical Separation (>= 3.0m)",
  "Physical Barrier",
];

const bicycleFacilities = [
  "None",
  "Signed Shared Roadway",
  "Extra Wide Outside (>= 4.2m)",
  "On-road Lane",
  "Shared Use Path",
  "Off-road Path",
  "Off-road Path with Barrier",
];

const streetLighting = ["Present", "Not Present"];

const vehicleFlow = ["Annual Average Daily Traffic (AADT)"];

const pedestrianFacilities = ["Distances reference proximity to carriageway"];

export const roadAttributeData = {
  speedLimit,
  curvature,
  skidResistance,
  laneWidth,
  roadsideHazards,
  roadCondition,
  delineation,
  lanes,
  carriageway,
  pavedShoulder,
  roadsideObjects,
  shoulderRumbleStrips,
  centrelineRumbleStrips,
  medianType,
  propertyAccessPoints,
  intersectionType,
  intersectingVolume,
  vehicleParking,
  sideWalk,
  bicycleFacilities,
  streetLighting,
  vehicleFlow,
  pedestrianFacilities,
};
