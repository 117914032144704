import { createBrowserRouter } from "react-router-dom";
import {
  AdminRegions,
  AdminUsers,
  AdminViewGov,
  AdminViewRegion,
  ConfirmEmail,
  Home,
  Notes,
  Preferences,
  Questionnaire,
  ResetPassword,
  Segment,
  SegmentForms,
  Sync,
} from "./pages";
import { Layout, LoginLayout } from "./components";
import ResourcesPage from "./pages/resources/ResourcePage";
import LoadedSegments from "./pages/segments/LoadedSegments";
import PreviousLookup from "./pages/lookup/PreviousLookup";
import EditSession from "./pages/sessions/editsessions/EditSession";
import UserGuide from "./pages/userguide/UserGuide";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout title="">
        <Home />
      </Layout>
    ),
  },
  {
    path: "/notes",
    element: (
      <Layout title="Notes">
        <Notes />
      </Layout>
    ),
  },
  {
    path: "/preferences",
    element: (
      <Layout title="Preferences">
        <Preferences />
      </Layout>
    ),
  },
  {
    path: "/sync",
    element: (
      <Layout title="Sync Local Sessions">
        <Sync />
      </Layout>
    ),
  },
  {
    path: "/questionnaire",
    element: (
      <Layout title="Capacity Questionnaire">
        <Questionnaire />
      </Layout>
    ),
  },
  {
    path: "/resources",
    element: (
      <Layout title="Links & Resources">
        <ResourcesPage />
      </Layout>
    ),
  },
  {
    path: "/userguide",
    element: (
      <Layout title="How to use the app">
        <UserGuide />
      </Layout>
    ),
  },
  {
    path: "/session/:id",
    children: [
      {
        path: "segment",
        element: (
          <Layout title="Define Segments">
            <Segment />
          </Layout>
        ),
      },
      {
        path: "segment/:segmentId?/:stage?",
        element: (
          <Layout title="Segment" useTitleContext>
            <SegmentForms />
          </Layout>
        ),
      },
      {
        path: "loadedsegments",
        element: (
          <Layout title="Loaded Segments">
            <LoadedSegments />
          </Layout>
        ),
      },
      {
        path: "editsession",
        element: (
          <Layout title="Edit Session">
            <EditSession />
          </Layout>
        ),
      },
    ],
  },
  {
    path: "/previous",
    element: (
      <Layout title="Lookup">
        <PreviousLookup />
      </Layout>
    ),
  },
  {
    path: "/emailConfirm/:email/:code",
    element: (
      <LoginLayout>
        <ConfirmEmail />
      </LoginLayout>
    ),
  },
  {
    path: "/resetPassword/:email/:code",
    element: (
      <LoginLayout>
        <ResetPassword />
      </LoginLayout>
    ),
  },
  {
    path: "/admin",
    children: [
      {
        path: "users",
        element: (
          <Layout title="Admin Dashboard" isAdmin={true}>
            <AdminUsers />
          </Layout>
        ),
      },
      {
        path: "regions",
        element: (
          <Layout title="Admin Dashboard" isAdmin={true}>
            <AdminRegions />
          </Layout>
        ),
      },
      {
        path: "regions/:region",
        element: (
          <Layout title="Admin Dashboard" isAdmin={true}>
            <AdminViewRegion />
          </Layout>
        ),
      },
      {
        path: "regions/:region/:govId",
        element: (
          <Layout title="Admin Dashboard" isAdmin={true}>
            <AdminViewGov />
          </Layout>
        ),
      },
    ],
  },
]);
