import React, { useState } from "react";
import {
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAPI, useIsOnline, useSnackbar } from "../../hooks";

export default function Preferences() {
  const { currentUser, users } = useAPI();
  const showSnackbar = useSnackbar();
  const theme = useTheme();
  const [isUpdating, setIsUpdating] = useState(false);
  const isOnline = useIsOnline();

  async function onDataUsageChange() {
    if (isOnline) {
      setIsUpdating(true);
      const results = await users.updatePreferences({
        allowDataAggregation: !currentUser.allowDataAggregation,
      });

      if (results.status === 200) {
        showSnackbar("User updated", "success");
      } else {
        showSnackbar("Couldn't update data!", "error");
      }

      setIsUpdating(false);
    }
  }

  return (
    <>
      <Typography variant="h4">App Preferences</Typography>
      <Divider />
      <Stack spacing={2}>
        <Typography>Version: {process.env.REACT_APP_VERSION}</Typography>
        <FormControlLabel
          control={
            <Switch
              disabled={isUpdating && !isOnline}
              checked={currentUser?.allowDataAggregation ?? false}
              onChange={onDataUsageChange}
            />
          }
          label="Data Usage"
        />
        <Typography variant="caption" color={theme.palette.text.secondary}>
          Allows LG Stars to use entered data to aggregate to the regional
          level. {isOnline ? "" : "Disabled in offline mode."}
        </Typography>
      </Stack>
    </>
  );
}
