import React, { useEffect, useState } from "react";
import { SortableSessionTable } from "../../../components";
import { sortableTableHeaders } from "../headerData";
import { useAPI, useRound } from "../../../hooks";

export default function RecentSessions() {
  const [rowData, setRowData] = useState([]);
  const { sessions } = useAPI();
  const round = useRound();

  useEffect(() => {
    const fetchData = async () => {
      const response = await sessions.get(round.id, 5);
      if (response?.status === 200) {
        setRowData(response.data);
      }
    };
    if (round) {
      fetchData();
    }
  }, [sessions, round]);

  return (
    <>
      <SortableSessionTable
        headers={sortableTableHeaders}
        rows={rowData}
      ></SortableSessionTable>
    </>
  );
}
