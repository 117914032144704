import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export default function SummaryStarRating({
  rating,
  isZeroStars,
  isHeaderRating = false,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const renderedStars = [];
  const starSize = isHeaderRating ? "40px" : undefined;
  const starTopMargin = isHeaderRating ? "4px" : undefined;
  const typographyStyle = isHeaderRating ? "h1" : undefined;

  if (isZeroStars) {
    for (let i = 0; i < 5; i++) {
      renderedStars.push(
        <StarBorderIcon
          key={`${rating.name}-${i}`}
          style={{
            color: theme.palette.secondary.main,
          }}
        />
      );
    }
  } else {
    for (let i = 0; i < rating.value; i++) {
      renderedStars.push(
        <StarIcon
          key={`${rating.name}-${i}`}
          style={{
            marginTop: starTopMargin,
            fontSize: starSize,
            color: theme.palette.secondary.main,
          }}
        />
      );
    }
    for (let i = 0; i < 5 - rating.value; i++) {
      renderedStars.push(
        <StarBorderIcon
          key={`${rating.name}-${5 - i}`}
          style={{
            marginTop: starTopMargin,
            fontSize: starSize,
            color: theme.palette.secondary.main,
          }}
        />
      );
    }
  }

  return (
    <Stack direction={isMobile ? "column" : "row"}>
      <Typography variant={typographyStyle} sx={{ marginRight: "0.3em" }}>
        {rating.name}:
      </Typography>
      <Stack direction="row">{renderedStars}</Stack>
    </Stack>
  );
}
