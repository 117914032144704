import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  adminData,
  localDatabase,
  roadNetworkData,
  roundData,
  segmentData,
  sessionData,
  userData,
} from "../database";
import { useIsOnline } from "./useIsOnline";

const APIContext = createContext("");

export const useAPI = () => useContext(APIContext);

export function APIProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [hasLocalData, setHasLocalData] = useState(false);
  const isOnline = useIsOnline();

  const defaultHeader = {
    Authorization: `Bearer ${currentUser?.jwtToken}`,
  };

  const rounds = roundData(defaultHeader, currentUser, isOnline);
  const users = userData(defaultHeader, currentUser, setCurrentUser, isOnline);
  const sessions = sessionData(defaultHeader, isOnline);
  const segments = segmentData(defaultHeader, isOnline);
  const admin = adminData(defaultHeader, isOnline);
  const roadNetwork = roadNetworkData(defaultHeader, isOnline);

  useEffect(() => {
    async function retrieveLocalStorageUser() {
      const localUsers = await localDatabase.currentUser.toArray();
      if (localUsers.length > 0) {
        setCurrentUser(localUsers[0]);
      }
    }

    retrieveLocalStorageUser();
    checkForLocalData();
  }, [isOnline]);

  async function checkForLocalData() {
    const localSessions = await localDatabase.session.count();
    const localSegments = await localDatabase.segment.count();
    setHasLocalData(localSessions > 0 || localSegments > 0);
  }

  async function confirmEmail(email, code) {
    try {
      const results = await axios.post(
        `${process.env.PUBLIC_URL}/api/confirmEmail`,
        { email: email, code: code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return results;
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error);
      }
      return error.response;
    }
  }

  async function resetPassword(email, password, code) {
    try {
      const results = await axios.post(
        `${process.env.PUBLIC_URL}/api/resetPassword`,
        { email, password, code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return results;
    } catch (error) {
      if (error.response.status === 500) {
        console.log(error);
      }
      return error.response;
    }
  }

  const value = {
    currentUser,
    rounds,
    users,
    sessions,
    segments,
    admin,
    roadNetwork,
    setCurrentUser,
    confirmEmail,
    resetPassword,
    hasLocalData,
    checkForLocalData,
  };

  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
}
