import { Controller } from "react-hook-form";
import { FormControl, RadioGroup, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function RadioController({
  text,
  control,
  name,
  children,
  errors,
}) {
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Typography variant="h6">{text}</Typography>
      <FormControl>
        <Controller
          rules={{ required: true }}
          control={control}
          name={name}
          defaultValue={""}
          render={({ field }) => (
            <RadioGroup row {...field}>
              {children}
            </RadioGroup>
          )}
        />
      </FormControl>
      {errors[name] && (
        <Typography color={theme.palette.error.main}>
          This is required.
        </Typography>
      )}
    </Stack>
  );
}
