import { useEffect } from "react";
import PasswordFieldInput from "./PasswordFieldInput";

export default function NewPasswordInput({
  watchPassword,
  watchConfirmPassword,
  setError,
  clearErrors,
  control,
  errors,
  confirmPasswordName,
  passwordName,
}) {
  useEffect(() => {
    if (
      watchPassword !== undefined &&
      watchConfirmPassword &&
      watchPassword !== watchConfirmPassword
    ) {
      setError(confirmPasswordName, {
        type: "custom",
        message: "Passwords are not the same",
      });
    } else {
      clearErrors(confirmPasswordName);
    }
  }, [clearErrors, confirmPasswordName, setError, watchConfirmPassword, watchPassword]);

  useEffect(() => {
    if (watchPassword === undefined || watchPassword.length === 0) {
      return;
    }

    let errorMessage = "Your password must: ";
    let isError = false;

    if (!watchPassword.match(/[a-z]+/)) {
      isError = true;
      errorMessage = errorMessage.concat("Contain a lowercase letter. ");
    }

    if (!watchPassword.match(/[A-Z]+/)) {
      isError = true;
      errorMessage = errorMessage.concat("Contain an uppercase letter. ");
    }

    if (!watchPassword.match(/[0-9]+/)) {
      isError = true;
      errorMessage = errorMessage.concat("Contain a number. ");
    }

    if (watchPassword.match(/^\w+$/)) {
      isError = true;
      errorMessage = errorMessage.concat(
        "Contain an alphanumeric character (like $ or @). "
      );
    }

    if (watchPassword.length < 8) {
      isError = true;
      errorMessage = errorMessage.concat("Be at least 8 characters long.");
    }

    if (isError) {
      setError(passwordName, {
        type: "custom",
        message: errorMessage,
      });
    } else {
      clearErrors(passwordName);
    }
  }, [clearErrors, passwordName, setError, watchPassword]);

  return (
    <>
      <PasswordFieldInput
        name={passwordName}
        label="Password"
        control={control}
        errors={errors}
      />
      <PasswordFieldInput
        name={confirmPasswordName}
        label="Confirm Password"
        control={control}
        errors={errors}
      />
    </>
  );
}
