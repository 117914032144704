/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { Login } from "../pages";
import axios from "axios";
import { LoginLayout } from "../components";
import { useAPI } from "./useAPI";
import { authData, localDatabase } from "../database";
import { useSnackbar } from "./useSnackbar";
import { useIsOnline } from "./useIsOnline";

const LoginContext = createContext("");

export const useLogin = () => useContext(LoginContext);

export function LoginProvider({ children }) {
  const { currentUser, setCurrentUser } = useAPI();
  const isOnline = useIsOnline();
  const auth = authData(currentUser, setCurrentUser, isOnline);
  const [timer, setTimer] = useState(null);
  const showSnackbar = useSnackbar();

  async function logout(e) {
    e?.preventDefault();
    if (currentUser) {
      await localDatabase.currentUser.delete(currentUser?.id);
    }

    setCurrentUser(null);
  }

  //interceptor to detect token issues
  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        //this is for 200 responses
        return response;
      },
      function (error) {
        //logout if token expired
        if (error.response?.status === 401) {
          showSnackbar("Token expired! Please log in again.", "error");
          logout();
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (currentUser?.jwtToken) {
      clearTimeout(timer);
      //refresh the token every 15 minutes. This is an arbitrary number - it could be anything!
      const timeoutId = setTimeout(() => auth.refreshToken(), 900000);
      setTimer(timeoutId);
    }
  }, [currentUser?.jwtToken]);

  useEffect(() => {
    if(isOnline && currentUser?.email === "offline") {
      logout();
    }
  }, [currentUser?.email, isOnline])

  const value = {
    logout,
  };

  return currentUser?.jwtToken?.length > 0 ? (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  ) : (
    <LoginLayout>
      <Login
        login={auth.login}
        logout={logout}
        signUp={auth.signUp}
        forgotPassword={auth.forgotPassword}
        user={currentUser}
      />
    </LoginLayout>
  );
}
