import React from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  customIdName,
  dateAssessedName,
  fromSLKName,
  roadName,
  toSLKName,
  uniqueIdName,
} from "../../common/formNames";
import dayjs from "dayjs";

function SegmentDataSection({ title, value }) {
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Typography
        variant="caption"
        sx={{ color: theme.palette.text.secondary }}
      >
        {title}
      </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
}

export default function SegmentDataView({ data }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return isMobile ? (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <SegmentDataSection title="Name" value={data[roadName]} />
      </Grid>
      <Grid item xs={4}>
        <SegmentDataSection title="Unique ID" value={data[uniqueIdName]} />
      </Grid>
      <Grid item xs={4}>
        <SegmentDataSection title="Custom ID" value={data[customIdName]} />
      </Grid>
      <Grid item xs={4}>
        <SegmentDataSection title="From (SLK)" value={data[fromSLKName]} />
      </Grid>
      <Grid item xs={4}>
        <SegmentDataSection title="To (SLK)" value={data[toSLKName]} />
      </Grid>
      <Grid item xs={4}>
        <SegmentDataSection
          title="DateAssessed"
          value={dayjs(data[dateAssessedName]).format("DD/MM/YYYY")}
        />
      </Grid>
    </Grid>
  ) : (
    <Stack
      direction="row"
      spacing={2}
      sx={{ width: "100%", justifyContent: "space-between" }}
    >
      <SegmentDataSection title="Road/Street Name" value={data[roadName]} />
      <SegmentDataSection title="Unique ID" value={data[uniqueIdName]} />
      <SegmentDataSection title="Custom ID" value={data[customIdName]} />
      <SegmentDataSection title="From (SLK)" value={data[fromSLKName]} />
      <SegmentDataSection title="To (SLK)" value={data[toSLKName]} />
      <SegmentDataSection
        title="DateAssessed"
        value={dayjs(data[dateAssessedName]).format("DD/MM/YYYY")}
      />
    </Stack>
  );
}
