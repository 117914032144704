import { Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function TopSection({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100vw",
        padding: isMobile ? "2em 1em" : "2em 3em",
        marginLeft: isMobile ? "-1em !important" : "-3em !important",
        marginTop: "-2em !important",
      }}
    >
      {children}
    </Paper>
  );
}
