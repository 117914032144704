import { localDatabase } from "./localDatabase";
import { postRequest } from "./requests";

export default function authData(currentUser, setCurrentUser, isOnline) {
  async function login(name, password) {
    if (isOnline) {
      try {
        const results = await postRequest(`login`, {
          user: name,
          password: password,
        });

        if (results.status === 200) {
          setCurrentUser(results.data);
          localDatabase.currentUser.clear();
          localDatabase.currentUser.put(results.data);
          return results.data;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      //allow offline functionality
      const user = {
        id: crypto.randomUUID(),
        email: "offline",
        jwtToken: "offline",
        governmentAreaId: "offline",
        governmentName: "offline",
      };

      setCurrentUser(user);
      localDatabase.currentUser.put(user);

      return true;
    }
  }

  async function signUp(name, password) {
    if (isOnline) {
      try {
        const results = await postRequest(`signup`, {
          user: name,
          password: password,
        });

        if (results.status === 200) {
          setCurrentUser(results.data);
          localDatabase.currentUser.add(results.data);
        }

        return results;
      } catch (error) {
        if (error.response.status === 500) {
          console.log(error);
        }
        return error.response;
      }
    }
  }

  async function forgotPassword(email) {
    if (isOnline) {
      try {
        const results = await postRequest(`forgotpassword`, { email });

        return results;
      } catch (error) {
        if (error.response.status === 500) {
          console.log(error);
        }
        return error.response;
      }
    }
  }

  async function refreshToken() {
    if (isOnline) {
      try {
        const results = await postRequest(`refreshtoken`, {
          email: currentUser.email,
        });

        if (results.status === 200) {
          const clone = structuredClone(currentUser);
          clone.jwtToken = results.data;
          setCurrentUser(clone);
          localDatabase.currentUser.put(clone);
        }

        return results;
      } catch (error) {
        if (error.response.status === 500) {
          console.log(error);
        }
        return error.response;
      }
    }

    //can't refresh the token without the internet. Assume good.
    return { status: 200 };
  }

  return { login, signUp, forgotPassword, refreshToken };
}
