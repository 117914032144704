import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAPI, useLoadingModal, useSnackbar } from "../../hooks";
import { NewPasswordInput } from "../../components";
import { confirmPasswordName, passwordName } from "./common/formNames";
import { useForm } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ResetPassword() {
  const { email, code } = useParams();
  const { resetPassword } = useAPI();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const showSnackbar = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const setLoading = useLoadingModal();

  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchPassword = watch(passwordName);
  const watchConfirmPassword = watch(confirmPasswordName);

  async function onSubmit(form) {
    setLoading(true);
    const result = await resetPassword(email, form[passwordName], code);
    setLoading(false);
    if (result.status === 200) {
      showSnackbar("Password reset", "success");
      setIsSuccess(true);
      setTimeout(() => navigate("/"), 4000);
    } else {
      showSnackbar("Couldn't reset password", "error");
    }
  }

  return (
    <Paper
      elevation={8}
      sx={{ padding: "3em", width: isMobile ? "90%" : "50%" }}
    >
      {isSuccess ? (
        <Stack spacing={4} sx={{ alignItems: "center" }}>
          <Typography variant="h3">Password Successfully Reset</Typography>
          <CheckCircleIcon fontSize="large" color="success" />
          <Stack spacing={1}>
            <Typography>Your password has been reset!</Typography>
            <Typography>Redirecting you to the Log In page.</Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h1">New Password</Typography>
          <NewPasswordInput
            watchPassword={watchPassword}
            watchConfirmPassword={watchConfirmPassword}
            setError={setError}
            clearErrors={clearErrors}
            control={control}
            errors={errors}
            confirmPasswordName={confirmPasswordName}
            passwordName={passwordName}
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleSubmit(onSubmit)()}
            disabled={Object.entries(errors).length > 0}
            sx={{ padding: "1em 0" }}
          >
            Reset Password
          </Button>
        </Stack>
      )}
    </Paper>
  );
}
