import React, { useState } from "react";
import { Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { carriagewayList, contextList, sealList } from "../common/roadTypeData";
import Dropdown from "./components/Dropdown";
import { RoadCard, SegmentDataView } from "./components";
import { TopSection } from "../../../../components";
import roadChecklistData from "../common/dropdowndata/roadChecklistData";
import { useNavigate } from "react-router-dom";

export default function SelectRoadType({ data, onSubmit }) {
  const existingRoadType = roadChecklistData.find(
    (r) => r["Road Type"] === data?.roadType
  );
  const [roadContext, setRoadContext] = useState(
    existingRoadType ? existingRoadType.Context : ""
  );
  const [seal, setSeal] = useState(
    existingRoadType ? existingRoadType.Seal : ""
  );
  const [carriageWay, setCarriageWay] = useState(
    existingRoadType ? existingRoadType.Carriageway : ""
  );
  const [aadt, setAadt] = useState(
    existingRoadType ? existingRoadType.AADT : ""
  );
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const filteredRoadTypes = roadChecklistData.filter(
    (r) =>
      (roadContext.length === 0 || r.Context === roadContext) &&
      (seal.length === 0 || r.Seal === seal) &&
      (carriageWay.length === 0 || r.Carriageway === carriageWay) &&
      (aadt.length === 0 || r.AADT === aadt)
  );

  const filteredAadts = [...new Set(filteredRoadTypes.map((f) => f.AADT))];

  function onClick(roadName) {
    onSubmit(roadName);
  }

  function changeRoadContext(choice) {
    setRoadContext(choice);
    setSeal("");
    setCarriageWay("");
    setAadt("");
  }

  function changeSeal(choice) {
    setSeal(choice);
    setCarriageWay("");
    setAadt("");
  }

  function changeCarriageWay(choice) {
    setCarriageWay(choice);
    setAadt("");
  }

  return (
    <>
      <TopSection>
        <Stack spacing={2}>
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h5">Select Road Type</Typography>
            <Stack sx={{ flex: 1, alignItems: isMobile ? "center" : "end" }}>
              <Button
                variant="outlined"
                sx={{ width: "auto", padding: "0.5em 2em" }}
                onClick={() => navigate("/notes#crosssection")}
              >
                Notes on Cross Section
              </Button>
            </Stack>
          </Stack>
          <SegmentDataView data={data} />
        </Stack>
      </TopSection>

      <Stack spacing={2}>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Dropdown
            label="Context"
            data={contextList}
            choice={roadContext}
            onSelect={changeRoadContext}
          />
          <Dropdown
            label="Seal"
            data={sealList}
            choice={seal}
            onSelect={changeSeal}
          />
          <Dropdown
            label="Carriageway"
            data={carriagewayList}
            choice={carriageWay}
            onSelect={changeCarriageWay}
          />
          <Dropdown
            label="AADT"
            data={filteredAadts}
            choice={aadt}
            onSelect={setAadt}
          />
        </Stack>
        <Grid container>
          {filteredRoadTypes.map((road, index) => {
            return (
              <Grid key={road["Road Type"]} item xs={12} md={6}>
                <RoadCard
                  data={road}
                  onClick={onClick}
                  isRight={index % 2 === 1}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
}
