import { FormControlLabel, Radio } from "@mui/material";

export default function RadioOption({ label, value }) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
      sx={{ width: "30%" }}
    />
  );
}
