import React, { useState } from "react";
import { Button, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import {
  customIdName,
  dateAssessedName,
  roadName,
  uniqueIdName,
} from "../common/formNames";
import { DateFieldInput, TextFieldInput } from "../../../../components";
import { useAPI } from "../../../../hooks";
import dayjs from "dayjs";

export default function BasicSegmentDetails({ data, onSubmit }) {
  const defaultValues = {
    [roadName]: data.roadName ?? "",
    [customIdName]: data.customId ?? "",
    [dateAssessedName]: data.dateAssessed ? dayjs(data.dateAssessed) : dayjs(),
    [uniqueIdName]: data.uniqueId ?? "",
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });

  const { roadNetwork } = useAPI();
  const [timer, setTimer] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function onRoadNameChange(name) {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => onTimeoutSubmit(name), 1000);
    setTimer(timeoutId);
  }

  async function onTimeoutSubmit(name) {
    console.log(name);
    const results = await roadNetwork.search(name);

    if (results.status === 200) {
      console.log(results);
      setValue(uniqueIdName, results.data.toString());
    }
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h5">Input road information</Typography>
        {!isMobile && (
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={() => handleSubmit(onSubmit)()}
            >
              Next
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2}>
        <TextFieldInput
          name={roadName}
          label="Road/Street Name"
          control={control}
          errors={errors}
          onChange={onRoadNameChange}
          required={true}
        />
        <Stack spacing={1}>
          <TextFieldInput
            name={uniqueIdName}
            label="Unique ID"
            control={control}
            errors={errors}
            required={true}
          />
          <Typography
            variant="caption"
            sx={{ paddingLeft: "1em", color: theme.palette.text.secondary }}
          >
            Main Roads Unique ID (ROAD) If you're unsure of the ID, please check
            via{" "}
            <Link
              target="blank"
              href="https://mrwebapps.mainroads.wa.gov.au/gpsslk"
            >
              the Main Roads Website
            </Link>
          </Typography>
        </Stack>

        <TextFieldInput
          name={customIdName}
          label="Custom ID"
          control={control}
          errors={errors}
          required={false}
          note="A custom ID of your choosing"
        />
        <DateFieldInput
          name={dateAssessedName}
          label="Date Assessed"
          control={control}
          errors={errors}
          required={true}
        />
        {isMobile && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto", padding: "0.5em 2em" }}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Next
          </Button>
        )}
      </Stack>
    </>
  );
}
