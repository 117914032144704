import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import ChoiceModal from "./ChoiceModal";
import CreateSessionModal from "./CreateSessionModal";

export default function NewSessionModal({ showChoiceModal, onClose }) {
  const navigate = useNavigate();
  const [goToNewSession, setGoToNewSession] = useState(showChoiceModal);
  const [createSessionOpen, setCreateSessionOpen] = useState(false);

  useEffect(() => {
    setGoToNewSession(showChoiceModal);
  }, [showChoiceModal]);

  async function onNewSession(takeQuestionnaire) {
    if (takeQuestionnaire) {
      navigate("/questionnaire");
    } else {
      setCreateSessionOpen(true);
    }
    setGoToNewSession(false);
  }

  function handleClose() {
    setCreateSessionOpen(false);
    setGoToNewSession(false);
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      <ChoiceModal
        open={goToNewSession}
        text="This questionnaire may help you decide which tool is best suited to assessing the safety of your road network, based on your Local Government's capacity. Would you like to take the survey?"
        yesText="Take Assessment"
        noText="Skip"
        onYes={() => onNewSession(true)}
        onNo={() => onNewSession(false)}
        onClose={handleClose}
      />

      <CreateSessionModal open={createSessionOpen} onClose={handleClose} />
    </>
  );
}
