import { getRequest, postRequest } from "./requests";

export default function adminData(defaultHeader, isOnline) {
  async function getUsers() {
    if (isOnline) {
      return await getRequest(`admin/users`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function getRegions() {
    if (isOnline) {
      return await getRequest(`admin/regions`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function getRegion(region) {
    if (isOnline) {
      return await getRequest(`admin/regions/${region}`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function getRegionNames() {
    if (isOnline) {
      return await getRequest(`admin/regions/names`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function getGovs() {
    if (isOnline) {
      return await getRequest(`admin/localgovs`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function getGovAnalysis(govId) {
    if (isOnline) {
      return await getRequest(`admin/localgovs/${govId}`, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function createNewUser(user) {
    if (isOnline) {
      return await postRequest(`admin/users`, user, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  async function setLockUser(user) {
    if (isOnline) {
      return await postRequest(`admin/users/lock`, user, defaultHeader);
    }
    return { status: 400, data: "Not Online" };
  }

  return {
    getUsers,
    getRegions,
    getRegion,
    getRegionNames,
    getGovs,
    getGovAnalysis,
    createNewUser,
    setLockUser,
  };
}
