/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import RecentSessions from "../sessions/recentsessions/RecentSessions";
import { ChoiceModal, NewSessionModal } from "../../components";
import OverviewSection from "../rounds/components/OverviewSection";
import { editSegment } from "../segments/forms/common/journeyType";
import { roundSummary } from "../segments/forms/common/journeyNames";
import { useAPI, useRound } from "../../hooks";
import { convertToPerthDate } from "../../helpers";
import FirstTimeModal from "./components/FirstTimeModal";

export default function Home() {
  const { sessions, users, currentUser } = useAPI();
  const [goToNewSession, setGoToNewSession] = useState(false);
  const [segments, setSegments] = useState([]);
  const [userSessions, setUserSessions] = useState([]);
  const [finishSession, setFinishSession] = useState(null);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(!currentUser.viewedFirstTimeModal);
  const navigate = useNavigate();
  const round = useRound();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      const result = await sessions.get(round.id);
      if (result.status === 200) {
        const segments = result.data.flatMap((session) => session.segments);
        setSegments(segments);
        setUserSessions(result.data);
        result.data.forEach(checkFinishStatus);
      }
    };
    if (round) {
      fetchData();
    }
  }, [round]);

  function handleFinaliseRound() {
    navigate(`/session/${userSessions[0].id}/segment/${segments[0].id}`, {
      state: {
        journeyType: editSegment,
        startingPoint: roundSummary,
      },
    });
  }

  function checkFinishStatus(session) {
    if (!session.isFinished) {
      setShowFinishModal(true);
      setFinishSession(session);
    }
  }

  function onResumeSession(resumeSession) {
    if (resumeSession) {
      navigate(`/session/${finishSession.id}/editsession`);
    } else {
      handleClose();
    }
  }

  function handleClose() {
    setShowFinishModal(false);
    setFinishSession(null);
  }

  async function onFirstTimeClose() {
    await users.setViewFirstTimeModal()
    setShowFirstTimeModal(false);
  }

  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={1}
        sx={{ justifyContent: "center", textAlign: "center" }}
      >
        <Typography variant="h4">Dashboard</Typography>
        <Stack sx={{ flex: 1, alignItems: isMobile ? "center" : "end" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto" }}
            onClick={() => setGoToNewSession(true)}
          >
            Start New Session
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <Stack direction="row" sx={{ justifyContent: "center" }}>
          <Typography variant="h6">Recent Sessions</Typography>
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="outlined"
              sx={{ width: "auto" }}
              onClick={() => {
                navigate("/previous");
              }}
            >
              View All
            </Button>
          </Stack>
        </Stack>
        <RecentSessions />
      </Stack>
      <Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={1}
          sx={{
            justifyContent: "space-between",
            textAlign: "center",
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          <Typography variant="h6">Current Round of Analysis</Typography>
          <Button
            variant="outlined"
            sx={{ width: "auto" }}
            onClick={handleFinaliseRound}
          >
            Finalise Round of Analysis
          </Button>
        </Stack>
        <OverviewSection
          roundSegments={segments}
          roadsInNetwork={round?.roadInNetworkCount}
          kmsInNetwork={round?.kmsInNetworkTotal}
        />
      </Stack>
      <Stack sx={{ alignItems: "center", marginTop: "2em" }}>
        <Typography>
          Got any feedback? Feel free to email{" "}
          <Link href="mailto:infrastructure@walga.asn.au ">
            infrastructure@walga.asn.au{" "}
          </Link>
          and let us know how we can improve this app.
        </Typography>
      </Stack>
      <NewSessionModal
        showChoiceModal={goToNewSession}
        onClose={() => setGoToNewSession(false)}
      />
      <ChoiceModal
        open={showFinishModal}
        text={
          finishSession?.segmentsCount > 0
            ? `You have an unfinished session, ${
                finishSession?.segments[0].roadName
              } from ${convertToPerthDate(
                finishSession?.lastUpdated
              )}. Would you like to finish it?`
            : `You have an unfinished session from ${convertToPerthDate(
                finishSession?.lastUpdated
              )}. Would you like to finish it?`
        }
        yesText="Yes"
        noText="Not Today"
        onYes={() => onResumeSession(true)}
        onNo={() => onResumeSession(false)}
        onClose={handleClose}
      />
      <FirstTimeModal open={showFirstTimeModal} onClose={onFirstTimeClose} />
    </>
  );
}
