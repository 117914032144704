import { Box, Divider, IconButton, Typography } from "@mui/material";

export default function ContentWithLinkIcon({
  title,
  body,
  icon,
  href,
  headerType = "h3",
  divider,
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mb: 0 }} variant={headerType}>
          {title}
        </Typography>
        <IconButton
          onClick={() => {
            window.open(href, "_blank");
          }}
        >
          {icon}
        </IconButton>
      </Box>
      {body && <Typography variant="b1">{body}</Typography>}
      {divider && <Divider sx={{ paddingTop: 1 }} orientation="horizontal" />}
    </>
  );
}
