import React, { useState } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import WhiteArrowIcon from "../icons/WhiteArrowIcon";
import { LightTextTableCell } from "./LightTextTableCell";

const direction = {
  Ascending: "asc",
  Descending: "desc",
};

/*
This expects the following header model:
{
    name: Name of the Header
    fieldName: The field of the row to show under this Header
    sortable: true or false
}

Rows always expect an Id.
*/
export default function SortableTable({ headers, rows, loading }) {
  const theme = useTheme();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: direction.Ascending,
  });

  function requestSort(key) {
    setSortConfig((prevState) => ({
      key,
      direction:
        prevState.direction === direction.Ascending
          ? direction.Descending
          : direction.Ascending,
    }));
  }

  const data = sortConfig.key
    ? [...rows].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === direction.Ascending ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === direction.Ascending ? 1 : -1;
        }
        return 0;
      })
    : rows;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            <TableRow>
              {headers.map((header) => {
                return header.sortable ? (
                  <LightTextTableCell
                    key={header.fieldName}
                    sortDirection={
                      sortConfig.key === header.fieldName
                        ? sortConfig.direction
                        : false
                    }
                  >
                    <TableSortLabel
                      direction={
                        sortConfig.key === header.fieldName
                          ? sortConfig.direction
                          : direction.Ascending
                      }
                      onClick={() => requestSort(header.fieldName)}
                      IconComponent={(props) => (
                        <WhiteArrowIcon
                          {...props}
                          direction={sortConfig.direction}
                          active={sortConfig.key === header.fieldName}
                        />
                      )}
                      sx={theme.components.TableSortLabel}
                    >
                      {header.name}
                    </TableSortLabel>
                  </LightTextTableCell>
                ) : (
                  <LightTextTableCell key={header.fieldName}>
                    {header.name}
                  </LightTextTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {headers.map((header) => {
                  const value = row[header.fieldName];
                  return (
                    <TableCell
                      key={header.fieldName}
                      align={header.name.length ? "left" : "right"}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <CircularProgress />}
    </>
  );
}
