import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes";
import { CssBaseline } from "@mui/material";
import { themeOptions } from "./theme/theme";
import {
  RoundProvider,
  APIProvider,
  TitleProvider,
  SnackbarProvider,
  IsOnlineProvider,
  LoadingModalProvider,
} from "./hooks";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-au";
import { ServiceWorkerUpdate } from "./components";

export default function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <CssBaseline />
      <IsOnlineProvider>
        <ServiceWorkerUpdate>
          <LoadingModalProvider>
            <SnackbarProvider>
              <APIProvider>
                <RoundProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-au"
                  >
                    <TitleProvider>
                      <RouterProvider router={router} />
                    </TitleProvider>
                  </LocalizationProvider>
                </RoundProvider>
              </APIProvider>
            </SnackbarProvider>
          </LoadingModalProvider>
        </ServiceWorkerUpdate>
      </IsOnlineProvider>
    </ThemeProvider>
  );
}
