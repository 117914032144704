import React, { useEffect, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  DownloadChoiceModal,
  SortableTable,
  UploadModal,
} from "../../../components";
import { Prefix, keyToHeaderMap } from "../../rounds/exportData";
import { useAPI } from "../../../hooks";
dayjs.extend(utc);

const headers = [
  {
    name: "Date Completed",
    fieldName: "dateCompleted",
    sortable: true,
  },
  {
    name: "Average Rating",
    fieldName: "averageRating",
    sortable: true,
  },
  {
    name: "<1 Star Roads",
    fieldName: "lessThanOneStarRoads",
    sortable: true,
  },
  {
    name: "1 Star Roads",
    fieldName: "oneStarRoads",
    sortable: true,
  },
  {
    name: "3 Star Roads",
    fieldName: "threeStarRoads",
    sortable: true,
  },
  {
    name: "5 Star Roads",
    fieldName: "fiveStarRoads",
    sortable: true,
  },
  {
    name: "Roads",
    fieldName: "roads",
    sortable: true,
  },
  {
    name: "Segments",
    fieldName: "segments",
    sortable: true,
  },
  {
    name: "Kms",
    fieldName: "kms",
    sortable: true,
  },
  {
    name: "",
    fieldName: "button",
    sortable: false,
  },
];

export default function AdminViewGov() {
  const { govId } = useParams();
  const { rounds, admin } = useAPI();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [decisionDialog, setDecisionDialog] = useState(false);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    async function getData() {
      const results = await admin.getGovAnalysis(govId);
      if (results.status === 200) {
        setData(results.data);
      }
      setLoading(false);
    }

    getData();
  }, [govId, admin]);

  useEffect(() => {
    if (showSuccessModal) {
      const timeout = setTimeout(() => {
        setShowSuccessModal(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [showSuccessModal]);

  function handleDownloadChoice(selection) {
    if (selection) {
      setShowSuccessModal(true);
    }
    setSessions([]);
    setDecisionDialog(false);
  }

  function prepareData() {
    const flattenProperties = (prefix, source) =>
      Object.entries(keyToHeaderMap).reduce((acc, [prefixedKey, header]) => {
        const [currentPrefix, key] = prefixedKey.split("_");
        if (currentPrefix === prefix && key in source) {
          acc[header] = source[key];
        }
        return acc;
      }, {});
    return sessions?.flatMap((session) => {
      const sessionData = flattenProperties(Prefix.Session, session);
      return session.segments.map((segment) => {
        const segmentData = flattenProperties(Prefix.Segment, segment);
        const segmentAssessmentData = segment.segmentAssessment
          ? flattenProperties(
              Prefix.SegmentAssessment,
              segment.segmentAssessment
            )
          : {};
        return { ...sessionData, ...segmentData, ...segmentAssessmentData };
      });
    });
  }

  async function handleRoundSelect(round) {
    const result = await rounds.getSessionsForRound(round.id);
    setSessions(result.data);
    setDecisionDialog(true);
  }

  function getRating(number) {
    if (number < 1) {
      return "<1 Star";
    }

    if (number < 2) {
      return "1 Star";
    }

    if (number < 4) {
      return "3 Star";
    }

    return "5 Star";
  }

  return (
    <>
      <Typography variant="h4">{data?.name}</Typography>
      <SortableTable
        headers={headers}
        loading={loading}
        rows={data?.rounds?.map((round) => {
          return {
            ...round,
            dateCompleted: round.dateCompleted.includes("0001-01-01")
              ? "--"
              : dayjs.utc(round.dateCompleted).local().format("DD/MM/YYYY"),
            averageRating: getRating(round.averageRating),
            button: (
              <IconButton onClick={() => handleRoundSelect(round)}>
                <DownloadIcon />
              </IconButton>
            ),
          };
        })}
      />
      <UploadModal
        open={showSuccessModal}
        topText={`Outputs Downloaded!`}
        bottomText={"Thank you for keeping WA's roads safe."}
        icon={<CheckCircleIcon style={{ color: "green", fontSize: "50px" }} />}
      />
      <DownloadChoiceModal
        open={decisionDialog}
        text="Would you like to download the outputs (CSV)?"
        yesText="Download"
        noText="Cancel"
        onYes={() => handleDownloadChoice(true)}
        onNo={() => handleDownloadChoice(false)}
        onClose={() => handleDownloadChoice(false)}
        downloadData={prepareData}
        fileName={`Export_${new Date().toISOString()}`}
      />
    </>
  );
}
