export const attributeTreatments = [
  {
    key: "SpeedLimitRating",
    label: "Reduce Speed Limit",
    effectiveness: "High",
  },
  {
    key: "CurvatureRating",
    label: "Improve Safety at Curves",
    effectiveness: "High",
  },
  {
    key: "SkidResistanceRating",
    label: "Improve Skid Resistance",
    effectiveness: "High",
  },
  {
    key: "LaneWidthRating",
    label: "Increase Lane Width",
    effectiveness: "High",
  },
  {
    key: "RoadsideHazardsRating",
    label: "Roadside Hazard Removal",
    effectiveness: "High",
  },
  {
    key: "RoadConditionRating",
    label: "Improve Road Condition",
    effectiveness: "Medium",
  },
  {
    key: "DelineationRating",
    label: "Add/Improve Delineation",
    effectiveness: "Medium",
  },
  {
    key: "LanesRating",
    label: "Add Overtaking Lanes",
    effectiveness: "Medium",
  },
  {
    key: "CarriagewayRating",
    label: "Add Centre Barrier System/Divide Road",
    effectiveness: "High",
  },
  {
    key: "PavedShoulderRating",
    label: "Add Paved Shoulder",
    effectiveness: "Medium",
  },
  {
    key: "RoadsideObjectsRating",
    label: "Move Roadside Objects",
    effectiveness: "High",
  },
  {
    key: "ShoulderRumbleStripsRating",
    label: "Add Shoulder Rumble Strips",
    effectiveness: "Medium",
  },
  {
    key: "CentrelineRumbleStripsRating",
    label: "Add Centreline Rumble Strips",
    effectiveness: "Medium",
  },
  {
    key: "MedianTypeRating",
    label: "Median Type",
    effectiveness: "-",
  },
  {
    key: "PropertyAccessPointsRating",
    label: "Reduce Number of Property Access Points",
    effectiveness: "Low",
  },
  {
    key: "VehicleParkingRating",
    label: "Add On-Street Parking",
    effectiveness: "Low",
  },
  {
    key: "FootpathRating",
    label: "Add Footpath",
    effectiveness: "High",
  },
  {
    key: "BicycleFacilitiesRating",
    label: "Add Bicycle Facilities",
    effectiveness: "Medium",
  },
  {
    key: "StreetLightingRating",
    label: "Add Street Lighting",
    effectiveness: "Medium",
  },
];
