import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAPI, useLoadingModal, useSnackbar } from "../../../../hooks";
import { TextFieldInput } from "../../../../components";
import { useForm } from "react-hook-form";
import DropdownInput from "../../../../components/forms/DropdownInput";

const emailName = "Email";
const regionName = "Region";
const govName = "Government";

export default function NewUserModal({ open, onClose, regions, governments }) {
  const { admin } = useAPI();
  const setLoading = useLoadingModal();
  const showSnackbar = useSnackbar();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  async function onSubmit(form) {
    setLoading(true);
    const results = await admin.createNewUser(form);
    if (results.status === 200) {
      showSnackbar("User created and emailed!", "success");
      onClose(true);
    } else {
      console.log(results.data);
      showSnackbar(
        "Create user failed! See the console log for the error",
        "error"
      );
    }

    setLoading(false);
  }

  const watchRegion = watch(regionName);

  const filteredGovs = governments.filter(
    (g) => watchRegion?.length === 0 || g.region?.includes(watchRegion)
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Stack spacing={2} sx={{ padding: "1em 2em 1em 2em" }}>
        <Typography variant="h5">
          Create User
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Stack spacing={2}>
          <TextFieldInput
            name={emailName}
            label="Email"
            control={control}
            errors={errors}
            required={true}
          />
          <DropdownInput
            name={regionName}
            label="Region"
            control={control}
            errors={errors}
            required={true}
            data={regions}
          />
          <DropdownInput
            name={govName}
            label="Local Government"
            control={control}
            errors={errors}
            required={true}
            data={filteredGovs.map((g) => g.name)}
          />
        </Stack>
        <Stack>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto", padding: "0.5em 2em", marginTop: "2em" }}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Create User
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
