import {
  driveCapacity,
  fundingLevel,
  goodName,
  highName,
  levelSafetyRate,
  moderateName,
  requiredAccreditation,
  timeCommitment,
  trainingCompleted,
  vehicleAvailable,
  veryGoodName,
  formResultsToolName,
} from "./formNames";

//table give is:
/*
IRAP	      ANRAM	      IRR	      Road Stereotype	 Road Safety Ratings Tool
Very Good	  Very Good	  Good	    Good	           Acceptable
Yes	        Yes	        No	      Either	         No
Yes	        No	        No	      No	             No
High	      High	      Moderate  Moderate	       Low
High	      High	      Moderate  Low	             Low
Yes	        No	        No	      No	             No
Yes	        No	        No	      No	             No
*/
//I figure 'No' doesn't need to be checked, so long as these are checked in order (IRAP -> ANRAM, etc).

const trueName = "true";

function isIRAP(form) {
  return (
    form[levelSafetyRate] === veryGoodName &&
    form[trainingCompleted] === trueName &&
    form[requiredAccreditation] === trueName &&
    form[timeCommitment] === highName &&
    form[fundingLevel] === highName &&
    form[vehicleAvailable] === trueName &&
    form[driveCapacity] === trueName
  );
}

function isANRAM(form) {
  return (
    form[levelSafetyRate] === veryGoodName &&
    form[trainingCompleted] === trueName &&
    form[timeCommitment] === highName &&
    form[fundingLevel] === highName
  );
}

function isIRR(form) {
  return (
    (form[levelSafetyRate] === veryGoodName ||
      form[levelSafetyRate] === goodName) &&
    (form[timeCommitment] === highName ||
      form[timeCommitment] === moderateName) &&
    (form[fundingLevel] === highName || form[fundingLevel] === moderateName)
  );
}

function isRoadStereotype(form) {
  return (
    (form[levelSafetyRate] === veryGoodName ||
      form[levelSafetyRate] === goodName) &&
    (form[timeCommitment] === highName || form[timeCommitment] === moderateName)
  );
}

export function calculateResourceToUse(form) {
  if (isIRAP(form)) {
    return {
      name: "IRAP",
      url: "https://irap.org/rap-tools/infrastructure-ratings/star-ratings/",
    };
  }

  if (isANRAM(form)) {
    return { name: "ANRAM", url: "https://www.arrb.com.au/anram" };
  }

  if (isIRR(form)) {
    return {
      name: "IRR",
      url: "https://austroads.com.au/publications/road-safety/ap-r587a-19",
    };
  }

  if (isRoadStereotype(form)) {
    return {
      name: "Network Design for Road Safety User Guide",
      url: "https://austroads.com.au/publications/road-design/ap-r619-20",
    };
  }

  return { name: formResultsToolName, url: "" };
}
