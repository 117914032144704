import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { PasswordFieldInput, TextFieldInput } from "../../../components";
import { passwordName, userName } from "../common/formNames";

export default function LoginCard({
  onLogin,
  onForgotPassword,
  setShowSignUp,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onUserKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // e.preventDefault();
      handleSubmit(onLogin)();
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h1">Log In</Typography>
      <TextFieldInput
        name={userName}
        label="Email"
        control={control}
        errors={errors}
        required={true}
      />
      <PasswordFieldInput
        name={passwordName}
        label="Password"
        control={control}
        errors={errors}
        onForgotPassword={onForgotPassword}
        onKeyPress={onUserKeyPress}
      />
      <Stack spacing={1} sx={{ paddingTop: "0.5em" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleSubmit(onLogin)()}
          sx={{ padding: "1em 0" }}
        >
          Log In
        </Button>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography variant="body2">Don't have an account? </Typography>
          <Button
            color="secondary"
            onClick={() => setShowSignUp(true)}
            sx={{ textTransform: "none", alignItems: "center" }}
          >
            Sign up.
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
