import React, { useEffect, useState } from "react";
import { useAPI } from "../../../hooks";
import { Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { SortableTable } from "../../../components";

const headers = [
  {
    name: "Region",
    fieldName: "region",
    sortable: true,
  },
  {
    name: "Local Governments",
    fieldName: "totalGovernments",
    sortable: true,
  },
  {
    name: "Road Network Completion (%)",
    fieldName: "roadNetworkCompletedPercent",
    sortable: true,
  },
  {
    name: "",
    fieldName: "button",
    sortable: false,
  },
];

export default function AdminRegions() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { admin } = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const results = await admin.getRegions();
      if (results.status === 200) {
        setData(results.data);
      }
      setLoading(false);
    }

    getData();
  }, [admin]);

  return (
    <>
      <Typography variant="h4">Manage Regions</Typography>
      <SortableTable
        headers={headers}
        loading={loading}
        rows={data.map((row) => {
          return {
            id: row.region,
            button: (
              <Button
                onClick={() => navigate(row.region)}
                endIcon={<ArrowForwardIosIcon />}
              >
                View Region
              </Button>
            ),
            ...row,
          };
        })}
      />
    </>
  );
}
