import React, { useState } from "react";

import {
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

import { SegmentDataView } from "./components";
import SummaryStarRating from "./components/SummaryStarRating";
import { editSegment, enteredSegment } from "../common/journeyType";
import {
  LoadedJourneyModal,
  ReversedChoiceModal,
  TopSection,
  UploadModal,
} from "../../../../components";
import {
  calculateFinalRating,
  extractRating,
  tallyRatings,
} from "../../../../helpers";

export default function SegmentSummary({
  data,
  onSubmit,
  onNewSegment,
  onFinaliseSegment,
  journeyType,
  onChooseSegment,
  onNextSegment,
  isNextSegment,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function handleAddToSession() {
    onSubmit("Add", {
      finalRating: finalRating.value,
      ratingBreakdown: talliedRatings,
    });
    setShowModal(true);
  }

  function handleNewSegment() {
    setShowModal(false);
    onNewSegment();
  }

  function handleUpdateSegment() {
    onSubmit("Add", {
      finalRating: finalRating.value,
      ratingBreakdown: talliedRatings,
    });
    setShowSuccessModal(true);
    const timeout = setTimeout(() => {
      navigate("/previous");
      setShowSuccessModal(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }

  const extractedRatings = extractRating(data);
  const talliedRatings = tallyRatings(extractedRatings);
  const finalRating = calculateFinalRating(extractedRatings);

  return (
    <Stack
      sx={{
        flexDirection: "column",
        minHeight: "calc(100vh - 115px)",
      }}
    >
      <TopSection>
        <Stack spacing={2}>
          <Stack direction="row">
            <Typography variant="h5">Segment Summary</Typography>
          </Stack>
          <SegmentDataView data={data} />
        </Stack>
      </TopSection>
      <Stack spacing={2} sx={{ flex: "1 0 auto", padding: "1em 0" }}>
        <SummaryStarRating
          rating={finalRating}
          isZeroStars={false}
          isHeaderRating={true}
        />
        <Divider></Divider>
        <Typography variant="h3">Road Attribute Ratings: </Typography>
        {extractedRatings.map((rating) => {
          return (
            <SummaryStarRating
              key={rating.name}
              rating={rating}
              isZeroStars={rating.hasLessThanSign}
            />
          );
        })}
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{
          borderTop: "1px solid lightgrey",
          padding: "1em 0",
          justifyContent: isMobile ? "center" : "flex-end",
        }}
      >
        <Button variant="outlined" onClick={() => onSubmit("Edit")}>
          Edit Ratings
        </Button>
        {journeyType === editSegment ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleUpdateSegment()}
          >
            Update Segment
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleAddToSession()}
          >
            Add to Session Summary
          </Button>
        )}
      </Stack>
      {journeyType === enteredSegment ? (
        <ReversedChoiceModal
          open={showModal}
          titleText="Segment Added!"
          text="Would you like to add a new segment?"
          yesText="new segment"
          noText="finalise work"
          onYes={handleNewSegment}
          onNo={onFinaliseSegment}
          onClose={() => setShowModal(false)}
        />
      ) : (
        <LoadedJourneyModal
          open={showModal}
          onChoose={onChooseSegment}
          onNext={onNextSegment}
          isNextSegment={isNextSegment}
          onFinish={onFinaliseSegment}
          onClose={() => setShowModal(false)}
        />
      )}
      <UploadModal
        open={showSuccessModal}
        topText={`Segment Updated!`}
        icon={<CheckCircleIcon style={{ color: "green", fontSize: "50px" }} />}
      />
    </Stack>
  );
}
