import React, { useState } from "react";

import { Stack, Typography, Switch } from "@mui/material";
import { sortableTableHeaders } from "../../sessions/headerData";
import { detailedSegmentTableHeaders } from "../../segments/segmentTableData";
import SegmentsTable from "../../segments/components/SegmentsTable";
import { SortableSessionTable } from "../../../components";

export default function SummaryToggle({ sessions, segments }) {
  const [isSegmentPage, setIsSegmentPage] = useState(false);

  const handleToggleChange = () => {
    setIsSegmentPage((prev) => !prev);
  };

  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">
          {isSegmentPage ? "Segments" : "Sessions"}
        </Typography>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Switch
            checked={isSegmentPage}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "Toggle switch" }}
          />
          <Typography align="right">
            {isSegmentPage ? "Show Segments" : "Show Sessions"}
          </Typography>
        </Stack>
      </Stack>
      {isSegmentPage ? (
        <SegmentsTable
          headers={detailedSegmentTableHeaders}
          rows={segments}
          canDelete={false}
          includeRatings={true}
        />
      ) : (
        <SortableSessionTable
          headers={sortableTableHeaders}
          rows={sessions}
        ></SortableSessionTable>
      )}
    </Stack>
  );
}
