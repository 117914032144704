import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useCSVDownloader } from "react-papaparse";

export default function ChoiceModal({
  open,
  text,
  yesText,
  noText,
  onYes,
  onNo,
  onClose,
  downloadData,
  fileName,
}) {
  const { CSVDownloader } = useCSVDownloader();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Stack spacing={2} sx={{ padding: "2em 2em 1em 2em" }}>
        <Typography>{text}</Typography>
        <Stack direction="row">
          <Stack sx={{ flex: 1, alignItems: "start" }}>
            <Button
              variant="text"
              sx={{ width: "auto", padding: "0.5em 2em" }}
              onClick={onNo}
            >
              {noText}
            </Button>
          </Stack>
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <CSVDownloader bom filename={fileName} data={downloadData}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "auto", padding: "0.5em 2em" }}
                onClick={onYes}
              >
                {yesText}
              </Button>
            </CSVDownloader>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
