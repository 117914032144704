import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { NewPasswordInput, TextFieldInput } from "../../../components";
import {
  confirmPasswordName,
  passwordName,
  userName,
} from "../common/formNames";

export default function SignUpCard({ onSignUp, setShowSignUp }) {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchPassword = watch(passwordName);
  const watchConfirmPassword = watch(confirmPasswordName);

  return (
    <Stack spacing={2}>
      <Typography variant="h1">Sign Up</Typography>
      <Typography color={theme.palette.text.secondary}>
        Create an account using your Local Government email address.
      </Typography>
      <TextFieldInput
        name={userName}
        label="Email"
        control={control}
        errors={errors}
        required={true}
      />
      <NewPasswordInput
        watchPassword={watchPassword}
        watchConfirmPassword={watchConfirmPassword}
        setError={setError}
        clearErrors={clearErrors}
        control={control}
        errors={errors}
        confirmPasswordName={confirmPasswordName}
        passwordName={passwordName}
      />
      <Stack spacing={1} sx={{ paddingTop: "0.5em" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleSubmit(onSignUp)()}
          disabled={Object.entries(errors).length > 0}
          sx={{ padding: "1em 0" }}
        >
          Create Account
        </Button>
        <Button
          onClick={() => setShowSignUp(false)}
          sx={{ textTransform: "none", alignItems: "center" }}
        >
          Back to Log In
        </Button>
      </Stack>
      <Divider />
      <Stack>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Don’t have a Local Government email?
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          <Link href="mailto:roadwise@walga.asn.au">Contact WALGA</Link> to help
          create your account.
        </Typography>
      </Stack>
    </Stack>
  );
}
