import { getRequest } from "./requests";

export default function roadNetworkData(defaultHeader, isOnline) {
  async function search(searchText) {
    if (isOnline) {
      const results = await getRequest(
        `roadNetwork?search=${searchText}`,
        defaultHeader
      );

      return results;
    } else {
      return { status: 400 };
    }
  }

  return { search };
}
