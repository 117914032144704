import {
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function Instructions({
  setStartQuestionnaire,
  setCreateSessionOpen,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="h5">
        Take the Capacity & Resource Assessment
      </Typography>
      <Stack spacing={4}>
        <Typography>
          This tool has been developed for the purpose of enabling Local
          Governments with limited capacity for road safety assessments to
          assess the risk rating of their local network in an efficient and
          validated manner. The Capacity Questionnaire lists the operational
          requirements for conducting a road safety assessment. It can be used
          to establish a Local Government’s level of capacity to conduct a
          safety assessment of the road network.
        </Typography>
        <Typography>
          Please select a response for each operational requirement to determine
          which, if any, of these tools may be suitable for your Local
          Government.
        </Typography>
        <Typography>
          If your responses match with the requirements for a specific risk
          rating tool (iRAP/ AusRAP, ANRAM, IRR, or Road Stereotype), your Local
          Government may already have the capacity to assess the road safety
          risk rating of your road network using one of these tools. However, if
          your responses do not match or if you selected NO/LOW or MODERATE for
          any of the operational requirements for each of the tools, then this
          tool may be of assistance in assessing the safety of your Local
          Government’s road network.
        </Typography>
        <Typography>
          More information on the operational requirements for these tools can
          be found in the Road Safety Ratings for Local Government Roads Project
          Reference Document under the “Literature Review” heading.
        </Typography>
      </Stack>

      <Divider />
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{ flex: 1, justifyContent: "end" }}
      >
        <Button
          variant="outlined"
          sx={{ width: "auto", padding: "0.5em 2em" }}
          onClick={() => setCreateSessionOpen(true)}
        >
          Continue with the road safety tool
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "auto", padding: "0.5em 2em" }}
          onClick={() => setStartQuestionnaire(true)}
        >
          Start the assessment
        </Button>
      </Stack>
    </>
  );
}
