import { Dialog, Divider, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ChecklistInformationModal({
  open,
  onClose,
  title,
  upperBody,
  lowerBody,
}) {
  if (!lowerBody) {
    return;
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Stack spacing={2} sx={{ padding: "1em 2em 1em 2em" }}>
        <Typography variant="h5">
          {title}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Stack>
          <Typography>{upperBody}</Typography>
        </Stack>
        <Divider></Divider>
        <Stack>
          {lowerBody.map((item, index) => (
            <Typography key={index}>{item}</Typography>
          ))}
        </Stack>
      </Stack>
    </Dialog>
  );
}
