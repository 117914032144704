import React from "react";
import { Box, Divider, Typography } from "@mui/material";

export default function Content({
  title,
  children,
  headerType = "h3",
  endDivider = true,
}) {
  return (
    <>
      {title && <Typography variant={headerType}>{title}</Typography>}
      {headerType === "h2" && (
        <Divider sx={{ paddingTop: 1 }} orientation="horizontal" />
      )}
      {children && <Box>{children}</Box>}
      {endDivider && (
        <Divider sx={{ paddingTop: 1 }} orientation="horizontal" />
      )}
    </>
  );
}
