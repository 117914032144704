import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
export default function CrossSectionImages({ roadType }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const identifier = roadType.slice(-1);
    const imagesContext = require.context(
      "../../../images/crosssectionimages",
      true,
      /\.jpg$/
    );

    setImages([
      {
        src: imagesContext(`./${roadType}/zero${identifier}.jpg`),
        color: "#ffffff",
        stars: 0,
      },
      {
        src: imagesContext(`./${roadType}/one${identifier}.jpg`),
        color: "#000000",
        stars: 1,
      },
      {
        src: imagesContext(`./${roadType}/three${identifier}.jpg`),
        color: "#ffe100",
        stars: 3,
      },
      {
        src: imagesContext(`./${roadType}/five${identifier}.jpg`),
        color: "#28c900",
        stars: 5,
      },
    ]);
  }, [roadType]);

  const StarDisplay = ({ numberOfStars, color }) => (
    <Box position="absolute" top={5} left={5} zIndex={1}>
      {[...Array(numberOfStars)].map((star, starIndex) => (
        <StarIcon
          key={starIndex}
          fontSize="small"
          style={{
            color: color,
          }}
        />
      ))}
    </Box>
  );

  return (
    <Stack
      spacing={2}
      sx={{
        padding: "1em",
        width: "55em",
      }}
    >
      {images.map((image, index) => (
        <Stack
          key={index}
          position="relative"
          style={{
            border: "1px solid black",
            width: "602px",
            height: "440px",
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StarDisplay numberOfStars={image.stars} color={image.color} />
          <img src={image.src} alt={"Cross-Section"} />
        </Stack>
      ))}
    </Stack>
  );
}
