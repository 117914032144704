import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function OverviewCard({ title, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        padding: "0.5em 0.5em 2em",
        margin: "0.5em",
        width: isMobile ? "10rem" : "30rem",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.text.secondary,
          fontSize: isMobile
            ? theme.components.OverviewCard.fontSize.xsmall
            : null,
        }}
      >
        {title}
      </Typography>
      <Stack
        sx={{
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Paper>
  );
}
