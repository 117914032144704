import React, { useEffect, useState } from "react";

import { Stack, Typography, Button, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { segmentTableHeaders } from "../../segments/segmentTableData";
import SegmentsTable from "../../segments/components/SegmentsTable";
import { sessionSummary } from "../../segments/forms/common/journeyNames";
import { editSegment } from "../../segments/forms/common/journeyType";
import { useAPI } from "../../../hooks";

export default function EditSession() {
  const [segmentData, setSegmentData] = useState([]);
  const { id } = useParams();
  const { segments } = useAPI();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSegments = async () => {
      const result = await segments.getSegmentsForSession(id);
      setSegmentData(result.data);
    };
    fetchSegments();
  }, [id, segments]);

  function handleFinaliseSession() {
    navigate(`/session/${id}/segment/${segmentData[0].id}`, {
      state: {
        journeyType: editSegment,
        startingPoint: sessionSummary,
      },
    });
  }

  async function handleAddSegment() {
    const results = await segments.post({ sessionId: id });
    const segment = results.data;
    navigate(`/session/${id}/segment/${segment.id}`);
  }

  return (
    <Stack spacing={4}>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        justifyContent="space-between"
        sx={{ textAlign: "center" }}
      >
        <Typography variant="h2">Session Segments</Typography>
        <Button
          variant="outlined"
          sx={{ width: "auto" }}
          onClick={() => handleAddSegment(true)}
        >
          Add Segment
        </Button>
      </Stack>
      <SegmentsTable
        headers={segmentTableHeaders}
        rows={segmentData}
        isClickable={true}
        canDelete={false}
        selectedJourneyType={editSegment}
      />
      <Stack sx={{ flex: 1, alignItems: "end" }}>
        <Button
          variant="contained"
          color="secondary"
          disabled={!segmentData.length}
          sx={{ width: "auto" }}
          onClick={() => handleFinaliseSession()}
        >
          Finalise Session
        </Button>
      </Stack>
    </Stack>
  );
}
