import React, { useState } from "react";

import { IconButton, Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import Dropdown from "./Dropdown";
import { roadAttributeData } from "../../common/roadAttributeData";
import { ChecklistInformationModal } from "../../../../../components";

export default function ChecklistDropdown({
  label,
  data,
  choice,
  onSelect,
  attributeName,
  readOnly = false,
}) {
  const [open, setOpen] = useState(false);

  function handleIconClick() {
    setOpen(!open);
  }

  return (
    <Stack direction="row" sx={{ flex: 1, alignItems: "center" }}>
      <Dropdown
        disabled={readOnly}
        label={label}
        data={data}
        choice={choice}
        onSelect={onSelect}
      />
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "1.5em",
          width: "3em",
          height: "3em",
        }}
      >
        <IconButton onClick={handleIconClick}>
          <InfoIcon color="action" />
        </IconButton>
        <ChecklistInformationModal
          open={open}
          onClose={handleIconClick}
          title={label}
          upperBody={
            "The road attribute definitions in below are descriptions used in the iRAP Vida Demonstrator."
          }
          lowerBody={roadAttributeData[attributeName]}
        />
      </Stack>
    </Stack>
  );
}
