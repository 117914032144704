import React, { useEffect, useState } from "react";

import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";

import { SegmentDataView } from "./components";
import { TopSection } from "../../../../components";
import ChecklistDropdown from "./components/ChecklistDropdown";
import roadChecklistData from "../common/dropdowndata/roadChecklistData";
import CrossSectionModal from "../../components/CrossSectionModal";

export default function RoadSafetyChecklist({ data, onSubmit }) {
  const theme = useTheme();
  const [openCrossSection, setOpenCrossSection] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [roadType, setRoadType] = useState({
    title: "",
    subTitle: "",
    dropdownData: [],
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const selectedRoadType =
      roadChecklistData.find((item) => item["Road Type"] === data.roadType) ||
      {};
    const attributeData = selectedRoadType.attributes
      ? Object.values(selectedRoadType.attributes)
      : [];

    const commaIndex = data.roadType.indexOf(",");
    const firstWord = data.roadType.slice(0, commaIndex).trim();
    const remainingText = data.roadType.slice(commaIndex + 1).trim();

    setRoadType({
      title: firstWord,
      subTitle: remainingText.charAt(0).toUpperCase() + remainingText.slice(1),
      dropdownData: attributeData,
    });

    attributeData.forEach((item) => {
      if (item.readOnly && item.data.length === 1) {
        setValue(item.name, item.data[0]);
      } else if (data.segmentAssessment && data.segmentAssessment[item.name]) {
        setValue(item.name, data.segmentAssessment[item.name]);
      }
    });
  }, [data.roadType, data.segmentAssessment, setValue]);

  return (
    <>
      <TopSection>
        <Stack spacing={2}>
          <Typography variant="h5">Road Safety Ratings Checklist</Typography>
          <SegmentDataView data={data} />
          <Typography variant="h3">{roadType.title}</Typography>
          <Typography>{roadType.subTitle}</Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{ width: "auto", marginTop: "1em" }}
          onClick={() => setOpenCrossSection(!openCrossSection)}
        >
          Show cross-section
        </Button>
      </TopSection>

      <Stack spacing={2}>
        <Typography variant="h3">Road Attribute Ratings</Typography>
        <Stack
          direction="column"
          spacing={2}
          sx={{ justifyContent: "space-between", paddingBottom: "5em" }}
        >
          {roadType.dropdownData.map((item, index) => (
            <React.Fragment key={index}>
              <Controller
                name={item.name}
                control={control}
                defaultValue={
                  item.readOnly && item.data.length === 1
                    ? item.data[0]
                    : data.segmentAssessment &&
                      data.segmentAssessment[item.name]
                    ? data.segmentAssessment[item.name]
                    : ""
                }
                rules={{ required: item.rulesMessage }}
                render={({ field }) => (
                  <ChecklistDropdown
                    label={item.label}
                    data={item.data}
                    choice={field.value}
                    readOnly={item.readOnly}
                    attributeName={item.name}
                    onSelect={field.onChange}
                  />
                )}
              />
              {errors[item.name] && (
                <Typography color="red">{errors[item.name].message}</Typography>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid lightgrey",
          backgroundColor: theme.palette.primary.contrastText,
          color: "white",
          textAlign: "center",
          padding: "1em 0",
          zIndex: 1,
          justifyContent: isMobile ? "center" : "flex-end",
          alignItems: isMobile ? "center" : "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginRight: isMobile ? "0" : "3em" }}
          onClick={handleSubmit(onSubmit)}
        >
          Submit Ratings
        </Button>
        <CrossSectionModal
          open={openCrossSection}
          onClose={() => setOpenCrossSection(false)}
          selectedRoadType={data.roadType}
        />
      </Stack>
    </>
  );
}
