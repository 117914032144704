import React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

export default function PasswordFieldInput({
  name,
  label,
  control,
  errors,
  onForgotPassword,
  onKeyPress,
}) {
  const theme = useTheme();

  return (
    <Stack sx={{ width: "100%" }}>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            label={label ?? name}
            variant="filled"
            type="password"
            onKeyDown={onKeyPress}
            InputLabelProps={{ shrink: true }}
            {...field}
          />
        )}
      />
      {onForgotPassword && (
        <Button
          onClick={onForgotPassword}
          sx={{
            textTransform: "none",
            fontWeight: 300,
            justifyContent: "start",
            padding: "0.25em 0",
          }}
        >
          Forgot your password?
        </Button>
      )}

      {errors[name] && (
        <Typography color={theme.palette.error.main}>
          {errors[name].message}
        </Typography>
      )}
    </Stack>
  );
}
