/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BasicSegmentDetails, SelectRoadType, SelectSLK } from "./components";
import { useAPI, useTitle } from "../../../hooks";
import {
  basicDetails,
  roadRatings,
  selectRoadType,
  selectSLK,
  segmentSummary,
  sessionSummary,
  roundSummary,
} from "./common/journeyNames";
import { loadedSegment, enteredSegment } from "./common/journeyType";
import {
  customIdName,
  dateAssessedName,
  fromSLKName,
  notesName,
  roadName,
  toSLKName,
  uniqueIdName,
} from "./common/formNames";
import dayjs from "dayjs";
import RoadSafetyChecklist from "./components/RoadSafetyChecklist";
import SegmentSummary from "./components/SegmentSummary";
import SessionSummary from "../../sessions/SessionSummary";
import RoundSummary from "../../rounds/RoundSummary";
import { addStarRatings } from "../../../helpers";

export default function SegmentForms() {
  const { id, segmentId, stage } = useParams();
  const [segment, setSegment] = useState(null);
  const [journeyType, setJourneyType] = useState(enteredSegment);
  const [journeyStage, setJourneyStage] = useState(stage ?? basicDetails);
  const { setTitle } = useTitle();
  const { segments, sessions } = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const [remainingSegments, setRemainingSegments] = useState(
    location.state?.loadedSegmentIds
  );

  useEffect(() => {
    async function getSegment() {
      setSegment(null);
      const results = await segments.getSingle(segmentId);
      setJourneyType(location.state?.journeyType || enteredSegment);
      setJourneyStage(location.state?.startingPoint || stage || basicDetails);
      setSegment(results.data);
    }

    if (!segment && segmentId) {
      getSegment();
    }
  }, [segmentId, segments]);

  useEffect(() => {
    switch (journeyStage) {
      case roundSummary:
        setTitle("Round Summary");
        break;
      case sessionSummary:
        setTitle("Session Summary");
        break;
      default:
        setTitle(
          segment?.roadName
            ? `${segment.roadName} - ${dayjs(segment.dateAssessed).format(
                "DD/MM/YYYY"
              )}`
            : "New Segment"
        );
    }
  }, [setTitle, journeyStage]);

  async function saveSegment(form) {
    try {
      var result = await segments.update(form);
      if (result.data) {
        const data = result.data;
        setSegment(data);
        setTitle(
          `${data.roadName} - ${dayjs(data.dateAssessed).format("DD/MM/YYYY")}`
        );
      }
    } catch (error) {
      console.log("Unable to save Segment!");
      console.log(error);
    }
  }

  function handleResetJourney(title, segment) {
    setJourneyStage(basicDetails);
    setTitle(title);
    setSegment(segment);
  }

  async function onBasicSubmit(form) {
    const clone = structuredClone(segment);
    clone[customIdName] = form[customIdName];
    clone[dateAssessedName] = form[dateAssessedName];
    clone[roadName] = form[roadName];
    clone[uniqueIdName] = form[uniqueIdName];
    await saveSegment(clone);
    setJourneyStage(selectSLK);
    navigate(`/session/${id}/segment/${segmentId}/${selectSLK}`);
  }

  async function onSelectSLKSubmit(form) {
    const clone = structuredClone(segment);
    clone[fromSLKName] = form[fromSLKName];
    clone[notesName] = form[notesName];
    clone[toSLKName] = form[toSLKName];
    await saveSegment(clone);
    setJourneyStage(selectRoadType);
    navigate(`/session/${id}/segment/${segmentId}/${selectRoadType}`);
  }

  async function onSelectRoadTypeSubmit(roadType) {
    const clone = structuredClone(segment);
    clone.roadType = roadType;
    await saveSegment(clone);
    setJourneyStage(roadRatings);
    navigate(`/session/${id}/segment/${segmentId}/${roadRatings}`);
  }

  async function onSelectRatingsSubmit(data) {
    addStarRatings(data);
    const clone = structuredClone(segment);
    clone.segmentAssessment = { segmentId: segment.id, ...data };
    await saveSegment(clone);
    setJourneyStage(segmentSummary);
    navigate(`/session/${id}/segment/${segmentId}/${segmentSummary}`);
  }

  async function onSummarySubmit(buttonId, starRatings) {
    if (buttonId === "Edit") {
      setJourneyStage(roadRatings);
      navigate(`/session/${id}/segment/${segmentId}/${roadRatings}`);
    }
    if (buttonId === "Add") {
      if (remainingSegments) {
        const remainingSegmentsAfterAdd = remainingSegments.filter(
          (segId) => segId !== segment.id
        );
        setRemainingSegments(remainingSegmentsAfterAdd);
      }
      const clone = structuredClone(segment);
      clone.segmentAssessment = {
        ...clone.segmentAssessment,
        ...starRatings.ratingBreakdown,
        finalStarRating: starRatings.finalRating,
      };
      await saveSegment(clone);
    }
  }

  async function onNewSegment() {
    const results = await segments.post({ sessionId: id });
    navigate(`/session/${id}/segment/${results.data.id}`);
    handleResetJourney("New Segment", results.data);
  }

  async function onChooseSegment() {
    navigate(`/session/${id}/loadedsegments`, {
      state: {
        journeyType: loadedSegment,
        loadedSegmentIds: location.state.loadedSegmentIds,
      },
    });
  }

  async function onNextSegment() {
    if (remainingSegments.length >= 1) {
      const nextSegmentId = remainingSegments[1];
      const nextRemainingSegments = remainingSegments.slice(1);
      let loadedSegmentState = {
        journeyType: loadedSegment,
        loadedSegmentIds: location.state.loadedSegmentIds,
      };

      setRemainingSegments(nextRemainingSegments);
      if (remainingSegments.length === 1) {
        const results = await segments.getSingle(remainingSegments[0]);
        handleResetJourney(
          `${results.data.roadName} - ${dayjs(results.data.dateAssessed).format(
            "DD/MM/YYYY"
          )}`,
          results.data
        );
        navigate(`/session/${id}/segment/${results.data.id}`, {
          state: loadedSegmentState,
        });
      } else {
        navigate(`/session/${id}/segment/${nextSegmentId}`, {
          state: loadedSegmentState,
        });
        const results = await segments.getSingle(nextSegmentId);
        handleResetJourney("New Segment", results.data);
      }
    }
  }

  function onFinaliseSegment() {
    setJourneyStage(sessionSummary);
  }

  async function onFinaliseSession() {
    setJourneyStage(roundSummary);
    const session = await sessions.getSingle(id);
    const clone = structuredClone(session.data);
    clone.isFinished = true;
    sessions.update(clone);
  }

  function onFinaliseRound() {
    navigate(`/`);
  }

  return segment ? (
    journeyStage === basicDetails ? (
      <BasicSegmentDetails data={segment} onSubmit={onBasicSubmit} />
    ) : journeyStage === selectSLK ? (
      <SelectSLK data={segment} onSubmit={onSelectSLKSubmit} />
    ) : journeyStage === selectRoadType ? (
      <SelectRoadType data={segment} onSubmit={onSelectRoadTypeSubmit} />
    ) : journeyStage === roadRatings ? (
      <RoadSafetyChecklist data={segment} onSubmit={onSelectRatingsSubmit} />
    ) : journeyStage === segmentSummary ? (
      <SegmentSummary
        data={segment}
        onSubmit={onSummarySubmit}
        onNewSegment={onNewSegment}
        onFinaliseSegment={onFinaliseSegment}
        onChooseSegment={onChooseSegment}
        onNextSegment={onNextSegment}
        isNextSegment={remainingSegments?.length >= 1}
        journeyType={journeyType}
      />
    ) : journeyStage === sessionSummary ? (
      <SessionSummary onFinaliseSession={onFinaliseSession} />
    ) : journeyStage === roundSummary ? (
      <RoundSummary onFinaliseRound={onFinaliseRound} />
    ) : (
      <CircularProgress />
    )
  ) : (
    <CircularProgress />
  );
}
