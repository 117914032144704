import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";

export default function FirstTimeModal({ open, onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog open={open} maxWidth="md">
      <Stack spacing={2} sx={{ padding: "1em 2em 2em 2em" }}>
        <Stack direction="row" alignItems="center">
          <Stack sx={{ flex: 1 }}>
            <Typography variant={isMobile ? "h6" : "h5"}>
              Welcome to LG Stars
            </Typography>
          </Stack>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Typography>
            This very brief guide will help familiarise you with the app.
          </Typography>
          <Typography>
            Work in the app is organised into “sessions” and “rounds”. Multiple
            people can work to assess the safety of the Local Government road
            network in separate sessions. Once everyone is finished and all of
            the analysis that is proposed is complete, the round can be
            finalised.
          </Typography>
          <Typography>
            To start, click the{" "}
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "auto" }}
            >
              Start New Session
            </Button>{" "}
            button.
          </Typography>
          <Typography>
            If you haven`t taken the Capacity Questionnaire, consider spending 5
            minutes to get a sense of whether this tool is the best tool for
            your Local Government.
          </Typography>
          <Typography>
            Once you have finished inputting data as part of a session, click
            the{" "}
            <Button
              variant="outlined"
              startIcon={<HomeIcon />}
              sx={{
                width: "auto",
                textTransform: "none",
                color: "rgba(0, 0, 0, 0.54)",
                margin: isMobile ? "0.5em 0" : 0,
              }}
            >
              Dashboard
            </Button>{" "}
            item in the side menu{" "}
            <Button variant="contained">
              <MenuIcon sx={{ color: theme.palette.primary.contrastText }} />
            </Button>{" "}
            to return home and see an overview of the round and the session. If you`ve done all
            of the analysis you plan to do for the year, you can click{" "}
            <Button
              variant="outlined"
              sx={isMobile ? { fontSize: "12px" } : {}}
            >
              Finalise Round of Analysis
            </Button>
            .
          </Typography>
          <Typography>
            The hamburger icon{" "}
            <Button variant="contained">
              <MenuIcon sx={{ color: theme.palette.primary.contrastText }} />
            </Button>{" "}
            gives access to the side menu and its options. The “How to use the tool” option
            provides some helpful guidance on defining and inputting segments,
            while “Links and Resources” provides useful links to other tools and
            to the paper version of the LG Stars tool.{" "}
            <b>
              The paper version may be helpful in referencing the cross-section
              diagrams
            </b>, which can be downloaded on the Links and Resources page.
          </Typography>
          <Typography>
            The “Notes” section also provides helpful information on defining
            segments and inputting data.
          </Typography>
          <Typography>Thanks for using the LG Stars Application!</Typography>
          <Typography>
            If you have any questions or would like to submit feedback, please
            send an email to{" "}
            <Link href="mailto:infrastructure@walga.asn.au ">
              infrastructure@walga.asn.au{" "}
            </Link>
            .
          </Typography>
        </Stack>
        <Divider></Divider>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: "auto", padding: "0.5em 2em" }}
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
