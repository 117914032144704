import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIsOnline, useTreatments } from "../../hooks";

export default function Treatment() {
  const treatments = useTreatments(5);
  const isOnline = useIsOnline();
  const theme = useTheme();

  return (
    <Stack>
      <Typography variant="h5" sx={{ marginTop: "1em" }}>
        Treatment Advice
      </Typography>
      {isOnline ? (
        <>
          <Typography sx={{ marginTop: "0.2em", marginBottom: "0.2em" }}>
            Based on the data for your Local Government, the following possible
            treatment option(s) could be considered in developing a works
            program.
          </Typography>

          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    Potential Treatment
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    Effectiveness
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {treatments.map((treatment, key) => (
                  <TableRow key={key}>
                    <TableCell align="left">{treatment?.label}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color:
                          treatment.effectiveness === "High"
                            ? theme.components.Effectiveness.high
                            : treatment.effectiveness === "Medium"
                            ? theme.components.Effectiveness.medium
                            : theme.components.Effectiveness.low,
                      }}
                    >
                      {treatment?.effectiveness}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ marginTop: "1em" }}>
            Please note, the potential treatments provide a rough indication of
            which types of treatments are likely to have the most impact.
          </Typography>
        </>
      ) : (
        <Typography sx={{ paddingTop: "1em" }}>
          Cannot determine treatments in offline mode
        </Typography>
      )}
    </Stack>
  );
}
