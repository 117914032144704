import React from "react";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { otherRiskRatingData, paperToolData } from "./resourceData";
import { Content, ContentWithLinkIcon } from "../../components";

export default function ResourcesPage() {
  const theme = useTheme();

  return (
    <>
      <Stack spacing={2}>
        <Content title={"Paper Tool"} headerType={"h2"} endDivider={false} />
        {paperToolData.map((item, index) => {
          return (
            <ContentWithLinkIcon
              key={index}
              title={item.title}
              headerType={"resourcePageSubHeader"}
              icon={
                item.icon === "DownloadIcon" ? (
                  <DownloadIcon sx={{ color: theme.palette.primary.main }} />
                ) : null
              }
              href={item.href}
            />
          );
        })}
      </Stack>
      <Stack spacing={2}>
        <Box sx={{ marginTop: "1em" }}>
          <Content
            title={"Other Risk Rating Tools"}
            headerType={"h2"}
            endDivider={false}
          />
        </Box>

        {otherRiskRatingData.map((item, index) => (
          <ContentWithLinkIcon
            key={index}
            title={item.title}
            headerType={"h3"}
            icon={<OpenInNewIcon sx={{ color: theme.palette.primary.main }} />}
            href={item.href}
            body={item.body}
            divider={true}
          />
        ))}
      </Stack>
      <Stack spacing={1}>
        <Box sx={{ marginTop: "1em" }}>
          <Content title={"Templates"} headerType={"h2"} endDivider={false} />
        </Box>
        <ContentWithLinkIcon
          headerType={"resourcePageSubHeader"}
          title={"Segment Template File"}
          icon={<DownloadIcon sx={{ color: theme.palette.primary.main }} />}
          href={"https://www.google.com"}
        />
      </Stack>
    </>
  );
}
