import { Button, Dialog, Stack, Typography } from "@mui/material";

export default function ReversedChoiceModal({
  open,
  titleText,
  text,
  yesText,
  noText,
  onYes,
  onNo,
  onClose,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        spacing={2}
        sx={{ padding: "2em 2em 1em 2em", textAlign: "center" }}
      >
        {titleText && <Typography variant="h3">{titleText}</Typography>}
        <Typography>{text}</Typography>
        <Stack direction="row">
          <Stack sx={{ flex: 1, alignItems: "start" }}>
            <Button
              variant="text"
              sx={{
                width: "auto",
                padding: "0.5em 2em",
                marginRight: "0.2em",
              }}
              onClick={onYes}
            >
              {yesText}
            </Button>
          </Stack>
          <Stack sx={{ flex: 1, alignItems: "end" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: "auto",
                padding: "0.5em 2em",
                whiteSpace: "nowrap",
                marginLeft: "0.2em",
              }}
              onClick={onNo}
            >
              {noText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
