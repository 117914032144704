export const otherRiskRatingData = [
  {
    title: "iRAP / AusRAP",
    href: "https://www.irap.org/rap-tools/infrastructure-ratings/star-ratings/",
    body: "With the Star Rating Demonstrator you can produce Star Ratings for a 100 metre length of road. By changing the road attributes, you can change the road’s Star Ratings and Star Rating Scores (SRS).",
  },
  {
    title: "ANRAM",
    href: "https://www.arrb.com.au/anram",
    body: "The Australian National Risk Assessment Model (ANRAM) provides a system to implement a nationally-consistent risk-based road assessment programme, to identify road sections with the highest risk of severe crashes.",
  },
  {
    title: "IRR",
    href: "https://www.austroads.com.au/publications/road-safety/ap-r587a-19",
    body: "Infrastructure Risk Rating (IRR) is a road safety risk assessment methodology that is calculated by coding road and roadside features.",
  },
  {
    title: "Road Stereotype",
    href: "https://www.austroads.com.au/publications/road-design/ap-r619-20",
    body: "A guide that provides guidance to road managers, planners and designers on achieving improved safety outcomes by applying consistent standards along a road corridor.",
  },
];

export const paperToolData = [
  {
    title:
      "Road Safety Ratings for Local Government Roads Tool Assesment Guide",
    href: "https://www.google.com",
    icon: "DownloadIcon",
  },
  {
    title: "Road Safety Ratings Tool Input Checklist",
    href: "https://www.google.com",
    icon: "DownloadIcon",
  },
];
