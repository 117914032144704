import { Button, Dialog, Stack, Typography } from "@mui/material";

export default function LoadedJourneyModal({
  open,
  onChoose,
  onNext,
  onFinish,
  onClose,
  isNextSegment,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        spacing={2}
        sx={{ padding: "2em 2em 1em 2em", textAlign: "center" }}
      >
        <Typography variant="h3">Segment Added!"</Typography>
        <Typography>What would you like to do next?</Typography>
        <Stack direction="row">
          <Button
            variant="text"
            sx={{
              width: "auto",
              padding: "0.5em 2em",
              marginRight: "0.2em",
            }}
            onClick={onChoose}
          >
            Choose Segment
          </Button>
          <Button
            variant="text"
            disabled={!isNextSegment}
            sx={{
              width: "auto",
              padding: "0.5em 2em",
              marginRight: "0.2em",
            }}
            onClick={onNext}
          >
            Next Segment
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              width: "auto",
              padding: "0.5em 2em",
              whiteSpace: "nowrap",
              marginLeft: "0.2em",
            }}
            onClick={onFinish}
          >
            Finish
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
